import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-data-privacy',
  standalone: true,
  imports: [MatSidenavModule,MatListModule,MatIconModule,MatSlideToggleModule,FormsModule,CommonModule],
  templateUrl: './data-privacy.component.html',
  styleUrl: './data-privacy.component.scss'
})
export class DataPrivacyComponent {


  settings = {
    researchInvites: true,
    socialResearch: true,
    aiImprovement: true

  };

}
