<div class="layout">
  <mat-icon>more_vert</mat-icon>
  <app-new-navbar (iconClick)="onNavbarIconClick($event)" [label]="selectedLabel"></app-new-navbar>

  <div class="content">
    <!-- Left Sidebar: Hidden on mobile -->
    <app-left-sidebar *ngIf="!isMobileView" (iconClick)="onNavbarIconClick($event)"></app-left-sidebar>

    <!-- <div class="middle">
      <app-middle-div></app-middle-div>
    </div> -->

    <!-- Right Sidebar: Hidden on mobile -->
    <app-right-sidebar *ngIf="!isMobileView"></app-right-sidebar>
  </div>
</div>

<!-- Bottom Tab Bar: Visible only on mobile -->
<div class="bottom-tabbar" *ngIf="isMobileView">
  <button (click)="navigate('home')">
    <mat-icon>home</mat-icon>
    <span>Home</span>
  </button>
  <button (click)="navigate('profile')">
    <mat-icon>person</mat-icon>
    <span>Profile</span>
  </button>
  <button (click)="navigate('profile2')">
    <mat-icon>group</mat-icon>
    <span>Profile2</span>
  </button>
  <button (click)="navigate('notifications')">
    <mat-icon>notifications</mat-icon>
    <span>Notifications</span>
  </button>
  <button (click)="navigate('more')">
    <mat-icon>more_vert</mat-icon>
    <span>More</span>
  </button>
</div>
