<div class="layout" *ngIf="!isRole && layoutService.showLayout">
  <app-new-navbar (iconClick)="onNavbarIconClick($event)"></app-new-navbar>
  <div class="content" [ngClass]="{ 'full-width': hideSidebars }">
    <app-left-sidebar *ngIf="!hideSidebars" (iconClick)="onNavbarIconClick($event)">
    </app-left-sidebar>
    <div class="middle">
      <router-outlet></router-outlet>
    </div>
    <app-right-sidebar *ngIf="!hideSidebars">
    </app-right-sidebar>
  </div>
</div>
<app-notifications *ngIf="isDrawerOpen" [isOpen]="isDrawerOpen">
</app-notifications>

<!-- Bottom Tab Bar: Visible only on mobile -->
<div class="bottom-tabbar" *ngIf="isMobileView && layoutService.showLayout">
  <button (click)="navigate('home')">
    <mat-icon>home</mat-icon>
    <span>Home</span>
  </button>
  <button (click)="navigate('profile')">
    <mat-icon>person</mat-icon>
    <span>Profile</span>
  </button>
  <button (click)="navigate('profile2')">
    <mat-icon>group</mat-icon>
    <span>Profile2</span>
  </button>
  <button (click)="navigate('notifications')">
    <mat-icon>notifications</mat-icon>
    <span>Notifications</span>
  </button>
  <button (click)="navigate('more')">
    <mat-icon>more_vert</mat-icon>
    <span>More</span>
  </button>
</div>
<div *ngIf="!isRole && !layoutService.showLayout">
  <router-outlet></router-outlet>
</div>



<div *ngIf="isRole">
  <div class="container-fluid select-role-div">
    <div class="title position-relative">
      <b class="role-text">Please Select Your Role to Proceed Further!</b>
    </div>
  </div>

  <!-- List of Roles Section -->
  <div class="all-roles d-flex align-content-start flex-wrap px-5 mx-1">
    <div class="" *ngFor="let role of myRoles;">
      <div class="d-flex flex-column align-items-center align-content-center">
        <mat-card class="roles m-4" (click)="roleClick(role)">
          <div class="role-image rounded"
            [ngStyle]="{ 'background-image': 'url(' + (role.profile_picture ? role.profile_picture : '../assets/img/updated-nushift-logo.png') + ')' }">
          </div>
        </mat-card>
        <div class="mb-2 text-center" style="width: 65% !important;"><b>{{role?.name}}</b></div>
      </div>
    </div>
  </div>
</div>