<!-- <span class="text-right w-100"><mat-icon style="font-size: 30px" mat-dialog-close (click)="closeDialog()"> close</mat-icon></span>
<h3 mat-dialog-title>Nushift Terms and Conditions</h3> -->

<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">Nushift Terms and Conditions</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="../../../assets/img/updated-nushift-logo.png">
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="col-xs-12 col-sm-10 margin-center center-block">
    <p>
      Nushift Technologies Private Limited, on behalf of itself and its affiliates/group companies under the brand
      "Nushift" (<b>“Nushift”</b>), is the author and publisher of the internet resource www.Nushift.com and the mobile
      application ‘Nushift’ (together, “Website”). Nushift owns and operates the services provided through the Website.
    </p>
    <ul class="custom-list">
      <li>
        <h5><span class="item-no">1.</span>NATURE AND APPLICABILITY OF TERMS</h5>
        <p>
          Please carefully go through these terms and conditions <b>(“Terms”)</b> and the privacy policy available at
          https://www.Nushift.com/company/privacy <b>(“Privacy Policy”)</b> before you decide to access the Website or
          avail the services made available on the Website by Nushift. These Terms and the Privacy Policy together
          constitute a legal agreement <b>(“Agreement”)</b> between you and Nushift in connection with your visit to the
          Website and your use of the Services (as defined below).
        </p>
        <p>The Agreement applies to you whether you are -</p>
        <ul class="lower-rom-list">
          <li>
            A medical practitioner or health care provider (whether an individual professional or an organization) or
            similar institution wishing to be listed, or already listed, on the Website, including designated,
            authorized associates of such practitioners or institutions <b>(“Practitioner(s)”, “you” or “User”);</b> or
          </li>
          <li>
            A patient, his/her representatives or affiliates, searching for Practitioners through the Website
            <b>(“End-User”, “you” or “User”);</b> or
          </li>
          <li>
            Otherwise a user of the Website <b>(“you” or “User”)</b>.
          </li>
        </ul>
        <p>
          This Agreement applies to those services made available by Nushift on the Website, which are offered free of
          charge to the Users <b>(“Services”)</b>, including the following:
        </p>
        <ul class="lower-rom-list">
          <li>
            For Practitioners: Listing of Practitioners and their profiles and contact details, to be made available to
            the other Users and visitors to the Website;
          </li>
          <li>
            For other Users: Facility to (i) create and maintain ‘Health Accounts’, (ii) search for Practitioners by
            name, specialty, and geographical area, or any other criteria that may be developed and made available by
            Nushift, and (iii) to make appointments with Practitioners.
          </li>
        </ul>
        <p>
          The Services may change from time to time, at the sole discretion of Nushift, and the Agreement will apply to
          your visit to and your use of the Website to avail the Service, as well as to all information provided by you
          on the Website at any given point in time.
        </p>
        <p>
          This Agreement defines the terms and conditions under which you are allowed to use the Website and describes
          the manner in which we shall treat your account while you are registered as a member with us. If you have any
          questions about any part of the Agreement, feel free to contact us at support&#64;Nushift.com.
        </p>
        <p>
          By downloading or accessing the Website to use the Services, you irrevocably accept all the conditions
          stipulated in this Agreement, the <a href="https://www.Nushift.com/company/subscribers"
            target="_blank">Subscription Terms of Service</a> and <a href="https://Nushift.com/privacy"
            target="_blank">Privacy Policy</a>, as available on the Website, and agree to abide by them. This Agreement
          supersedes all previous oral and written terms and conditions (if any) communicated to you relating to your
          use of the Website to avail the Services. By availing any Service, you signify your acceptance of the terms of
          this Agreement.
        </p>
        <p>
          We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time, and
          such modifications shall be informed to you in writing You should read the Agreement at regular intervals.
          Your use of the Website following any such modification constitutes your agreement to follow and be bound by
          the Agreement so modified.
        </p>
        <p>
          You acknowledge that you will be bound by this Agreement for availing any of the Services offered by us. If
          you do not agree with any part of the Agreement, please do not use the Website or avail any Services.
        </p>
        <p>
          Your access to use of the Website and the Services will be solely at the discretion of Nushift.
        </p>
        <p>
          The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but
          not limited to:
        </p>
        <ul class="lower-rom-list">
          <li>
            the Indian Contract Act, 1872,
          </li>
          <li>
            the (Indian) Information Technology Act, 2000, and
          </li>
          <li>
            the rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information
            Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011
            (the <b>“SPI Rules”</b>), and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011
            (the <b>“IG Rules”</b>).
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">2.</span>CONDITIONS OF USE</h5>
        <p>
          You must be 18 years of age or older to register, use the Services, or visit or use the Website in any manner.
          By registering, visiting and using the Website or accepting this Agreement, you represent and warrant to
          Nushift that you are 18 years of age or older, and that you have the right, authority and capacity to use the
          Website and the Services available through the Website, and agree to and abide by this Agreement.
        </p>
      </li>
      <li>
        <h5><span class="item-no">3.</span>TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN PRACTITIONERS</h5>
        <p>The terms in this Clause 3 are applicable only to Users other than Practitioners.</p>
        <ul class="custom-list">
          <li>
            <span class="item-no">3.1</span>END-USER ACCOUNT AND DATA PRIVACY
            <ul class="custom-list">
              <li><span class="item-no">3.1.1</span> The terms “personal information” and “sensitive personal data or
                information” are defined under the SPI Rules, and are reproduced in the Privacy Policy.
              </li>
              <li><span class="item-no">3.1.2</span> Nushift may by its Services, collect information relating to the
                devices through which you access the Website, and anonymous data of your usage. The collected
                information will be used only for improving the quality of Nushift’s services and to build new services.
              </li>
              <li><span class="item-no">3.1.3</span> The Website allows Nushift to have access to registered Users’
                personal email or phone number, for communication purpose so as to provide you a better way of booking
                appointments and for obtaining feedback in relation to the Practitioners and their practice.
              </li>
              <li>
                <span class="item-no">3.1.4</span> The Privacy Policy sets out, <i>inter-alia</i>:
                <ul class="lower-rom-list">
                  <li>
                    The type of information collected from Users, including sensitive personal data or information;
                  </li>
                  <li>The purpose, means and modes of usage of such information;</li>
                  <li>How and to whom Nushift will disclose such information; and,</li>
                  <li>Other information mandated by the SPI Rules.</li>
                </ul>
              </li>
              <li>
                <span class="item-no">3.1.5</span> The User is expected to read and understand the Privacy Policy, so as
                to ensure that he or she has the knowledge of, <i>inter-alia</i>:
                <ul class="lower-rom-list">
                  <li>the fact that certain information is being collected;</li>
                  <li>the purpose for which the information is being collected;</li>
                  <li>the intended recipients of the information;</li>
                  <li>the nature of collection and retention of the information; and</li>
                  <li>the name and address of the agency that is collecting the information and the agency that will
                    retain the information; and</li>
                  <li>the various rights available to such Users in respect of such information.</li>
                </ul>
              </li>
              <li><span class="item-no">3.1.6</span> Nushift shall not be responsible in any manner for the authenticity
                of the personal information or sensitive personal data or information supplied by the User to Nushift or
                to any other person acting on behalf of Nushift.
              </li>
              <li><span class="item-no">3.1.7</span> The User is responsible for maintaining the confidentiality of the
                User’s account access information and password, if the User is registered on the Website. The User shall
                be responsible for all usage of the User’s account and password, whether or not authorized by the User.
                The User shall immediately notify Nushift of any actual or suspected unauthorized use of the User’s
                account or password. Although Nushift will not be liable for your losses caused by any unauthorized use
                of your account, you may be liable for the losses of Nushift or such other parties as the case may be,
                due to any unauthorized use of your account.
              </li>
              <li>
                <span class="item-no">3.1.8</span>If a User provides any information that is untrue, inaccurate, not
                current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Nushift has
                reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete,
                Nushift has the right to discontinue the Services to the User at its sole discretion.
              </li>
              <li>
                <span class="item-no">3.1.9</span>Nushift may use such information collected from the Users from time to
                time for the purposes of debugging customer support related issues.
              </li>
              <li>
                <span class="item-no">3.1.10</span>Against every Practitioner listed in Nushift.com, you may see a ‘show
                number’ option. When you choose this option, you choose to call the number through a free telephony
                service provided by Nushift, and the records of such calls are recorded and stored in Nushift’s servers.
                Such call will have an IVR message stating the purpose of recording your calls and your consent to such
                recordings which are dealt with as per the Privacy Policy. Such records may be accessed by Nushift for
                quality control and support related purposes and are dealt with only in accordance with the terms of the
                Privacy Policy. Such call facility provided to you by Nushift should be used only for appointment and
                booking purposes, and not for consultation on health-related issues. Nushift accepts no liability if the
                call facility is not used in accordance with the foregoing. Nushift may also choose to not use this
                facility and show the practitioner's direct number. In case you choose to not provide your consent to
                recording your calls that may have personal information required for appointment and booking purposes,
                Nushift reserves the right to not provide the Services for which such personal information is sought.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">3.2</span>RELEVANCE ALGORITHM
            <p>
              Nushift’s relevance algorithm for the Practitioners is a fully automated system that lists the
              Practitioners, their profile and information regarding their Practice on its Website. These listings of
              Practitioners do not represent any fixed objective ranking or endorsement by Nushift. Nushift will not be
              liable for any change in the relevance of the Practitioners on search results, which may take place from
              time to time. The listing of Practitioners will be based on automated computation of the various factors
              including inputs made by the Users including their comments and feedback. Such factors may change from
              time to time, in order to improve the listing algorithm. Nushift in no event will be held responsible for
              the accuracy and the relevancy of the listing order of the Practitioners on the Website.
            </p>
          </li>
          <li>
            <span class="item-no">3.3</span>LISTING CONTENT AND DISSEMINATING INFORMATION
            <ul class="custom-list">
              <li>
                <span class="item-no">3.3.1</span>Nushift collects, directly or indirectly, and displays on the Website,
                relevant information regarding the profile and practice of the Practitioners listed on the Website, such
                as their specialization, qualification, fees, location, visiting hours, and similar details. Nushift
                takes reasonable efforts to ensure that such information is updated at frequent intervals. Although
                Nushift screens and vets the information and photos submitted by the Practitioners, it cannot be held
                liable for any inaccuracies or incompleteness represented from it, despite such reasonable efforts.
              </li>
              <li>
                <span class="item-no">3.3.2</span>The Services provided by Nushift or any of its licensors or service
                providers are provided on an "as is" and “as available’ basis, and without any warranties or conditions
                (express or implied, including the implied warranties of merchantability, accuracy, fitness for a
                particular purpose, title and non-infringement, arising by statute or otherwise in law or from a course
                of dealing or usage or trade). Nushift does not provide or make any representation, warranty or
                guarantee, express or implied about the Website or the Services. Nushift does not guarantee the accuracy
                or completeness of any content or information provided by Users on the Website. To the fullest extent
                permitted by law, Nushift disclaims all liability arising out of the User’s use or reliance upon the
                Website, the Services, representations and warranties made by other Users, the content or information
                provided by the Users on the Website, or any opinion or suggestion given or expressed by Nushift or any
                User in relation to any User or services provided by such User.
              </li>
              <li>
                <span class="item-no">3.3.3</span>The Website may be linked to the website of third parties, affiliates
                and business partners. Nushift has no control over, and not liable or responsible for content, accuracy,
                validity, reliability, quality of such websites or made available by/through our Website. Inclusion of
                any link on the Website does not imply that Nushift endorses the linked site. User may use the links and
                these services at User’s own risk.
              </li>
              <li>
                <span class="item-no">3.3.4</span>Nushift assumes no responsibility, and shall not be liable for, any
                damages to, or viruses that may infect User’s equipment on account of User’s access to, use of, or
                browsing the Website or the downloading of any material, data, text, images, video content, or audio
                content from the Website. If a User is dissatisfied with the Website, User’s sole remedy is to
                discontinue using the Website.
              </li>
              <li>
                <span class="item-no">3.3.5</span>If Nushift determines that you have provided fraudulent, inaccurate,
                or incomplete information, including through feedback, Nushift reserves the right to immediately suspend
                your access to the Website or any of your accounts with Nushift and makes such declaration on the
                website alongside your name/your clinic’s name as determined by Nushift for the protection of its
                business and in the interests of Users. You shall be liable to indemnify Nushift for any losses incurred
                as a result of your misrepresentations or fraudulent feedback that has adversely affected Nushift or its
                Users.
              </li>
              <li>
                <span class="item-no">3.3.6</span>The information published under the head of "Industry wide city-wise
                Pricing Graph" is derived from a benchmarking group that is determined to be relevant by Nushift in the
                relevant city. Nushift has published this information for guidance purpose and does not have the ability
                to ascertain accuracy of the data based on which the information has been published and nor does it have
                any duty to disclose to anyone the source of the underlying data sets based on which this information
                has been published. Nushift is not liable in any manner for any consequence whatsoever arising out of
                any use of the information published here.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">3.4</span>BOOK APPOINTMENT AND CALL FACILITY
            <p>Nushift enables Users to connect with Practitioners through two methods: a) Book facility that allows
              Users book an appointment through the Website; b) Value added telephonic services which connect Users
              directly to the Practitioner’s number provided on the Website.</p>
            <ul class="custom-list">
              <li>
                <span class="item-no">3.4.1</span>Nushift will ensure Users are provided confirmed appointment on the
                Book facility. However, Nushift has no liability if such an appointment is later cancelled by the
                Practitioner, or the same Practitioner is not available for appointment. Provided, it does not fall
                under the heads listed under the <a href="https://www.Nushift.com/company/guarantee-claim">Nushift
                  Guarantee Program</a>, in which case the terms of this program shall apply.
              </li>
              <li>
                <span class="item-no">3.4.2</span>If a User has utilized the telephonic services, Nushift reserves the
                right to share the information provided by the User with the Practitioner and store such information
                and/or conversation of the User with the Practitioner, in accordance with our <a
                  href="https://www.Nushift.com/company/privacy">Privacy Policy</a>.
              </li>
              <li>
                <span class="item-no">3.4.3</span>The results of any search Users perform on the Website for
                Practitioners should not be construed as an endorsement by Nushift of any such particular Practitioner.
                If the User decides to engage with a Practitioner to seek medical services, the User shall be doing so
                at his/her own risk.
              </li>
              <li>
                <span class="item-no">3.4.4</span>
                Without prejudice to the generality of the above, Nushift is not involved in providing any healthcare or
                medical advice or diagnosis and hence is not responsible for any interactions between User and the
                Practitioner. User understands and agrees that Nushift will not be liable for:
                <ul class="lower-rom-list">
                  <li>
                    User interactions and associated issues User has with the Practitioner;
                  </li>
                  <li>
                    the ability or intent of the Practitioner(s) or the lack of it, in fulfilling their obligations
                    towards Users;
                  </li>
                  <li>
                    any wrong medication or quality of treatment being given by the Practitioner(s), or any medical
                    negligence on part of the Practitioner(s);
                  </li>
                  <li>
                    inappropriate treatment, or similar difficulties or any type of inconvenience suffered by the User
                    due to a failure on the part of the Practitioner to provide agreed Services;
                  </li>
                  <li>
                    any misconduct or inappropriate behaviour by the Practitioner or the Practitioner’s staff;
                  </li>
                  <li>
                    cancellation or no show by the Practitioner or rescheduling of booked appointment or any variation
                    in the fees charged, provided these have been addressed to under, <a
                      href="https://www.Nushift.com/company/guarantee-claim">Nushift Guarantee Program</a>.
                  </li>
                </ul>
              </li>
              <li>
                <span class="item-no">3.4.5</span>Users are allowed to provide feedback about their experiences with the
                Practitioner, however, the User shall ensure that, the same is provided in accordance with applicable
                law. User however understands that, Nushift shall not be obliged to act in such manner as may be
                required to give effect to the content of Users feedback, such as suggestions for delisting of a
                particular Practitioner from the Website.
              </li>
              <li>
                <span class="item-no">3.4.6</span>In case of a ‘Patient-No-Show (P.N.S)’ (defined below), where the User
                does not show-up at the concerned Practitioner’s clinic:
                <ul class="lower-rom-list">
                  <li>
                    User’s account will be temporarily disabled from booking further online appointments on Nushift.com
                    for next four (4) months, in case of, three(3) Valid PNS, as per the <a
                      href="https://www.Nushift.com/company/pns-policy">Patient-No-Show Policy</a>. However, the User
                    can continue to call the clinic via Nushift.com to get an appointment.
                  </li>
                  <li>
                    Patient- No-Show (P.N.S) for the purposes of these Terms and Conditions, is defined as, any instance
                    where a User, who booked an appointment on the Website using the Book Appointment facility , has not
                    turned up for the appointment without cancelling, rescheduling, or informing the Practitioner in
                    advance about the same. When Practitioner informs Nushift of the incident or marks a particular
                    appointment as P.N.S. using the Nushift Ray software or Nushift Pro App within five (5) days of the
                    scheduled appointment, an email and SMS (“PNS Communication”) will be sent to the User to confirm on
                    the incident with reasons. Where the User is not able to establish that the User had a legitimate
                    reason as per Clause 3.4.6(c), for not showing up, Nushift shall be entitled to take actions as
                    under Clause 3.4.6 (a). However Users understand that, actions such as ones mentioned under Clause
                    3.4.6(a) are included as a deterrent to stop Users from misusing the Website, and the loss of
                    business hours incurred by the Practitioner.
                  </li>
                  <li>
                    Following instances, solely at the discretion of Nushift, would be construed as valid cases of PNS
                    (“Valid PNS”), in which case the User shall be penalized as per Clause 3.4.6 (a):
                    <ul class="lower-alpha-list">
                      <li>User does not reply within seven (7) days, with reasons to PNS Communication, from the date of
                        receipt of such PNS Communication;</li>
                      <li>In case User responds to the PNS Communication with below reasons:
                        <ul class="lower-alpha-list">
                          <li>Forgot the appointment</li>
                          <li>Chose to visit another Practitioner/consulted online;</li>
                          <li>Busy with other work; or such other reasons (which Nushift at its discretion decides to be
                            a valid reason to not show up).</li>
                        </ul>
                      </li>
                      <li>Where the User has booked a paid appointment and is unable to visit the Practitioner, due to
                        such genuine reasons of sickness etc. at the sole discretion of Nushift, pursuant to conducting
                        of investigation, the User shall be provided with a refund of such payment made by User, at the
                        time of booking. However, where cancellation charges have been levied, you would not be entitled
                        to complete refund.</li>
                    </ul>
                  </li>
                  <li>
                    Nushift reserves the right to make the final decision in case of a conflict. The total aggregate
                    liability of Nushift with respect to any claims made herein shall be INR 200.
                  </li>
                </ul>
              </li>
              <li>
                <span class="item-no">3.4.7</span>Cancellation and Refund Policy
                <ul class="lower-rom-list">
                  <li>
                    In the event that, the Practitioner with whom User has booked a paid appointment via the Website,
                    has not been able to meet the User, User will need to write to us at support&#64;Nushift.com within five
                    (5) days from the occurrence of such event; in which case, the entire consultation amount as
                    mentioned on the Website will be refunded to the User within the next five (5) to six (6) business
                    days in the original mode of payment done by the User while booking. In case where the User, does
                    not show up for the appointment booked with a Practitioner, without cancelling the appointment
                    beforehand, the amount will not be refunded, and treated as under Clause 3.4.6. However, where
                    cancellation charges have been levied (as charged by the Practitioner/Practice), you would not be
                    entitled to complete refund even if you have cancelled beforehand.
                  </li>
                  <li>
                    Users will not be entitled for any refunds in cases where, the Practitioner is unable to meet the
                    User at the exact time of the scheduled appointment time and the User is required to wait,
                    irrespective of the fact whether the User is required to wait or choose to not obtain the medical
                    services from the said Practitioner.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">3.5</span>NO DOCTOR-PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE
            <ul class="custom-list">
              <li>
                <span class="item-no">3.5.1</span>Please note that some of the content, text, data, graphics, images,
                information, suggestions, guidance, and other material (collectively, “Information”) that may be
                available on the Website (including information provided in direct response to your questions or
                postings) may be provided by individuals in the medical profession. The provision of such Information
                does not create a licensed medical professional/patient relationship, between Nushift and you and does
                not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is
                only provided to assist you with locating appropriate medical care from a qualified practitioner.
              </li>
              <li>
                <span class="item-no">3.5.2</span>It is hereby expressly clarified that, the Information that you obtain
                or receive from Nushift, and its employees, contractors, partners, sponsors, advertisers, licensors or
                otherwise on the Website is for informational purposes only. We make no guarantees, representations or
                warranties, whether expressed or implied, with respect to professional qualifications, quality of work,
                expertise or other information provided on the Website. In no event shall we be liable to you or anyone
                else for any decision made or action taken by you in reliance on such information.
              </li>
              <li>
                <span class="item-no">3.5.3</span>The Services are not intended to be a substitute for getting in touch
                with emergency healthcare. If you are an End-User facing a medical emergency (either on your or a
                another person’s behalf), please contact an ambulance service or hospital directly.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">3.6</span>CONSULT
            <ul class="custom-list">
              <a href="https://www.Nushift.com/consult/terms">Click here to view Consult terms &amp; conditions.</a>
            </ul>
          </li>
          <li>
            <span class="item-no" id="health-feed">3.7</span>Nushift HEALTH FEED
            <p>These terms &amp; conditions governing Nushift Health feed are applicable to Users (being both end-users/
              Practitioner). However, it is clarified that the terms and conditions herein applicable only to
              Practitioners and applicable to Users are called out separately, as the context warrants.</p>
            <ul class="custom-list">
              <li>
                <span class="item-no">3.7.1</span>Nushift Health feed is an online content platform available on the
                Website, wherein Practitioners who have created a Nushift profile can login and post health and wellness
                related content.
              </li>
              <li>
                <span class="item-no">3.7.2</span>Practitioners can use Nushift Health feed by logging in from their
                health account, creating original content comprising text, audio, video, images, data or any combination
                of the same <b>(“Content”)</b>, and uploading said Content to Nushift’s servers. Nushift will make
                available to the User a gallery of images licensed by Nushift from a third party stock image provider
                <b>(“Nushift Gallery”)</b>. The User can upload their own images or choose an image from the Nushift
                Gallery. Nushift does not provide any warranty as to the ownership of the intellectual property in the
                Nushift Gallery and the User acknowledges that the User will use the images from the Nushift Gallery at
                their own risk. Nushift shall post such Content to Nushift Health feed at its own option and subject to
                these Terms and Conditions. The Content uploaded via Nushift Health feed does not constitute medical
                advice and may not be construed as such by any person.
              </li>
              <li>
                <span class="item-no">3.7.3</span>Practitioners acknowledges that they are the original authors and
                creators of any Content uploaded by them via Nushift Health feed and that no Content uploaded by them
                would constitute infringement of the intellectual property rights of any other person. Nushift reserves
                the right to remove any Content which it may determine at its own discretion as violating the
                intellectual property rights of any other person, including but not limited to patent, trademark,
                copyright or other proprietary rights. Practitioner agrees to absolve Nushift from and indemnify Nushift
                against all claims that may arise as a result of any third party intellectual property right claim that
                may arise from the Practitioner’s uploading of any Content on the Nushift Health feed. The Practitioner
                may not use the images in the Nushift Gallery for any purpose other than those directly related to the
                creation and uploading of Content to Nushift Health feed. The Practitioner also agrees to absolve
                Nushift from and indemnify Nushift against all claims that may arise as a result of any third party
                intellectual property claim if the Practitioner downloads, copies or otherwise utilizes an image from
                the Nushift Gallery for his/her personal or commercial gain.
              </li>
              <li>
                <span class="item-no">3.7.4</span>Practitioner hereby assigns to Nushift, in perpetuity and worldwide,
                all intellectual property rights in any Content created by the User and uploaded by the User via Nushift
                Health feed.
              </li>
              <li>
                <span class="item-no">3.7.5</span>Nushift shall have the right to edit or remove the Content and any
                comments in such manner as it may deem Nushift Health feed at any time.
              </li>
              <li>
                <span class="item-no">3.7.6</span>Practitioner shall ensure that the Content or any further responses to
                the Content (including responses to Users) is not harmful, harassing, blasphemous, defamatory, obscene,
                pornographic, paedophilic or libelous in any manner. Further, Practitioner should ensure that the
                Content is not invasive of any other person’s privacy, or otherwise contains any elements that is
                hateful, racially or ethnically objectionable, disparaging, or otherwise unlawful in any manner
                whatever. Nushift reserves the right to remove any Content which it may determine at its own discretion
                is violative of these Terms and Conditions or any law or statute in force at the time. Also, the
                Practitioner agrees to absolve Nushift from and indemnify Nushift against all claims that may arise as a
                result of any legal claim arising from the nature of the Content posted by the Practitioner on Nushift
                Health Feed.
              </li>
              <li>
                <span class="item-no">3.7.7</span>Practitioner shall ensure that no portion of the Content is violative
                of any law for the time being in force.
              </li>
              <li>
                <span class="item-no">3.7.8</span>Practitioner shall ensure that the Content is not threatening the
                unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or
                public order. Further the Practitioner shall ensure that the Content will not cause incitement to the
                commission of any cognisable offence or prevent investigation of any offence or is insulting to any
                other nation.
              </li>
              <li>
                <span class="item-no">3.7.9</span>User may also use Nushift Health feed in order to view original
                content created by Practitioners and to create and upload comments on such Content, where allowed
                <b>(“User Comment”)</b>.
              </li>
              <li>
                <span class="item-no">3.7.10</span>User acknowledges that the User Comment reflects the views and
                opinions of the authors of such Content and do not necessarily reflect the views of Nushift.
              </li>
              <li>
                <span class="item-no">3.7.11</span>User agrees that the Content they access on Nushift Health feed does
                not in any way constitute medical advice and that the responsibility for any act or omission by the User
                arising from the User’s interpretation of the Content, is solely attributable to the User. The User
                agrees to absolve Nushift from and indemnify Nushift against all claims that may arise as a result of
                the User’s actions resulting from the User’s viewing of Content on Nushift Health feed.
              </li>
              <li>
                <span class="item-no">3.7.12</span>User acknowledges that all intellectual property rights in the User
                Comment on Nushift Health feed vests with Nushift. The User agrees not to infringe upon Nushift’s
                intellectual property by copying or plagiarizing content on Nushift Health feed. Nushift reserves its
                right to initiate all necessary legal remedies available to them in case of such an infringement by the
                User. Also, User Comment will be the sole intellectual property of Nushift. The User agrees not to post
                User Comment that would violate the intellectual property of any third party, including but not limited
                to patent, trademark, copyright or other proprietary rights. Nushift reserves the right to remove any
                User Comment which it may determine at its own discretion as violating the intellectual property rights
                of any third party. The User agrees to absolve Nushift from and indemnify Nushift against all claims
                that may arise as a result of any third party intellectual property right claim that may arise from the
                User Comment.
              </li>
              <li>
                <span class="item-no">3.7.13</span>User shall ensure that the User Comment is not harmful, harassing,
                blasphemous, defamatory, obscene, pornographic, paedophilic or libelous in any manner. Further, User
                should ensure that the User Comment is not invasive of any other person’s privacy, or otherwise contains
                any elements that is hateful, racially or ethnically objectionable, disparaging, or otherwise unlawful
                in any manner whatever. Nushift reserves the right to remove any Content which it may determine at its
                own discretion is violative of these Terms and Conditions or any law or statute in force at the time
                Also, the User agrees to absolve Nushift from and indemnify Nushift against all claims that may arise as
                a result of any legal claim arising from the nature of the User Comment.
              </li>
              <li>
                <span class="item-no">3.7.14</span>User shall ensure that the User Comment is not threatening the unity,
                integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public
                order. Further the Practitioner shall ensure that the User Comment will not cause incitement to the
                commission of any cognisable offence or prevent investigation of any offence or is insulting to any
                other nation.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">3.8</span>CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS
            <ul class="custom-list">
              <li>
                <span class="item-no">3.8.1</span>The contents listed on the Website are (i) User generated content, or
                (ii) belong to Nushift. The information that is collected by Nushift directly or indirectly from the
                End- Users and the Practitioners shall belong to Nushift. Copying of the copyrighted content published
                by Nushift on the Website for any commercial purpose or for the purpose of earning profit will be a
                violation of copyright and Nushift reserves its rights under applicable law accordingly.
              </li>
              <li>
                <span class="item-no">3.8.2</span>Nushift authorizes the User to view and access the content available
                on or from the Website solely for ordering, receiving, delivering and communicating only as per this
                Agreement. The contents of the Website, information, text, graphics, images, logos, button icons,
                software code, design, and the collection, arrangement and assembly of content on the Website
                (collectively, <b>"Nushift Content"</b>), are the property of Nushift and are protected under copyright,
                trademark and other laws. User shall not modify the Nushift Content or reproduce, display, publicly
                perform, distribute, or otherwise use the Nushift Content in any way for any public or commercial
                purpose or for personal gain.
              </li>
              <li>
                <span class="item-no">3.8.3</span>User shall not access the Services for purposes of monitoring their
                availability, performance or functionality, or for any other benchmarking or competitive purposes.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <span class="item-no">3.9</span>REVIEWS AND FEEDBACK
        <p>
          By using this Website, you agree that any information shared by you with Nushift or with any Practitioner will
          be subject to our Privacy Policy.
        </p>
        <p>
          You are solely responsible for the content that you choose to submit for publication on the Website, including
          any feedback, ratings, or reviews (“Critical Content”) relating to Practitioners or other healthcare
          professionals. The role of Nushift in publishing Critical Content is restricted to that of an ‘intermediary’
          under the Information Technology Act, 2000. Nushift disclaims all responsibility with respect to the content
          of Critical Content, and its role with respect to such content is restricted to its obligations as an
          ‘intermediary’ under the said Act. Nushift shall not be liable to pay any consideration to any User for
          re-publishing any content across any of its platforms.
        </p>
        <p>
          Your publication of reviews and feedback on the Website is governed by Clause 5 of these Terms. Without
          prejudice to the detailed terms stated in Clause 5, you hereby agree not to post or publish any content on the
          Website that (a) infringes any third-party intellectual property or publicity or privacy rights, or (b)
          violates any applicable law or regulation, including but not limited to the IG Rules and SPI Rules. Nushift,
          at its sole discretion, may choose not to publish your reviews and feedback, if so required by applicable law,
          and in accordance with Clause 5 of these Terms. You agree that Nushift may contact you through telephone,
          email, SMS, or any other electronic means of communication for the purpose of:
        </p>
        <ul class="lower-rom-list">
          <li>Obtaining feedback in relation to Website or Nushift’s services; and/or</li>
          <li>Obtaining feedback in relation to any Practitioners listed on the Website; and/or</li>
          <li>Resolving any complaints, information, or queries by Practitioners regarding your Critical Content;</li>
        </ul>
        <p>
          and you agree to provide your fullest co-operation further to such communication by Nushift. Nushift’s
          Feedback Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and remains subject always
          to these Terms.
        </p>
      </li>
      <li>
        <span class="item-no">3.10</span>RECORDS
        <p>
          Nushift may provide End-Users with a free facility known as ‘Records’ on its mobile application ‘Nushift’.
          Information available in your Records is of two types:
        </p>
        <ul class="lower-rom-list">
          <li>User-created: Information uploaded by you or information generated during your interaction with Nushift
            ecosystem, eg: appointment, medicine order placed by you.</li>
          <li>Practice-created: Health Records generated by your interaction with a Practitioner who uses ‘Nushift Ray’
            or other Services of Nushift software.</li>
        </ul>
        <p></p>
        <p>
          The specific terms relating to such Health Account are as below, without prejudice to the rest of these Terms
          and the Privacy Policy:
        </p>
        <ul class="custom-list">
          <li>
            <span class="item-no">3.10.1</span>Your Records is only created after you have signed up and explicitly
            accepted these Terms.
          </li>
          <li>
            <span class="item-no">3.10.2</span>Any Practice created Health Record is provided on an as-is basis at the
            sole intent, risk and responsibility of the Practitioner and Nushift does not validate the said information
            and makes no representation in connection therewith. You should contact the relevant Practitioner in case
            you wish to point out any discrepancies or add, delete, or modify the Health Record in any manner.
          </li>
          <li>
            <span class="item-no">3.10.3</span>The Health Records are provided on an as-is basis. While we strive to
            maintain the highest levels of service availability, Nushift is not liable for any interruption that may be
            caused to your access of the Services.
          </li>
          <li>
            <span class="item-no">3.10.4</span>The reminder provided by the Records is only a supplementary way of
            reminding you to perform your activities as prescribed by your Practitioner. In the event of any medicine
            reminders provided by Nushift, you should refer to your prescription before taking any medicines. Nushift is
            not liable if for any reason reminders are not delivered to you or are delivered late or delivered
            incorrectly, despite its best efforts. In case you do not wish to receive the reminders, you can switch it
            off through the Nushift app.
          </li>
          <li>
            <span class="item-no">3.10.5</span>It is your responsibility to keep your correct mobile number and email ID
            updated in the Records. The Health Records will be sent to the Records associated with this mobile number
            and/or email ID. Every time you change any contact information (mobile or email), we will send a
            confirmation. Nushift is not responsible for any loss or inconvenience caused due to your failure in
            updating the contact details with Nushift.
          </li>
          <li>
            <span class="item-no">3.10.6</span>Nushift uses industry–level security and encryption to your Health
            Records. However, Nushift does not guarantee to prevent unauthorized access if you lose your login
            credentials or they are otherwise compromised. In the event you are aware of any unauthorized use or access,
            you shall immediately inform Nushift of such unauthorized use or access. Please safeguard your login
            credentials and report any actual suspected breach of account to support&#64;Nushift.com.
          </li>
          <li>
            <span class="item-no">3.10.7</span>If you access your dependents’ Health Records by registering your
            dependents with your own Records, you are deemed to be responsible for the Health Records of your dependents
            and all obligations that your dependents would have had, had they maintained their own separate individual
            Records. You agree that it shall be your sole responsibility to obtain prior consent of your dependent and
            shall have right to share, upload and publish any sensitive personal information of your dependent. Nushift
            assumes no responsibility for any claim, dispute or liability arising in this regard, and you shall
            indemnify Nushift and its officers against any such claim or liability arising out of unauthorized use of
            such information.
          </li>
          <li>
            <span class="item-no">3.10.8</span>In case you want to delete your Records, you can do so by contacting our
            service support team. However only your account and any associated Health Records will be deleted, and your
            Health Records stored by your Practitioners will continue to be stored in their respective accounts.
          </li>
          <li>
            <span class="item-no">3.10.9</span>You may lose your “User created” record, if the data is not synced with
            the server.
          </li>
          <li>
            <span class="item-no">3.10.10</span>If the Health Record is unassessed for a stipulated time, you may not be
            able to access your Health Records due to security reasons.
          </li>
          <li>
            <span class="item-no">3.10.11</span>Nushift is not liable if for any reason, Health Records are not
            delivered to you or are delivered late despite its best efforts.
          </li>
          <li>
            <span class="item-no">3.10.12</span>The Health Records are shared with the phone numbers that are provided
            by your Practitioner. Nushift is not responsible for adding the Heath Records with incorrect numbers if
            those incorrect numbers are provided by the Practitioner.
          </li>
          <li>
            <span class="item-no">3.10.13</span>Nushift is not responsible or liable for any content, fact, Health
            Records, medical deduction or the language used in your Health Records whatsoever. Your Practitioner is
            solely responsible and liable for your Health Records and any information provided to us including but not
            limited to the content in them.
          </li>
          <li>
            <span class="item-no">3.10.14</span>Nushift has the ability in its sole discretion to retract Health Records
            without any prior notice if they are found to be shared incorrectly or inadvertently.
          </li>
          <li>
            <span class="item-no">3.10.15</span>Nushift will follow the law of land in case of any constitutional court
            or jurisdiction mandates to share the Health Records for any reason.
          </li>
          <li>
            <span class="item-no">3.10.16</span>You agree and acknowledge that Nushift may need to access the Health
            Record for cases such as any technical or operational issue of the End User in access or ownership of the
            Records.
          </li>
          <li>
            <span class="item-no">3.10.17</span>You acknowledge that the Practitioners you are visiting may engage
            Nushift's software or third party software for the purposes of the functioning of the Practitioner’s
            business and Nushift's services including but not limited to the usage and for storage of Records (as
            defined in Section 3.10) in India and outside India, in accordance with the applicable laws.
          </li>
          <li>
            <span class="item-no">3.10.18</span>To the extent that your Records have been shared with Nushift or stored
            on any of the Nushift products used by Practitioner’s you are visiting, and may in the past have visited,
            You hereby agree to the storage of your Records by Nushift pertaining to such previously visited clinics and
            hospitals who have tie ups with Nushift for the purposes of their business and for Nushift's services
            including but not limited to the usage and for storage of Records (as defined in Section 3.10) in India and
            outside India, in accordance with the applicable laws and further agree, upon creation of your account with
            Nushift, to the mapping of such Records as may be available in Nushift’s database to your User account.
          </li>
        </ul>
      </li>
      <li>
        <span class="item-no">3.11</span>Nushift MEDICINE INFORMATION
        <p>
          For detailed terms and conditions regarding medicine information click <a href="/medicine-info/terms"
            target="_blank">here.</a>
        </p>
      </li>
      <li>
        <h5><span class="item-no">3.12</span>Nushift Q&amp;A</h5>
        <ul class="custom-list">
          <li>
            <span class="item-no">3.12.1</span>Terms for Practitioners:
            <ul class="custom-list">
              <ul class="lower-rom-list">
                <li>Every Practitioner on the Q&amp;A Platform must be qualified in the area of expertise that he
                  represents as being his qualification.</li>
                <li>The Q&amp;A Platform is a platform for exchange of information which is of general nature in a
                  question and answer format. <b>The Q&amp;A Platform is not for emergency situations.</b></li>
                <li>Any Q&amp;A interaction cannot be construed as a medical consultation with the Practitioner, in any
                  manner whatsoever.</li>
                <li>Nushift Users may post questions on medical issues (“<b>Querist</b>”), and these queries can be
                  answered by multiple Practitioners on the Q&amp;A Platform. It is expressly clarified that no
                  doctor-patient relationship is established between the Querist and Practitioner, in any manner
                  whatsoever, by indulging in this Q&amp;A.</li>
                <li>The Practitioner may provide <b>probable</b> views, recommendations, suggestions and solutions to
                  the question posted by a Querist. However, it is expressly clarified that any such view,
                  recommendation, suggestion and solution shall not be construed as medical advice. The person in
                  question (<i>being Querist or not</i>) is advised to consult with a doctor in this regard.</li>
                <li>Practitioner shall not share their personal, religious and/or moral views with the Querist while
                  issuing their response.</li>
                <li>The Practitioner cannot issue and/or prescribe any medicines on the Q&amp;A platform. It is a
                  platform for only exchange of information and not a consultation/ medical advice.</li>
                <li>The Practitioner is <b>absolutely and expressly prohibited</b> to:
                  <ul class="lower-alpha-list">
                    <li>Provide a medical diagnosis;</li>
                    <li>Issue a prescription;</li>
                    <li>Post any response which comprises of any derogatory language, objectionable, pornographic and
                      /or offensive content;</li>
                    <li>Advertise any brand of drugs, supplements, vaccines and medications, in any manner of
                      whatsoever; </li>
                    <li>Advertise to/solicit patients in any manner including without limitation by way of using social
                      media handles, including without limitation, facebook, twitter etc., telephone/ mobile numbers,
                      youtube, and address;</li>
                    <li>Promote content and activities which are illegal in nature;</li>
                  </ul>
                </li>
                <li>In the event of any breach of these QA T&amp;C, Nushift will take appropriate action <i>inter
                    alia</i> ranging from issuing warnings to removal of Practitioner from the Q&amp;A Platform, in its
                  sole and absolute discretion, on a case to case basis.</li>
                <li>Any liability arising out of the response issued by a Practitioner will be borne solely by the
                  Practitioner. Q&amp;A Platform is only a platform interface provided by Nushift for sharing and
                  exchanging information purposes.</li>
                <li>The Q&amp;A Platform shall be audited on a regular basis by Nushift for ascertaining compliance by
                  the Practitioner to Paragraph 6, and the Practitioners consent to the same. It is clarified that the
                  said audit is not conducted for the purpose of quality check or validation of responses issued by
                  Practitioner.</li>
                <li>All general Nushift Terms and Conditions shall govern the QA T&amp;C to the extent applicable.
                  Decision of Nushift is final and binding on the issues arising under the QA T&amp;C and Nushift Terms
                  and Conditions.</li>
              </ul>
            </ul>
          </li>
          <li>
            <span class="item-no">3.12.2</span>Terms for User/Querist:
            <ul class="custom-list">
              <ul class="lower-rom-list">
                <li>The personally identifiable information of the querist shall be anonymized by Nushift prior to
                  posting the question on the Q&amp;A Platform;</li>
                <li>Q&amp;A Platform is a public forum for exchange of information, hence, any question posted by a
                  querist is visible publicly. It is advisable for a User to ensure that no confidential information is
                  published by them on the Q&amp;A Platform.</li>
                <li>User shall not use the Q&amp;A Platform for emergency situations.</li>
                <li>The User is prohibited to post any query/issue on the Q&amp;A Platform, which comprises of any
                  derogatory language, objectionable, pornographic and /or offensive content;</li>
                <li>Any response provided by the Practitioner shall not be construed as medical advice on the Q&amp;A
                  Platform. Q&amp;A Platform is a platform for exchange of information which is of general nature in a
                  question and answer format. This cannot be construed as a medical consultation with the Practitioner,
                  in any manner whatsoever.</li>
                <li>It is expressly clarified that no doctor-patient relationship is established between the user and
                  Practitioner, in any manner whatsoever, by indulging in this Q&amp;A. The Practitioner may provide
                  <b>probable</b> views, recommendations, suggestions and solutions to the question posted. However, it
                  is expressly clarified that any such view, recommendation, suggestion and solution shall not be
                  construed as medical advice. The person in question (<i>being Querist or not</i>) is advised to
                  consult with a doctor in this regard.</li>
                <li>All general Nushift Terms and Conditions shall govern the QA T&amp;C to the extent applicable.
                  Decision of Nushift is final and binding on the issues arising under the QA T&amp;C and Nushift Terms
                  and Conditions.</li>
              </ul>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <span class="item-no">3.13</span>Nushift DIAGNOSTICS
        <p>
          For detailed terms and conditions regarding diagnostics click <a href="/company/terms/diagnostic"
            target="_blank">here.</a>
        </p>
      </li>
      <li id="healthcash">
        <span class="item-no">3.14</span> Nushift HEALTHCASH
        <ul class="custom-list">
          <li>
            <span class="item-no">3.14.1</span> Nushift HealthCash is a grant of benefit in the form of credits in the
            Nushift accounts of the Users (“<b>Nushift HealthCash</b>”) in the form and under such circumstances decided
            by Nushift from time to time.
          </li>
          <li>
            <span class="item-no">3.14.2</span> Nushift HealthCash can be redeemed by the User only to pay online for
            the following services on the Website:
            <ul class="lower-rom-list">
              <li>Ordering medicines online;</li>
              <li>Consulting a doctor online;</li>
              <li>Booking diagnostic tests and health check-ups;</li>
              <li>Pre-paying for doctor appointment bookings; and</li>
              <li>Subscribing to Healthcare plans.</li>
            </ul>
            (The above services on the Website are collectively referred to as “<b>Permitted Services</b>”.)
          </li>
          <li>
            <span class="item-no">3.14.3</span> Every time a User using (i) Android version 4.31 or above; or (ii) iOS
            version 4.24 or above of the Nushift mobile application; or (iii) the Nushift website (www.Nushift.com),
            uses HealthCash to pay online for any of the Permitted Services availed by him/her, only such percentage of
            the total amount to be paid for the availed Permitted Services, as may be prescribed by Nushift from time to
            time on the respective Nushift mobile application or website ( www.Nushift.com ), can be redeemed by such
            User using the Nushift HealthCash per transaction.
          </li>
          <li>
            <span class="item-no">3.14.4</span> Nushift HealthCash neither can be converted to actual money nor can be
            transferred to any bank accounts.
          </li>
          <li>
            <span class="item-no">3.14.5</span> The Nushift HealthCash received by each User may or may not have an
            expiry date associated with it and the said expiry date is subject to the following:
            <ul class="lower-rom-list">
              <li>The expiry date associated with Nushift HealthCash may vary from time to time. However, the same
                cannot exceed beyond 2 (Two) years</li>
              <li>The expiry date of Nushift HealthCash may be intimated to each User by way of SMS or e-mail or in-App
                notification on the Website.</li>
              <li>The expiry date of Nushift HealthCash can be viewed by each User in the designated page for HealthCash
                in the Website.</li>
              <li>The User acknowledges that Nushift has agreed to offer Nushift HealthCash in its sole discretion.
                Nothing in these Terms shall be interpreted to restrict Nushift’s right to withdraw the Nushift
                HealthCash before the expiry date.</li>
            </ul>
          </li>
          <li>
            <span class="item-no">3.14.6</span> Nushift reserves its discretion to deny Nushift HealthCash to any User
            in its sole discretion, notwithstanding compliance by such User of the requirements set out in the Terms,
            applicable law or otherwise.
          </li>
          <li>
            <span class="item-no">3.14.7</span> Other than the conditions set out in these Terms, accrual, utilization,
            expiry or any other treatment of Nushift HealthCash may be further restricted by the terms governing the
            specific service or facility offered by Nushift or its business associates, the procurement of which
            entitles Nushift HealthCash to a User (“<b>HealthCash Terms</b>”). In the event of any inconsistency between
            these Terms and the HealthCash Terms in relation to accrual, utilization, expiry or any other treatment of
            Nushift HealthCash by a User, the HealthCash Terms shall override these Terms to the extent of such
            inconsistency.
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">4.</span> TERMS OF USE PRACTITIONERS</h5>
        <p>The terms in this Clause 4 are applicable only to Practitioners.</p>
        <ul class="custom-list">
          <li>
            <span class="item-no">4.1</span> LISTING POLICY
            <ul class="custom-list">
              <li>
                <span class="item-no">4.1.1</span>Nushift, directly and indirectly, collects information regarding the
                Practitioners’ profiles, contact details, and practice. Nushift reserves the right to take down any
                Practitioner’s profile as well as the right to display the profile of the Practitioners, with or without
                notice to the concerned Practitioner. This information is collected for the purpose of facilitating
                interaction with the End-Users and other Users. If any information displayed on the Website in
                connection with you and your profile is found to be incorrect, you are required to inform Nushift
                immediately to enable Nushift to make the necessary amendments.
              </li>
              <li>
                <span class="item-no">4.1.2</span>Nushift shall not be liable and responsible for the ranking of the
                Practitioners on external websites and search engines
              </li>
              <li>
                <span class="item-no">4.1.3</span>Nushift shall not be responsible or liable in any manner to the Users
                for any losses, damage, injuries or expenses incurred by the Users as a result of any disclosures or
                publications made by Nushift, where the User has expressly or implicitly consented to the making of
                disclosures or publications by Nushift. If the User had revoked such consent under the terms of the
                Privacy Policy, then Nushift shall not be responsible or liable in any manner to the User for any
                losses, damage, injuries or expenses incurred by the User as a result of any disclosures made by Nushift
                prior to its actual receipt of such revocation.
              </li>
              <li>
                <span class="item-no">4.1.4</span>Nushift reserves the right to moderate the suggestions made by the
                Practitioners through feedback and the right to remove any abusive or inappropriate or promotional
                content added on the Website. However, Nushift shall not be liable if any inactive, inaccurate,
                fraudulent, or non- existent profiles of Practitioners are added to the Website.
              </li>
              <li>
                <span class="item-no">4.1.5</span>Practitioners explicitly agree that Nushift reserves the right to
                publish the Content provided by Practitioners to a third party including content platforms.
              </li>
              <li>
                <span class="item-no">4.1.6</span>When you are listed on Nushift.com, End-Users may see a ‘show number’
                option. When End-Users choose this option, they choose to call your number through a free telephony
                service provided by Nushift, and the records of such calls are recorded and stored in Nushift’s servers.
                Such call will have an IVR message stating the purpose of recording your calls and your consent to such
                recordings which are dealt with as per the Privacy Policy. Such records may be accessed by Nushift for
                quality control and support related purposes and are dealt with only in accordance with the terms of the
                Privacy Policy. Such call facility provided to End-Users and to you by Nushift should be used only for
                appointment and booking purposes, and not for consultation on health-related issues. Nushift accepts no
                liability if the call facility is not used in accordance with the foregoing. In case you choose to not
                provide your consent to recording your calls that may have personal information required for appointment
                and booking purposes, Nushift reserves the right to not provide the Services for which such personal
                information is sought.
              </li>
              <li>
                <span class="item-no">4.1.7</span>You as a Practitioner hereby represent and warrant that you will use
                the Services in accordance with applicable law. Any contravention of applicable law as a result of your
                use of these Services is your sole responsibility, and Nushift accepts no liability for the same.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">4.2</span>PROFILE OWNERSHIP AND EDITING RIGHTS
            <p>
              Nushift ensures easy access to the Practitioners by providing a tool to update your profile information.
              Nushift reserves the right of ownership of all the Practitioner’s profile and photographs and to moderate
              the changes or updates requested by Practitioners. However, Nushift takes the independent decision whether
              to publish or reject the requests submitted for the respective changes or updates. You hereby represent
              and warrant that you are fully entitled under law to upload all content uploaded by you as part of your
              profile or otherwise while using Nushift’s services, and that no such content breaches any third party
              rights, including intellectual property rights. Upon becoming aware of a breach of the foregoing
              representation, Nushift may modify or delete parts of your profile information at its sole discretion with
              or without notice to you.
            </p>
          </li>
          <li>
            <span class="item-no">4.3</span>REVIEWS AND FEEDBACK DISPLAY RIGHTS OF Nushift
            <ul class="custom-list">
              <li>
                <span class="item-no">4.3.1</span>All Critical Content is content created by the Users of
                www.Nushift.com <b>(“Website”)</b> and the clients of Nushift customers and Practitioners, including the
                End-Users. As a platform, Nushift does not take responsibility for Critical Content and its role with
                respect to Critical Content is restricted to that of an ‘intermediary’ under the Information Technology
                Act, 2000. The role of Nushift and other legal rights and obligations relating to the Critical Content
                are further detailed in Clauses 3.9 and 5 of these Terms. Nushift’s Feedback Collection and Fraud
                Detection Policy, is annexed as the Schedule hereto, and remains subject always to these Terms.
              </li>
              <li>
                <span class="item-no">4.3.2</span>Nushift reserves the right to collect feedback and Critical Content
                for all the Practitioners, Clinics and Healthcare Providers listed on the Website.
              </li>
              <li>
                <span class="item-no">4.3.3</span>Nushift shall have no obligation to pre-screen, review, flag, filter,
                modify, refuse or remove any or all Critical Content from any Service, except as required by applicable
                law.
              </li>
              <li>
                <span class="item-no">4.3.4</span>You understand that by using the Services you may be exposed to
                Critical Content or other content that you may find offensive or objectionable. Nushift shall not be
                liable for any effect on Practitioner’s business due to Critical Content of a negative nature. In these
                respects, you may use the Service at your own risk. Nushift however, as an ‘intermediary, takes steps as
                required to comply with applicable law as regards the publication of Critical Content. The legal rights
                and obligations with respect to Critical Content and any other information sought to be published by
                Users are further detailed in Clauses 3.9 and 5 of these Terms.
              </li>
              <li>
                <span class="item-no">4.3.5</span>Nushift will take down information under standards consistent with
                applicable law, and shall in no circumstances be liable or responsible for Critical Content, which has
                been created by the Users. The principles set out in relation to third party content in the terms of
                Service for the Website shall be applicable mutatis mutandis in relation to Critical Content posted on
                the Website.
              </li>
              <li>
                <span class="item-no">4.3.6</span>If Nushift determines that you have provided inaccurate information or
                enabled fraudulent feedback, Nushift reserves the right to immediately suspend any of your accounts with
                Nushift and makes such declaration on the website alongside your name/your clinics name as determined by
                Nushift for the protection of its business and in the interests of Users.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">4.4</span>RELEVANCE ALGORITHM
            <p>
              Nushift has designed the relevance algorithm in the best interest of the End-User and may adjust the
              relevance algorithm from time to time to improve the quality of the results given to the patients. It is a
              pure merit driven, proprietary algorithm which cannot be altered for specific Practitioners. Nushift shall
              not be liable for any effect on the Practitioner’s business interests due to the change in the Relevance
              Algorithm.
            </p>
          </li>
          <li>
            <span class="item-no">4.5</span>INDEPENDENT SERVICES
            <p>
              Your use of each Service confers upon you only the rights and obligations relating to such Service, and
              not to any other service that may be provided by Nushift.
            </p>
          </li>
          <li>
            <span class="item-no">4.6</span>Nushift REACH RIGHTS
            <p>
              Nushift reserves the rights to display sponsored ads on the Website. These ads would be marked as
              “Sponsored ads”. Without prejudice to the status of other content, Nushift will not be liable for the
              accuracy of information or the claims made in the Sponsored ads. Nushift does not encourage the Users to
              visit the Sponsored ads page or to avail any services from them. Nushift will not be liable for the
              services of the providers of the Sponsored ads.
            </p>
            <p>
              You represent and warrant that you will use these Services in accordance with applicable law. Any
              contravention of applicable law as a result of your use of these Services is your sole responsibility, and
              Nushift accepts no liability for the same.
            </p>
          </li>
          <li>
            <span class="item-no">4.7</span>Nushift MEDICINE INFORMATION
            <p>
              For detailed terms and conditions regarding medicine information click <a href="/medicine-info/terms"
                target="_blank">here.</a>
            </p>
          </li>
          <li>
            <span class="item-no">4.8</span> BOOK APPOINTMENT AND CALL FACILITY
            <ul class="custom-list">
              <li>
                <span class="item-no">4.8.1</span>As a valuable partner on our platform we want to ensure that the
                Practitioners experience on the Nushift booking platform is beneficial to both, Practitioners and their
                Users.<br>
                For all terms and conditions of Book facility on Nushift profile check <a
                  href="https://www.Nushift.com/company/book-policy"> Book Standards </a>.
              </li>
              <li>
                <span class="item-no">4.8.2</span>Practitioner understands that, Nushift shall not be liable, under any
                event, for any comments or feedback given by any of the Users in relation to the Services provided by
                Practitioner. The option of publishing or modifying or moderating or masking (where required by law or
                norm etc.) the feedback provided by Users shall be solely at the discretion of Nushift.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">4.9</span> PRACTITIONER UNDERTAKING
            <p>
              The Practitioner is and shall be duly registered, licensed and qualified to practice medicine/ provide
              health care, wellness services, as per applicable laws/regulations/guidelines set out by competent
              authorities and the Practitioner shall not be part of any arrangement which will prohibit him/her from
              practicing medicine within the territory of India. The Practitioner shall at all times ensure that all the
              applicable laws that govern the Practitioner shall be followed and utmost care shall be taken in terms of
              the consultation/ services being rendered.
            </p>
          </li>
          <li>
            <span class="item-no">4.10</span> USAGE IN PROMOTIONAL &amp; MARKETING MATERIALS
            <p>
              In recognition of the various offerings and services provided by Nushift to Practitioner, Practitioner
              shall (subject to its reasonable right to review and approve): (a) allow Nushift to include a brief
              description of the services provided to Practitioner in Nushift’s marketing, promotional and advertising
              materials; (b) allow Nushift to make reference to Practitioner in case studies, and related marketing
              materials; (c) serve as a reference to Nushift’s existing and potential clients; (d) provide video logs,
              testimonials, e-mailers, banners, interviews to the news media and provide quotes for press releases; (e)
              make presentations at conferences; and/or (f) use the Practitioner’s name and/or logo, brand images, tag
              lines etc., within product literature, e-mailers, press releases, social media and other advertising,
              marketing and promotional materials.
            </p>
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">5.</span>RIGHTS AND OBLIGATIONS RELATING TO CONTENT</h5>
        <ul class="custom-list">
          <li>
            <span class="item-no">5.1</span>As mandated by Regulation 3(2) of the IG Rules, Nushift hereby informs Users
            that they are not permitted to host, display, upload, modify, publish, transmit, update or share any
            information that:
            <ul class="lower-rom-list">
              <li>
                belongs to another person and to which the User does not have any right to;
              </li>
              <li>
                is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous,
                invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or
                encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
              </li>
              <li>harm minors in any way;</li>
              <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
              <li>violates any law for the time being in force;</li>
              <li>
                deceives or misleads the addressee about the origin of such messages or communicates any information
                which is grossly offensive or menacing in nature;
              </li>
              <li>impersonate another person;</li>
              <li>
                contains software viruses or any other computer code, files or programs designed to interrupt, destroy
                or limit the functionality of any computer resource;
              </li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with
                foreign states, or public order or causes incitement to the commission of any cognizable offence or
                prevents investigation of any offence or is insulting any other nation.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">5.2</span>Users are also prohibited from:
            <ul class="lower-rom-list">
              <li>
                violating or attempting to violate the integrity or security of the Website or any Nushift Content;
              </li>
              <li>
                transmitting any information (including job posts, messages and hyperlinks) on or through the Website
                that is disruptive or competitive to the provision of Services by Nushift;
              </li>
              <li>
                intentionally submitting on the Website any incomplete, false or inaccurate information;
              </li>
              <li>making any unsolicited communications to other Users;</li>
              <li>
                using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars
                or intelligent agents) to navigate or search the Website;
              </li>
              <li>
                attempting to decipher, decompile, disassemble or reverse engineer any part of the Website;
              </li>
              <li>
                copying or duplicating in any manner any of the Nushift Content or other information available from the
                Website;
              </li>
              <li>framing or hot linking or deep linking any Nushift Content.</li>
              <li>
                circumventing or disabling any digital rights management, usage rules, or other security features of the
                Software.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">5.3</span>Nushift, upon obtaining knowledge by itself or been brought to actual
            knowledge by an affected person in writing or through email signed with electronic signature about any such
            information as mentioned above, shall be entitled to disable such information that is in contravention of
            Clauses 5.1 and 5.2. Nushift shall also be entitled to preserve such information and associated records for
            at least 90 (ninety) days for production to governmental authorities for investigation purposes.
          </li>
          <li>
            <span class="item-no">5.4</span>In case of non-compliance with any applicable laws, rules or regulations, or
            the Agreement (including the Privacy Policy) by a User, Nushift has the right to immediately terminate the
            access or usage rights of the User to the Website and Services and to remove non-compliant information from
            the Website.
          </li>
          <li>
            <span class="item-no">5.5</span>Nushift may disclose or transfer User-generated information to its
            affiliates or governmental authorities in such manner as permitted or required by applicable law, and you
            hereby consent to such transfer. The SPI Rules only permit Nushift to transfer sensitive personal data or
            information including any information, to any other body corporate or a person in India, or located in any
            other country, that ensures the same level of data protection that is adhered to by Nushift as provided for
            under the SPI Rules, only if such transfer is necessary for the performance of the lawful contract between
            Nushift or any person on its behalf and the User or where the User has consented to data transfer.
          </li>
          <li>
            Nushift respects the intellectual property rights of others and we do not hold any responsibility for any
            violations of any intellectual property rights
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">6.</span>TERMINATION</h5>
        <ul class="custom-list">
          <li>
            <span class="item-no">6.1</span>Nushift reserves the right to suspend or terminate a User’s access to the
            Website and the Services with or without notice and to exercise any other remedy available under law, in
            cases where,
            <ul class="lower-rom-list">
              <li>Such User breaches any terms and conditions of the Agreement;</li>
              <li>A third party reports violation of any of its right as a result of your use of the Services;</li>
              <li>Nushift is unable to verify or authenticate any information provide to Nushift by a User;</li>
              <li>
                Nushift has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of
                such User; or
              </li>
              <li>
                Nushift believes in its sole discretion that User’s actions may cause legal liability for such User,
                other Users or for Nushift or are contrary to the interests of the Website.
              </li>
            </ul>
          </li>
          <li>
            <span class="item-no">6.2</span>Once temporarily suspended, indefinitely suspended or terminated, the User
            may not continue to use the Website under the same account, a different account or re-register under a new
            account. On termination of an account due to the reasons mentioned herein, such User shall no longer have
            access to data, messages, files and other material kept on the Website by such User. The User shall ensure
            that he/she/it has continuous backup of any medical services the User has rendered in order to comply with
            the User’s record keeping process and practices.
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">7.</span>LIMITATION OF LIABILITY</h5> In no event, including but not limited to
        negligence, shall Nushift, or any of its directors, officers, employees, agents or content or service providers
        (collectively, the “Protected Entities”) be liable for any direct, indirect, special, incidental, consequential,
        exemplary or punitive damages arising from, or directly or indirectly related to, the use of, or the inability
        to use, the Website or the content, materials and functions related thereto, the Services, User’s provision of
        information via the Website, lost business or lost End-Users, even if such Protected Entity has been advised of
        the possibility of such damages. In no event shall the Protected Entities be liable for:
        <ul class="lower-rom-list">
          <li>
            provision of or failure to provide all or any service by Practitioners to End- Users contacted or managed
            through the Website;
          </li>
          <li>
            any content posted, transmitted, exchanged or received by or on behalf of any User or other person on or
            through the Website;
          </li>
          <li>
            any unauthorized access to or alteration of your transmissions or data; or
          </li>
          <li>any other matter relating to the Website or the Service.</li>
        </ul>
        <p>
          In no event shall the total aggregate liability of the Protected Entities to a User for all damages, losses,
          and causes of action (whether in contract or tort, including, but not limited to, negligence or otherwise)
          arising from this Agreement or a User’s use of the Website or the Services exceed, in the aggregate Rs. 1000/-
          (Rupees One Thousand Only).
        </p>
      </li>
      <li>
        <h5><span class="item-no">8.</span>RETENTION AND REMOVAL</h5>
        <p>
          Nushift may retain such information collected from Users from its Website or Services for as long as
          necessary, depending on the type of information; purpose, means and modes of usage of such information; and
          according to the SPI Rules. Computer web server logs may be preserved as long as administratively necessary.
        </p>
      </li>
      <li>
        <h5><span class="item-no">9.</span>APPLICABLE LAW AND DISPUTE SETTLEMENT</h5>
        <ul class="custom-list">
          <li>
            <span class="item-no">9.1</span>You agree that this Agreement and any contractual obligation between Nushift
            and User will be governed by the laws of India.
          </li>
          <li>
            <span class="item-no">9.2</span>Any dispute, claim or controversy arising out of or relating to this
            Agreement, including the determination of the scope or applicability of this Agreement to arbitrate, or your
            use of the Website or the Services or information to which it gives access, shall be determined by
            arbitration in India, before a sole arbitrator appointed by Nushift. Arbitration shall be conducted in
            accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be Bangalore.
            All proceedings of such arbitration, including, without limitation, any awards, shall be in the English
            language. The award shall be final and binding on the parties to the dispute.
          </li>
          <li>
            <span class="item-no">9.3</span>Subject to the above Clause 9.2, the courts at Bengaluru shall have
            exclusive jurisdiction over any disputes arising out of or in relation to this Agreement, your use of the
            Website or the Services or the information to which it gives access.
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">10.</span>CONTACT INFORMATION GRIEVANCE OFFICER</h5>
        <ul class="custom-list">
          <li>
            <span class="item-no">10.1</span>If a User has any questions concerning Nushift, the Website, this
            Agreement, the Services, or anything related to any of the foregoing, Nushift customer support can be
            reached at the following email address: support&#64;Nushift.com or via the contact information available from
            the following hyperlink: <a href="/company/contact" target="_blank">www.Nushift.com/contact</a>.
          </li>
          <li>
            <span class="item-no">10.2</span>In accordance with the Information Technology Act, 2000, and the rules made
            there under, if you have any grievance with respect to the Website or the service, including any
            discrepancies and grievances with respect to processing of information, you can contact our Grievance
            Officer at:
            <p>
              Name: Surabhi Patodia<br>
              Nushift Technologies Pvt Ltd<br>
              WeWork Salarpuria Symbiosis,<br>
              Arekere Village, Begur Hobli,<br>
              Bannerghatta Road, <br>
              Bangalore - 560076. <br>
              Phone: <a href="tel:8880588999">8880588999</a><br>
              Email: <a href="mailto:privacy@Nushift.com">privacy&#64;Nushift.com</a><br>
            </p>
            <p>In the event you suffer as a result of access or usage of our Website by any person in violation of Rule
              3 of the IG Rules, please address your grievance to the above person.</p>
          </li>
        </ul>
      </li>
      <li>
        <h5><span class="item-no">11.</span>SEVERABILITY</h5>
        <p>
          If any provision of the Agreement is held by a court of competent jurisdiction or arbitral tribunal to be
          unenforceable under applicable law, then such provision shall be excluded from this Agreement and the
          remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable
          in accordance with its terms; provided however that, in such event, the Agreement shall be interpreted so as
          to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and
          intention of the excluded provision as determined by such court of competent jurisdiction or arbitral
          tribunal.
        </p>
      </li>
      <li>
        <h5><span class="item-no">12.</span>WAIVER</h5>
        <p>
          No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or
          consent shall be in writing and signed by Nushift. Any consent by Nushift to, or a waiver by Nushift of any
          breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any
          other different or subsequent breach.
        </p>
      </li>
      <li id="nutrichoiceoffer">
        <h5><span class="item-no">13.</span>BRITANNIA NUTRICHOICE OFFER TERMS AND CONDITIONS</h5>
        <p>
          These terms and conditions (“<b>Terms</b>”) are set out in addition to the terms set out above this section on
          this web page (“<b>General Terms</b>”) and apply to grant of benefit in the form of credits in the Nushift
          accounts of the Users (“<b>Nushift HealthCash</b>”) in the form and under such circumstances as set out in
          these Terms by usage of the coupon codes available along with the Britannia Products (defined below)
          (“<b>HealthCash Activation Codes</b>”). The offer of Nushift HealthCash is available for the users of the
          Website (“<b>Users</b>”) during the period October 1, 2018 till September 30, 2019 (both days included)
          (“<b>Offer Period</b>”) only. Details of the Terms are set out below:
        </p>
        <ul class="custom-list">
          <li>
            <span class="item-no">13.1</span>The offer of Nushift HealthCash can be availed by User(s) by purchase of
            Britannia Nutri Choice Essentials 150 g Oat Cookies and Britannia Nutri Choice Essentials 150 g Ragi Cookies
            (“<b>Britannia Products</b>”), during the Offer Period in accordance with the Terms herein. The offer on
            Britannia Products specified in these Terms are hereinafter referred to as “<b>Nutrichoice Offer</b>”.
          </li>
          <li>
            <span class="item-no">13.2</span>The HealthCash Activation Codes are valid for activation up to September
            30, 2019 (“<b>Activation Last Date</b>”), post which the HealthCash Activation Codes cannot be used for
            activating the Nushift HealthCash.
          </li>
          <li>
            <span class="item-no">13.3</span>In order to activate the Nushift HealthCash through the HealthCash
            Activation Code, the User will need to send the following text message to the short-code number <b>87223
              87223</b>: [<mark>Nushift &lt;HealthCash Activation Code&gt;</mark>] (“<b>Activation Request</b>”).
          </li>

          <li>
            <span class="item-no">13.4</span>By sending the Activation Request, the User agrees to the Terms as well as
            the General Terms, and in case the User is not already a registered user on Nushift’s platform, i.e.
            www.Nushift.com and/or Nushift’s mobile application (“<b>Website</b>”), he/she agrees to create a Nushift
            account on the Website linked to the mobile number from which the User sends the Activation Request. The
            complete terms and conditions pertaining to this offer are provided here. Please go through the entire terms
            on this web page in order to view the complete terms for this Nutrichoice Offer.
          </li>
          <li>
            <span class="item-no">13.5</span>Each HealthCash Activation Code can only be used once by the Users.
          </li>
          <li>
            <span class="item-no">13.6</span>Each HealthCash Activation Code will allow the User to activate Nushift
            HealthCash equivalent to INR 50 (Indian Rupees Fifty Only).
          </li>
          <li>
            <span class="item-no">13.7</span>A User will be able to activate Nushift HealthCash through the Nutrichoice
            Offer only for a maximum of 3 (three) times in his/her Nushift account by using the HealthCash Activation
            Codes. Once a User uses his/her first HealthCash Activation Code to activate Nushift HealthCash, such User
            cannot use the second HealthCash Activation Code without redeeming the existing Nushift HealthCash in the
            User’s account with Nushift. The same logic applies when the User is activating Nushift HealthCash by using
            the third HealthCash Activation Code.
          </li>
          <li>
            <span class="item-no">13.8</span>Nushift HealthCash activated through the NutriChoice Offer can be redeemed
            by the User only to pay online for the following services on the Website:
            <ul class="lower-rom-list">
              <li>ordering medicines online;</li>
              <li>consulting a doctor online;</li>
              <li>booking diagnostic tests and health check-ups;</li>
              <li>pre-paying for doctor appointment bookings.</li>
            </ul>
            (The above services on the Website are collectively referred to as “<b>Permitted Services</b>”.)
          </li>
          <li>
            <span class="item-no">13.9</span>Nushift HealthCash activated through one HealthCash Activation Code will be
            available for the User for a period of 14 (fourteen) days from the date of activation of the Nushift
            HealthCash by the User. After this period, these allotted Nushift HealthCash will expire, if the User does
            not redeem such Nushift HealthCash in any of the Permitted Services.
          </li>
          <li>
            <span class="item-no">13.10</span>Nushift is not liable to accept activation of Nushift HealthCash through
            any HealthCash Activation Code which a User has received out of an unsealed pack of a Britannia Product.
          </li>
          <li>
            <span class="item-no">13.11</span>Nushift is not liable to accept activation of Nushift HealthCash through
            any HealthCash Activation Code in case such HealthCash Activation Code has already been used as per
            Nushift’s systems, even in case such HealthCash Activation Code has been taken out from a sealed pack of a
            Britannia Product. Nushift shall have the sole discretion in this matter.
          </li>
          <li>
            <span class="item-no">13.12</span>The User acknowledges that Nushift has agreed to offer Nushift HealthCash
            in its sole discretion. Nothing in these Terms or the General Terms shall be interpreted to obligate Nushift
            to offer Nushift HealthCash beyond the Offer Period or to restrict its right to withdraw the NutriChoice
            Offer during the Offer Period. Nushift reserves its discretion to deny Nushift HealthCash to any User in its
            sole discretion, notwithstanding compliance by such User of the requirements set out in these Terms, the
            General Terms, applicable law or otherwise.
          </li>
          <li>
            <span class="item-no">13.13</span>The User should raise all concerns pertaining to the Britannia Products
            with Britannia Industries Limited (“<b>Britannia</b>”) as per the contact details specified on Britannia
            products packaging. Nushift shall not be liable in any manner in this regard.
          </li>
          <li>
            <span class="item-no">13.14</span>The User should raise all concerns pertaining to the Permitted Services
            with Nushift Technologies Private Limited (“<b>Nushift</b>”) at <a
              href="mailto:support@Nushift.com">support&#64;Nushift.com</a>. Britannia shall not be liable in any manner in
            this regard.
          </li>
          <li>
            <span class="item-no">13.15</span>All capitalized terms used but not defined herein will have the same
            meaning as ascribed to them under the General Terms. To the extent of any inconsistency between these Terms
            and the General Terms, the General Terms shall prevail.
          </li>
          <li>
            <span class="item-no">13.16</span>The User agrees that these Terms shall be governed in accordance with the
            laws of India.
          </li>
          <li>
            <span class="item-no">13.17</span>Any dispute arising out of these Terms shall be determined by arbitration
            in India, before a sole arbitrator appointed by Nushift. Arbitration shall be conducted in accordance with
            the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be Bangalore. All proceedings
            of such arbitration, including, without limitation, any awards, shall be in the English language. The
            arbitration award shall be final and binding on the parties to the dispute.
          </li>
          <li>
            <span class="item-no">13.18</span>Subject to the terms of Clause 17 above, the courts of Bangalore shall
            have exclusive jurisdiction to try all disputes arising pursuant to or under these Terms.
          </li>
        </ul>
        <h5><span class="item-no" id="health-plan">14.</span> Nushift HEALTH PLAN – USER TERMS AND CONDITIONS</h5>
        <p>
          For the purposes of the following terms and conditions (<b>“Nushift Health Plan Terms”</b>), the service
          provided to You (<i>as defined below</i>) is facilitation of consultation and other benefits, as detailed
          hereinafter, with the Practitioners (healthcare providers including all its doctors, agents, employees)
          registered pharmacies which facilitate the medicine orders (<b>“Registered Pharmacies”</b>) and diagnostic
          centres which conduct diagnostic tests (<b>“Diagnostic Centres”</b>), and other benefits the Nushift may offer
          in future, as a part of the subscription to the Nushift Health Plan (<b>“Health Plan Service”</b> /
          <b>“Plan”</b>). Nushift operates and facilitates the Health Plan Service provided through the Website.
        </p>
        <ul class="custom-list">
          <li>
            <span class="item-no">14.1</span> NATURE AND APPLICABILITY OF TERMS
            <p>
              Please go through these Nushift Health Plan Terms carefully before you decide to avail the Health Plan
              Service facilitated by Nushift on its Website. The Nushift Health Plan Terms set out herein are in
              addition to the Nushift Terms and Conditions (available at <a
                href="https://www.Nushift.com/company/terms">https://www.Nushift.com/company/terms</a>) read with the
              Privacy Policy (available at <a
                href="https://www.Nushift.com/company/privacy">https://www.Nushift.com/company/privacy</a>), (the Terms
              and Conditions and Privacy Policy are together referred as <b>“Standard Policies”</b>) and together they
              constitute a legal agreement between You and Nushift. The Standard Policies and the Nushift Health Plan
              Terms are to be read in connection with Your visit to the Website and Your use of the Health Plan Service
              respectively. To the extent of any inconsistency between the Standard Policies and the Nushift Health Plan
              Terms herein, the Nushift Health Plan Terms herein shall prevail over the Standard Policies to the extent
              of the inconsistency.
            </p>
            <p>
              These Nushift Health Plan Terms apply to any person who subscribes to the Health Plan Service on behalf or
              for the benefit of a patient, either individually or inclusive of his/her family members / relatives. The
              primary user is required to be registered on the Website and should have subscribed to the Health Plan
              Service. For the purposes of these Nushift Health Plan Terms, the primary subscriber to the Health Plan
              Service is an individual designed as primary user (<b>“Primary User”</b>) in the Plan Page (as defined
              hereafter), who subscribes to the Health Plan Service either individually and/or for benefit of other
              members, which could include any other individual nominated by the Primary Member (hereinafter
              collectively known as the <b>“Covered Members”</b>). The Covered Members and a Primary User are
              collectively and individually hereinafter referred to as the <b>“You”</b> / <b>“Your”</b> / <b>“User”</b>
              / <b>“Users”</b>. As a part of availing the Health Plan Service, You may choose different packages
              available pursuant to the Health Plan Service / Plan which best suits Your needs as per the plan details
              set out in your Nushift application (<b>“Plan Details”</b>). The User specific details will be visible to
              You on Your purchased plan page (<b>“Plan Page”</b>) hosted on Your Nushift application.
            </p>
            <p>
              If you have any questions about any part of these Nushift Health Plan Terms, feel free to contact us at <a
                href="mailto:support@Nushift.com">support&#64;Nushift.com</a>.
            </p>
            <p>
              By availing the Health Plan Service, you signify your acceptance of the terms of the Standard Policies and
              these Nushift Health Plan Terms.
            </p>
            <p>
              We reserve the right to modify or terminate any portion of these Nushift Health Plan Terms and the
              Standard Policies for any reason and at any time, and such modifications shall be informed to You by
              appropriate notifications on the Website. You should read these Nushift Health Plan Terms and the Standard
              Policies at regular intervals. Your continued availing of Health Plan Service following any such
              modification constitutes your agreement to follow and be bound by these Terms and the Standard Policies so
              modified.
            </p>
            <p>
              You acknowledge that you will be bound by these Nushift Health Plan Terms specifically for availing any of
              the Health Plan Service. If you do not agree with any part of these Nushift Health Plan Terms and/or the
              Standard Policies, please do not use the Website or avail the Health Plan Service.
            </p>
            <p>
              Your access and use of the Health Plan Service will be solely at the discretion of Nushift.
            </p>
          </li>
          <li>
            <span class="item-no">14.2</span> CONDITIONS OF USE
            <p>
              You must be eighteen (18) years of age or older to register on the Website for availing the Health Plan
              Service as a Primary User. As a Primary User, You can add minors as Covered Member(s) under the Health
              Plan Service availed and subscribed to with the express understanding that the Primary Member shall be
              entirely responsible and liable on all counts for compliance with these Nushift Health Plan Terms for and
              on behalf of such Covered Member who is below the age of majority. By registering, visiting and using the
              Website for availing the Health Plan Service or accepting these Terms and the Standard Policies, You
              represent and warrant to Nushift that You are eighteen (18) years of age or older and have the appropriate
              authorization to contract on behalf of a Covered Member who is below the age of majority, and that You
              have the right, authority and capacity to use the Website for availing the Health Plan Service and agree
              to and abide by these Terms.
            </p>
          </li>
          <li>
            <span class="item-no">14.3</span> TERMS OF THE HEALTH PLAN SERVICE <p></p>
            <ul class="custom-list">
              <li>
                <span class="item-no">14.3.1</span> Term: The Health Plan Service subscribed by You will be valid for
                the period as per the Plan Details commencing from the date of the subscription of the Health Plan
                Service (<b>“Term”</b>). After the expiry of the Term, You may choose a different Plan as per the Plan
                Details and subscribe thereto or renew Your existing subscription, on such terms that are valid and
                subsisting as on date of subscription or renewal. The benefits under the Health Plan Service cannot be
                carried forward after the expiry of the Term.
              </li>
              <li>
                <span class="item-no">14.3.2</span> Registration: The Primary User is a registered user on the Website
                and has subscribed to the Health Plan Service on the Website by paying the applicable fees as per the
                Plan Details. You could avail the Plan solely for You or for Yourself and any Covered Member(s). Each
                Covered Member, who is covered by the Health Plan Service subscribed by You will have to follow an
                authentication mechanism to utilize the Health Plan Service. The maximum number of permissible Covered
                Members per each Primary User will be set out in the Plan Details. Covered Members, once added by the
                Primary User cannot be removed, modified or edited.
              </li>
              <li>
                <span class="item-no">14.3.3</span> Information: As a part of the registration process for Health Plan
                Service, You will fill in certain personal information and details in order to create a medical history
                and profile which will be accessible to the Practitioners. The information shared by You will be dealt
                with in accordance with the Standard Policies.
              </li>
              <li>
                <span class="item-no">14.3.4</span> Health Plan Service details: As a part of Health Plan Service, You
                are entitled to benefits (which may include such number of consultations, offers on medicines which are
                ordered through the Website, discounted health check-ups, discounts / offers on diagnostics tests and
                Family Doctor benefit, or any other benefit that Nushift may offer in the future) that are set out in
                the Plan Details.
              </li>
              <li>
                <span class="item-no">14.3.5</span> Health Plan Service process: After subscribing to Health Plan
                Service, You will be eligible to avail the benefits covered under the Health Plan Service as under:
                <ul class="lower-rom-list">
                  <li>
                    Consultations: <b><i>Covered Consultations and their Utilization:</i></b> As part of the Health Plan
                    Service, You are entitled to certain number of consultations with Covered Practitioners at a
                    discounted rate (<b>“Covered Consultations”</b>). For the purposes of these Terms, “Covered
                    Practitioners” are those Practitioners who have been onboarded by Nushift to provide You the Covered
                    Consultation as part of Health Plan Service. The number of Covered Consultations that You are
                    eligible for shall be displayed under Your Plan Page. The Covered Consultations can be availed by
                    You either by booking through the Website by using the Book facility (as published on the Website)
                    or by way of a walk-in. It is clarified that the benefits under the Covered Consultations will be
                    calculated cumulatively and not per member. Illustratively, 1 Primary User has subscribed to the
                    Health Plan Service with 4 Covered Members who are cumulatively entitled to 10 Covered
                    Consultations. This means that as a group (Primary User and the Covered Members) the number of
                    Covered Consultations is 10. These 10 Covered Consultations can be utilized by 1 individual User or
                    more than 1 individual user in such combination and proportion that they choose as a group. Further,
                    if You have exhausted the Covered Consultations under the Health Plan Service, You will still be
                    able to avail other benefits which are a part of the Health Plan Service, however, if You seek a
                    consultation with the Covered Practitioners after exhausting the Covered Consultation, You will have
                    to pay to the Covered Practitioner their standard rate at their establishment. If before the expiry
                    of the Term, You have exhausted the Covered Consultations that You were eligible for, You may have
                    an option to renew Health Plan Service solely for the purpose of subscribing to additional number of
                    Covered Consultation during the Term. <br>
                    <b><i>Authentication:</i></b> If You are booking a Covered Consultation through Nushift’s Book
                    facility, then the Plan Details, shall indicate such Covered Consultation to have been consumed at
                    the time of booking such Covered Consultation, unless you have cancelled it as per permissible
                    timelines set out in the Standard Policies. However, if You are availing the Covered Consultation by
                    way of a walk-in appointment, then the Plan Details shall indicate such Covered Consultation to have
                    been consumed once You authenticate Your booking at such Covered Practitioner’s establishment.
                    Covered consultations do not include consultations with Physiotherapist, Psychiatrist, Ayurveda, and
                    Homeopathy.
                  </li>
                  <li>
                    Medicine: As part of the Health Plan Service, You are eligible to receive offers on order of
                    medicines (as offered for sale by Registered Pharmacies which are the sellers of such medicines)
                    through the Website during the Term as per the Plan Details. The type of offers will be set out
                    under your Plan Details. The other terms and conditions governing the order of medicines which are
                    available at <a href="https://www.Nushift.com/order/terms">https://www.Nushift.com/order/terms</a>
                    (<b>“Order Terms”</b>) will applicable to You in conjunction with the Standard Policies. The offers
                    on purchase of medicines is available only on purchase of medicines through the Website and not via
                    any other mode. For the purposes of these Nushift Health Plan Terms, “Registered Pharmacies” are
                    those pharmacies who have been on-boarded by Nushift to offer You sale of medicine as part of the
                    Health Plan Service through the Website. Additionally, the Order Terms applicable to Nushift and
                    User on medicine order will apply ‘as is’ under these Nushift Health Plan Terms. It is to be noted
                    that Nushift is not the seller of the medicines but only a facilitator which facilitates procurement
                    of medicine between You and a Registered Pharmacist. Nushift is in no manner liable for or
                    responsible towards the underlying medicine sale between the User and the registered pharmacist.
                    Nushift hereby disclaims and excludes all warranties with respect to all services, information
                    and/or products contained on a medicine order, express, implied or statutory.
                  </li>
                  <li>
                    Diagnostics: As part of Health Plan Service, You will be eligible to receive discounts / offers on
                    the diagnostic tests availed by You (undertaken by Diagnostic Centres) through the Website for the
                    Term as per the Plan Details. The quantum of the discount or type of offers will be set out under
                    your Plan Details. The other terms and conditions governing the lab/diagnostic tests which are
                    available at <a
                      href="https://www.Nushift.com/company/terms/diagnostic">https://www.Nushift.com/company/terms/diagnostic</a>
                    will applicable to You in conjunction with the Standard Policies. For the purposes of these Terms,
                    “Diagnostic Centres” are those diagnostic centres who have been onboarded by Nushift to offer You
                    diagnostic tests under the Health Plan Service. It is to be noted that the terms applicable to
                    Nushift and User on diagnostic tests will apply ‘as is’ under these Terms. The lab that You choose
                    for the diagnostic test will offer at-home diagnostic service, and may offer walk-in tests at its
                    discretion. It is clarified that all diagnostic tests may not be covered under Health Plan Service
                    which is at the discretion of such Diagnostic Centres. It is further clarified that Nushift does not
                    conduct any diagnostic tests but is only a facilitator between the User and the Diagnostic Centres.
                    Nushift is in no manner liable for or responsible towards the actual test and analysis undertaken by
                    the Diagnostic Centre. Nushift disclaims and excludes all warranties in relation to any diagnostic
                    service availed by the User as part of the Health Plan Service.
                  </li>
                  <li>
                    Health Check-ups: As a benefit extended to You as a subscriber to Health Plan Service, You will get
                    health check-ups at discounted fees during the Term. The details (including the number) of health
                    check-ups will be visible to You under your Plan Page. As with Covered Consultations, the Primary
                    User and the Covered Members may, as a group, choose to avail the health check-ups in such
                    proportion amongst themselves as they deem fit. Health check-up would be provided by the Covered
                    Practitioners and the Diagnostic Centres.
                  </li>
                  <li>
                    Family Doctor: As a part of the Health Plan Service, You will also be eligible to avail the benefit
                    of online tele-consultation with a team of Practitioners (<b>“Family Doctor”</b>). The Family Doctor
                    will be available to You for consult and You are not required to pay any additional sum to consult
                    with such Family Doctor. The Family Doctor benefit may be availed either through a phone call or
                    chat or a video call. The Standard Policies applicable to You with regard to the online consultation
                    (Nushift Consult / Consult) will apply “as is”. Please note that the benefit of Family Doctor
                    Consult is merely a consulting model and any interactions and associated issues with the
                    Practitioner acting as Family Doctor, including but not limited to the Your health issues and/or
                    Your experiences is strictly between You and such Practitioner. You shall not hold Nushift
                    responsible for any such interactions and associated issues. Any conversations that You have had
                    with Family Doctor will be retained in Nushift database as per the applicable laws and subject to
                    confidentiality as laid out in the Standard Policies. Additionally, Nushift in no manner endorses
                    any Practitioner who is acting in the capacity of Family Doctor, that You consult and is not in any
                    manner responsible for any drug/medicines prescribed or the therapy prescribed by such Family Doctor
                    on the basis of the consultation. Further, Nushift shall not be responsible for any breach of
                    service or service deficiency by such Practitioner. It is further clarified that Nushift is not a
                    medical service provider, nor is it involved in providing any healthcare or medical advice or
                    diagnosis, it shall hence not be responsible and owns no liability to either You or any Practitioner
                    for any outcome from the consultation between You and such Practitioner. The Family Doctor benefit
                    is made available to You to obtain consultation from a Practitioner as a part of the Health Plan
                    Service and is in no way intending to replace physical consultations with the Practitioner.
                  </li>
                </ul>
              </li>
              <li>
                <span class="item-no">14.3.6</span> Payment for the Health Plan Service: You will pay to Nushift at the
                time of subscribing to the Health Plan Service, at the rates set out in the Plan Details. At the time of
                visiting the establishment of the Practitioner for Covered Consultation, You are not required to pay any
                amount as long as your eligibility for Covered Consultations are valid and has been set out in the Plan
                Page. After availing the Covered Consultation service, You are required to let the billing department of
                such Practitioner’s establishment know, that You have availed it by way of a Covered Consultation as
                part of Health Plan Service which will then be authenticated by the Practitioner’s establishment.
              </li>
              <li>
                <span class="item-no">14.3.7</span>
                Health Plan Service Cancellation and Refund Policy:
                <div>You may have an option to cancel Your subscription to the Health Plan Services. You will contact
                  Nushift on the customer support chat channel regarding cancelling Your subscription to the Health Plan
                  Service. Further, Nushift may refund the amount paid by You towards your subscription of the Health
                  Plan Services (“Health Plan Subscription amount”), subject to the following conditions:</div>
                <ul class="custom-list">
                  <li>
                    <span class="item-no">1.</span> In the event the Health Plan Subscription amount is Rs. 3,999
                    (Rupees Three Thousand Nine Hundred and Ninety Nine) or Rs. 5,999 (Rupees Five Thousand Nine Hundred
                    and Ninety Nine) the refund scheme is as follows:
                    <ul class="lower-rom-list">
                      <li>
                        If the user has not availed any of the Health Plan Services which are mentioned in the User’s
                        Plan Details within a period of 15 (Fifteen) days from the date of subscribing to the Health
                        Plan Services (“Free Look Period”) and if the user requests for a cancellation of his/her Health
                        Plan Services during the Free Look Period, Nushift will refund the entire Health Plan
                        Subscription amount which was paid by the user.
                      </li>
                      <li>
                        If the user requests for cancellation of his/her subscription to the Health Plan Services during
                        the Free Look Period and if the user has already availed certain Health Plan Services which are
                        mentioned in the user’s Plan Details during the Free Look Period, Nushift will deduct maximum of
                        Rs. 1,000 (Rupees One Thousand) or the actual value of the Health Plan Services availed by the
                        user, whichever is higher.
                      </li>
                      <li>
                        If the user requests for cancellation of his/her subscription to the Health Plan Services after
                        expiry of the Free Look Period, Nushift will refund the Health Plan Subscription amount in the
                        following manner:
                        <ul class="lower-alpha-list">
                          <li>
                            If the cancellation request by the user is within 16 (Sixteen) days to 89 (Eighty Nine) days
                            from the date of user’s subscription to the Health Plan Services, Nushift will refund 75%
                            (Seventy Five percent) of the Health Plan Subscription amount or the actual cost of Health
                            Plan Services availed by the user, whichever is higher;
                          </li>
                          <li>
                            If the cancellation request by the user is within 90 (Ninety) days to 179 (One hundred and
                            Seventy Nine) days from the date of user’s subscription to the Health Plan Services, Nushift
                            will refund 50% (Fifty percent) of the Health Plan Subscription amount or the actual cost of
                            Health Plan Services availed by the user, whichever is higher;
                          </li>
                          <li>
                            If the cancellation request by the user is within 180 (One Hundred and Eighty) days to 269
                            (Two hundred and Sixty Nine) from the date of user’s subscription to the Health Plan
                            Services, Nushift will refund 25% (Twenty Five percent) of the Health Plan Subscription
                            amount or the actual cost of Health Plan Services availed by the user, whichever is higher;
                            and
                          </li>
                          <li>
                            The user will not be entitled to any refund of the Health Plan Subscription amount if the
                            cancellation request by the user is after 269 (Two Hundred and Sixty Nine) from the date of
                            user’s subscription to the Health Plan Services.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span class="item-no">2.</span> In the event the Health Plan Subscription amount paid by the user is
                    Rs. 1,999 (Rupees One Thousand Nine Hundred and Ninety Nine) or Rs.2,999 (Rupees Two Thousand Nine
                    Hundred and Ninety Nine) the refund scheme is as follows:
                    <ul class="lower-rom-list">
                      <li>
                        If the user has not availed any of the Health Plan Services which are mentioned in the user’s
                        Plan Details within the Free Look Period and if the user requests for a cancellation of his/her
                        Health Plan Services during the Free Look Period, Nushift will refund the entire Health Plan
                        Subscription amount which was paid by the user.
                      </li>
                      <li>
                        If the user requests for cancellation of his/her subscription to the Health Plan Services during
                        the Free Look Period and if the user has already availed certain Health Plan Services which are
                        mentioned in the user’s Plan Details during the Free Look Period, Nushift will refund the Health
                        Plan Subscription amount after deducting the consultation fees (as mentioned by the respective
                        doctor on the Website) for the consultation(s) availed by the user as part of the Health Plan
                        Services.
                      </li>
                      <li>
                        If the user requests for cancellation of his/her subscription to the Health Plan Services after
                        expiry of the Free Look Period, Nushift will refund the Health Plan Subscription amount in the
                        following manner:
                        <ul class="lower-alpha-list">
                          <li>
                            If the cancellation request by the user is within 16 (Sixteen) days to 89 (Eighty Nine) days
                            from the date of user’s subscription to the Health Plan Services, Nushift will refund 75%
                            (Seventy Five percent) of the Health Plan Subscription amount or the actual cost of Health
                            Plan Services availed by the user, whichever is higher;
                          </li>
                          <li>
                            If the cancellation request by the user is within 90 (Ninety) days to 179 (One hundred and
                            Seventy Nine) days from the date of user’s subscription to the Health Plan Services, Nushift
                            will refund 50% (Fifty percent) of the Health Plan Subscription amount or the actual cost of
                            Health Plan Services availed by the user, whichever is higher;
                          </li>
                          <li>
                            If the cancellation request by the user is within 180 (One Hundred and Eighty) days to 269
                            (Two hundred and Sixty Nine) days from the date of user’s subscription to the Health Plan
                            Services, Nushift will refund 25% (Twenty Five percent) of the Health Plan Subscription
                            amount or the actual cost of Health Plan Services availed by the user, whichever is higher;
                            and
                          </li>
                          <li>
                            The user will not be entitled to any refund of the Health Plan Subscription amount if the
                            cancellation request by the user is after 269 (Two Hundred and Sixty Nine) days from the
                            date of user’s subscription to the Health Plan Services.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span class="item-no">3.</span> In the event the Health Plan Subscription amount paid by the user is
                    Rs.399 (Rupees Three Hundred and Ninety Nine) or Rs.499 (Rupees Four Hundred and Ninety Nine) or
                    Rs.799 (Rupees Seven Hundred and Ninety Nine)the refund scheme is as follows:
                    <ul class="lower-rom-list">
                      <li>
                        If the user has not availed any of the Health Plan Services which are mentioned in the user’s
                        Plan Details within a period of 7 (Seven) days from the date of subscribing to the Health Plan
                        Services (“Free Look Period”) and if the user requests for a cancellation of his/her Health Plan
                        Services during the Free Look Period, Nushift will refund the entire Health Plan Subscription
                        amount which was paid by the user.
                      </li>
                      <li>
                        If the user requests for cancellation of his/her subscription to the Health Plan Services during
                        the Free Look Period and if the user has already availed certain Health Plan Services which are
                        mentioned in the user’s Plan Details during the Free Look Period, Nushift will refund the Health
                        Plan Subscription amount after deducting the consultation fees (as mentioned by the respective
                        doctor on the Website) for the consultation(s) availed by the user as part of the Health Plan
                        Services.
                      </li>
                      <li>
                        The user will not be entitled to any refund of the Health Plan Subscription amount if the
                        cancellation request by the user is after 7 (Seven) days from the date of user’s subscription to
                        the Health Plan Services.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span class="item-no">4.</span> In the event the Health Plan Subscription amount paid by the user is
                    Rs.1499 (Rupees One Thousand Four Hundred and Ninety Nine) the refund scheme is as follows:
                    <ul class="lower-rom-list">
                      <li>
                        If the user has not availed any of the Health Plan Services which are mentioned in the user's
                        Plan Details within a period of 15 (Fifteen) days from the date of subscribing to the Health
                        Plan Services ("Free Look Period") and if the user requests for a cancellation of his/her Health
                        Plan Services during the Free Look Period, Nushift will refund the entire Health Plan
                        Subscription amount which was paid by the user.
                      </li>
                      <li>
                        If the user requests for cancellation of his/her subscription to the Health Plan Services during
                        the Free Look Period and if the user has already availed certain Health Plan Services which are
                        mentioned in the user's Plan Details during the Free Look Period, Nushift will refund the Health
                        Plan Subscription amount after deducting the consultation fees (as mentioned by the respective
                        doctor on the Website) for the consultation(s) availed by the user as part of the Health Plan
                        Services.
                      </li>
                      <li>
                        The user will not be entitled to any refund of the Health Plan Subscription amount if the
                        cancellation request by the user is after 15 (Fifteen) days from the date of user's subscription
                        to the Health Plan Services.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span class="item-no">5.</span> All refunds of the Health Plan Subscription amount to the user are
                    subject to Nushift’s internal verification process.
                  </li>
                  <li>
                    <span class="item-no">6.</span> Subject to Clause 3.4.7 (3) above, the refund of the Health Plan
                    Subscription amount will made to the user within fifteen (15) business days of such cancellation
                    request.
                  </li>
                </ul>
              </li>
              <li>
                <span class="item-no">14.3.8</span> As a part of Health Plan Service, You are entitled to certain
                discounts, offers and benefits. It is clarified that none of these benefits under Health Plan Service
                can be redeemed for cash.
              </li>
              <li>
                <span class="item-no">14.3.9</span> Fair Usage Policy for Nushift Health Plan.
                <div>The following fair usage policy shall be applicable to every Plan:</div>
                <ul class="lower-rom-list">
                  <li>
                    Users are allowed to utilise a maximum of 5 (Five) free online consultations per day (i.e. 5 (Five)
                    online consultations per day collectively for all users, including covered members). Upon completion
                    of the 5 (Five) free consultations per day, users may choose to avail additional consultations by
                    paying the prescribed consultation fee to the doctor.
                  </li>
                  <li>
                    Users are allowed to utilize a maximum of 15 (Fifteen) free online consultations per month (i.e. 15
                    (Fifteen) free online consultations per month collectively for all users, including covered
                    members). Upon completion of the 15 (Fifteen) free consultations per month, users may choose to
                    avail additional consultations by paying the prescribed consultation fee to the doctor.
                  </li>
                  <li>
                    Each user of a Plan can have 1 (one) active doctor consultation at any given time. An online
                    consultation is considered to be active for 60 (Sixty) minutes from the actual time of commencement
                    of the said consultation and upon completion of 60 (Sixty) minutes, users may commence a new online
                    consultation.
                  </li>
                  <li>
                    The aforementioned fair usage policy is subject to change by Nushift at its sole discretion based on
                    future analysis and actual usage.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <p></p>
          <li>
            <span class="item-no">14.4</span> APPLICABILITY OF THE STANDARD POLICIES
            <p>
              The Nushift Health Plan Terms herein are required to be read in conjunction and addition with the
              applicable user terms under the Standard Policies. In case of any inconsistency between the Nushift Health
              Plan Terms and the Standard Policies, the Terms will prevail to the extent of the inconsistency.
            </p>
          </li>
          <li>
            <span class="item-no">14.5</span> REIMBURSEMENT UNDER Nushift PLUS PLAN – TERMS &amp; CONDITIONS
            <p>
              Users who have subscribed to the Nushift Plus Plan which is available on the
              Website are entitled to receive reimbursements, on the consultation fee mentioned by
              the doctor on the Website with respect to each consultation (“Consultation
              Fee”) subject to the following terms and conditions:
            </p>
            <ul class="custom-list">
              <li>
                <span class="item-no">1</span> Only those Users who have subscribed to a Nushift Plus Plan through the
                Website are entitled to receive reimbursement on the Consultation Fee.
              </li>
              <li>
                <span class="item-no">2</span>The maximum reimbursement which can be availed by a User per consultation
                is Rs. 500/- (Rupees Five Hundred only).
              </li>
              <li>
                <span class="item-no">3</span>Reimbursement is available only on the Consultation Fees, and not on the
                fees paid by the User for each Consultation. For example, if the doctor has raised an invoice for Rs.
                500/- and the Consultation Fee as mentioned under the profile of the doctor on the Website is Rs. 400/-,
                the User who has consulted the said doctor will be entitled to a cash back of only Rs. 400/- and not Rs.
                500/-.
              </li>
              <li>
                <span class="item-no">4</span>For the purpose of availing the reimbursement, Users shall send a legible
                picture/photo of the invoice/bill raised by the respective clinic/hospital at which the said User has
                booked an appointment to pluscashback&#64;Nushift.com.
              </li>
              <li>
                <span class="item-no">5</span>The E-mail to be sent by the User (as per Clause 5 above) shall also
                include the following details:
                <ul class="lower-rom-list">
                  <li>Picture/photo of the invoice/bill</li>
                  <li>Bank details of the User</li>
                </ul>
              </li>
              <li>
                <span class="item-no">6</span> The picture/photo mentioned under Clause 5 shall contain the following
                details for the Users to avail the reimbursement:
                <ul class="lower-rom-list">
                  <li>Name of the User (name should be as mentioned in the Nushift Plus membership before the booking
                    the appointment)</li>
                  <li>Name of clinic/hospital</li>
                  <li>Name of doctor</li>
                  <li>Consultation Fee/ fees paid.</li>
                </ul>
              </li>
              <li>
                <span class="item-no">7</span> Nushift team will verify the amount mentioned in the invoice with the
                Consultation Fee mentioned by the doctor on the Website
              </li>
              <li>
                <span class="item-no">8</span> Any reimbursement claim made by a User after the expiry of his/her
                subscription to the Nushift Plus Plan is not valid.
              </li>
              <li>
                <span class="item-no">9</span> A User cannot carry forward his/her entitlement to receive reimbursement
                while renewing his/her Nushift Plus Plan subscription.
              </li>
              <li>
                <span class="item-no">10</span> Plus is not an insurance product.
              </li>
              <li>
                <span class="item-no">11</span>Any reimbursement claim made by a User 30 days after the date of
                appointment is not valid.
              </li>
            </ul>
          </li>

        </ul>
      </li>
    </ul>
  </div>
</mat-dialog-content>
