<div class="preferences-container">
  <h2 class="title">Visibility of your profile & network</h2>
  <div class="preference-item">
    <span>Profile viewing options</span>
    <span class="dropdown">Your name and headline ▾</span>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Page visit visibility</span>
    <div class="float-end">

      <mat-slide-toggle class="custom-icon-toggle" [(ngModel)]="settings1.pageVisitVisibility"></mat-slide-toggle>
<!-- <img src="../../../../assets/privacy-settings/on.png" alt=""> -->
      <!-- <mat-slide-toggle class="custom-switch" [(ngModel)]="settings1.pageVisitVisibility"></mat-slide-toggle> -->
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Edit your public profile</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Who can see or download your email address</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Who can see your connections</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Who can see members you follow</span>
    <span class="dropdown">Anyone on Nushift ▾</span>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Who can see your last name</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Representing your profession and interests</span>
    <div class="float-end">
      <mat-slide-toggle [(ngModel)]="settings1.professionVisibility"></mat-slide-toggle>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Profile discovery using email address</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Profile discovery using phone number</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item clickable">
    <span>Blocking</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
</div>




<div class="preferences-container">
  <h2 class="title">Visibility of your LinkedIn Activity</h2>
  <div class="preference-item">
    <span>Manage Active Status</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Share Profession changes, and work anniversaries from profile</span>
    <div class="float-end">
      <mat-slide-toggle class="value" [(ngModel)]="settings.shareChanges"></mat-slide-toggle>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Notify connections when you are in the views</span>
    <div class="float-end">
      <mat-slide-toggle [(ngModel)]="settings.notifyViews"></mat-slide-toggle>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Mentioned by others</span>
    <div class="float-end">
      <mat-slide-toggle [(ngModel)]="settings.mentions"></mat-slide-toggle>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="preference-item">
    <span>Followers</span>
    <mat-icon>chevron_right</mat-icon>
  </div>

  <mat-divider></mat-divider>
</div>