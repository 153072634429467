<div class="right-sidebar">
  <!-- Trending Podcast Section -->
  <div class="container">
    <div class="header">
      Trending Podcasts
      <span>
        <img src="../../../../assets/new_feed/tas.png" alt="Trending Icon" />
      </span>
    </div>
    <div class="content1">
      <div class="podcast-card">
        <img [src]="podcastImage" alt="Podcast Image" />
        <div class="podcast-title">{{ podcastName }}</div>
        <div class="stats">
          <span>
            <img src="../../../../assets/new_feed/pod_1.png" alt="Episodes Icon" />
            {{ pc_episode_count }}
          </span>
          <span>
            <img src="../../../../assets/new_feed/pod_2.png" alt="Duration Icon" />
            {{ podcastEpisodeDuration }}
          </span>
          <span>
            <img src="../../../../assets/new_feed/pod_3.png" alt="Views Icon" />
            {{ pc_views }}
          </span>
        </div>
        <a class="view-all" href="#">View all</a>
      </div>
    </div>
  </div>

  <!-- Upcoming Events Section -->
  <!-- <div class="container mt-2">
    <div class="header">
      Upcoming Events
      <span>
        <img src="../../../../assets/new_feed/calendar.png" alt="Calendar Icon" />
      </span>
    </div>
    <div class="event-date">{{ date }}</div>
    <div class="event-item" *ngFor="let event of trending_events">
      <img [src]="event.event_picture" alt="Event Image" class="event-img" />
      <div class="event-info">
        <p class="event-title">{{ event.event_name }}</p>
        <div class="event-actions">
          <button class="btn-view">
            {{ event.is_registered === 'no' ? 'View' : 'Join' }}
          </button>
          <span
            class="event-status"
            [class.online]="event.category === 'ONLI'"
            [class.offline]="event.category !== 'ONLI'"
          >
            ● {{ event.category === 'ONLI' ? 'Online' : 'Offline' }}
          </span>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Trending on Feed Section -->
  <div class="container mt-2">
    <div class="header">Trending on Feed</div>
    <div class="content1 feed-links">
      <a *ngFor="let tag of trending_tags" (click)="onSearch(tag.hashtag)">
        {{ tag.hashtag }}
      </a>
    </div>
  </div>
</div>
