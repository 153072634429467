import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../dashboard.service';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ChartConfiguration, ChartDataset, ChartTypeRegistry } from 'chart.js';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, TitleCasePipe } from '@angular/common';

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
  standalone: true,
  imports: [BaseChartDirective, MatCardModule, TitleCasePipe, CommonModule],
})
export class MainDashboardComponent implements OnInit {
  applicantsList: any;  // Referrals Count
  referralSentCount: string = '';
  referralRecievedCount: string = '';
  referralPendingCount: string = '';
  // Followers Data
  totalFollowersCount: string = '';
  followersData: any;
  totalFeedCount: string = '';
  feedLikesData: any;
  feedSharesData: any;
  feedCommentsData: any;
  feedbackData: any;

  // Connection
  connectionsData: any;
  connectionsValues: any;
  followersChartLabels: string[] = [];


  //chartsmodule //
  public feedChartLegend = true;
  public feedLineChartType = 'line';
  public followersChartLegend = true;
  public followersLineChartType = 'line';
  feedChartLabels: string[] = [];
  public connectionsChartLegend = true;
  public connectionsLineChartType = 'bar';
  public feedbackChartLegend = true;
  public feedbackLineChartType = 'line';
  isMobile: boolean;
  feedbackChartLabels: string[] = [];

  connectionsChartLabels: string[] = ['Doctors', 'Diagnostics', 'Hospitals'];
  buttons = [
    {
      id: 'total',
      selected: false,
      color: '#E32020',
      defaultImage: '../../../../assets/dashboard_images/totalcount.png',
      selectedImage: '../../../../assets/dashboard_images/red_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/red_outer.png'

    },
    {
      id: 'share',
      selected: false,
      color: '#FBDF4A',
      defaultImage: '../../../../assets/dashboard_images/share.png',
      selectedImage: '../../../../assets/dashboard_images/yellow_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/yellow_outer.png'

    },
    {
      id: 'comment',
      selected: false,
      color: '#71EE76',
      defaultImage: '../../../../assets/dashboard_images/comments.png',
      selectedImage: '../../../../assets/dashboard_images/green_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/green_outer.png'

    },
    {
      id: 'like',
      selected: false,
      color: '#3F8CD2',
      defaultImage: '../../../../assets/dashboard_images/like.png',
      selectedImage: '../../../../assets/dashboard_images/blue_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/blue_outer.png'

    }
  ];

  feedbackButtons = [
    {
      id: 'yet_to_receive',
      selected: true,
      color: '#E32020',
      defaultImage: '../../../../assets/dashboard_images/red_fill.png',
      selectedImage: '../../../../assets/dashboard_images/red_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/red_outer.png'
    },
    {
      id: 'sent',
      selected: false,
      color: '#FBDF4A',
      defaultImage: '../../../../assets/dashboard_images/yellow_outer.png',
      selectedImage: '../../../../assets/dashboard_images/yellow_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/yellow_outer.png'
    },
    {
      id: 'received',
      selected: false,
      color: '#71EE76',
      defaultImage: '../../../../assets/dashboard_images/green_outer.png',
      selectedImage: '../../../../assets/dashboard_images/green_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/green_outer.png'
    },
    {
      id: 'pending',
      selected: false,
      color: '#3F8CD2',
      defaultImage: '../../../../assets/dashboard_images/blue_outer.png',
      selectedImage: '../../../../assets/dashboard_images/blue_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/blue_outer.png'
    }
  ];


  public followersChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Series A',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'  // Ensures the line is not filled
      }
    ],
    labels: []

  }
  public followersChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
  };






  public feedChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [], // Initialize as empty number array
        label: 'Followers',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      }
    ],
    labels: []
  };
  public feedChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      }
    },
    elements: {
      line: {
        borderWidth: 2 // Making the line slightly thicker
      },
      point: {
        radius: 3 // Making points slightly larger
      }
    }
  };









  public connectionsChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [0, 0, 0],
        label: 'Connections',
        fill: true,
        barPercentage: 0.1, // Adjust the width of each bar
        tension: 0.5, // Adjust the space between bars
        // Ensures the line is not filled
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      },


    ],
    labels: ['Doctors', 'Diagnostics', 'Hospitals']
  }
  public connectionsChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: 'black'
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public connectionsChartColors: Array<any> = [
    {
      backgroundColor: this.getConnectionColors(),
      // borderColor: 'blue',
      borderWidth: 1,
    }
  ];



  public feedbackChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Referrals Feedback',
        fill: false  // Ensures the line is not filled
      }
    ],
    labels: []
  }
  public feedbackChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
  };

  public feedbackChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: '#002469',
      borderWidth: 2,
    }
  ];
name: any;




  getConnectionColors(): string[] {
    // Define your custom colors for each month
    return [
      '#3F8CD2',  // January
      '#FBDF4A',   // February
      '#71EE76',   // March
    ];
  }





  ngOnInit(): void {
    this.authService.isSpecialComponentActive = false;
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe((state: BreakpointState) => {


        this.activatedRoute.queryParams.subscribe((params: any) => {
          if (params.cf.toLowerCase() === 'd') {
            // Goes inside this condition if the screen path contains dashboard as 'n' for 'cf' value
            this.authService.isLoggedIn.next({
              showNavbar: this.isMobile ? false : true,
              showSideNav: false,
              cf: params.cf.toLowerCase(),
            });
          }
        });

      });
    console.log('Buttons:', this.buttons);



    this.getNewAnalytics('year');

  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private apiEndPointService: ApiEndpointsService,
    private _snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
  }


  feedAction(buttonId: string) {
    // Deselect all buttons
    this.buttons.forEach(button => button.selected = false);

    // Select the clicked button
    const button = this.buttons.find(button => button.id === buttonId);

    if (button) {
      button.selected = true;

      this.feedChartLabels = this.applicantsList['axis'];

      // Create the dataset with proper typing and the button's color
      const dataset: ChartDataset<'line'> = {
        data: this.applicantsList['engagement'][buttonId] || [],
        label: buttonId.toLocaleUpperCase(),
        fill: false,
        tension: 0.5,
        borderColor: button.color, // Use the button's color for the line
        backgroundColor: 'rgba(255,255,255,0.1)', // Nearly transparent background
        borderWidth: 2,
        pointBackgroundColor: button.color, // Match points to line color
        pointBorderColor: button.color
      };

      // Update chart data
      this.feedChartData = {
        labels: this.feedChartLabels,
        datasets: [dataset]
      };
    }
  }


  referralfeedbackAction(buttonId: string) {
    // Deselect all buttons
    this.feedbackButtons.forEach(button => button.selected = false);

    // Select the clicked button
    const button = this.feedbackButtons.find(button => button.id === buttonId);
    if (button) {
      button.selected = true;
    }

    this.feedbackChartLabels = this.applicantsList['axis'];

    // Ensure data is properly typed as numbers
    const feedbackData: number[] = this.applicantsList['feedback'][buttonId] || [];

    const dataset: ChartDataset<keyof ChartTypeRegistry> = {
      data: feedbackData,
      label: buttonId.toLocaleUpperCase(),
      fill: false,
      borderColor: button.color,
      pointBackgroundColor: button.color, // Match points to line color
      pointBorderColor: button.color,
      tension: 0.5,
      borderWidth: 2,
    };

    this.feedbackChartData = {
      labels: this.feedbackChartLabels,
      datasets: [dataset]
    };

    this.feedbackChartColors = [
      {
        backgroundColor: 'rgba(105, 0, 132, .2)',
        borderColor: button?.color,
        borderWidth: 2,
      }
    ];
  }


  async getNewAnalytics(filterType: string) {
    let payload = {
      uid: this.authService.userValue.uid,
      filter_by: filterType,
    };

    try {
      this.spinner.show('yyyyyyyyyyyyyyy');
      const res = await (await this.dashboardService.getNewAnalytics(payload)).toPromise();

      if (res?.status) {
        const decodedData = await this.apiEndPointService.decodeAesToken(
          this.authService.userValue.uid,
          res.data
        );

        const parsedData = JSON.parse(decodedData.replace(/'/g, '"').replace(/: None/g, ': "None"'));
        this.applicantsList = parsedData;

        // Update all the properties safely
        this.referralSentCount = parsedData.referrals?.sent ?? '0';
        this.referralPendingCount = parsedData.referrals?.pending ?? '0';
        this.referralRecievedCount = parsedData.referrals?.received ?? '0';
        this.totalFollowersCount = parsedData.followers?.total ?? '0';
        this.followersData = parsedData.followers?.followers_data ?? [];
        this.followersChartLabels = parsedData.axis ?? [];
        this.connectionsData = parsedData.connections ?? {};
        // console.log(this.applicantsList['axis'],'followersData');
        this.followersChartData = {
          labels: this.followersChartLabels,
          datasets: [
            {
              data: this.followersData,
              label: 'Followers',
              fill: false,
              tension: 0.5,
              borderColor: 'black',
              backgroundColor: 'rgba(255,0,0,0.3)'
            }
          ]
        };
        // Update connections chart data safely
        if (this.connectionsData) {
          this.connectionsChartLabels = Object.keys(this.connectionsData);
          this.connectionsValues = Object.values(this.connectionsData);
          this.connectionsChartData = {
            labels: this.connectionsChartLabels,
            datasets: [{
              data: this.connectionsValues,
              label: 'Connections',
              fill: true,
              barPercentage: 0.1,
              categoryPercentage: 0.5
            }]
          };
        }


        this.feedAction('total');
        this.referralfeedbackAction('yet_to_receive');

        this.openSnackBar(res.message, '');
      }
    } catch (error) {
      console.error('Error fetching analytics:', error);
      this.openSnackBar('Error fetching analytics data', '');
    } finally {
      this.spinner.hide();
    }
  }
  getConnectionPercentage(index: number): string {
    try {
      const value = this.connectionsChartData?.datasets?.[0]?.data?.[index];
      return value !== undefined ? `${value}%` : '0%';
    } catch {
      return '0%';
    }
  }

  openSnackBar(message: string, action: string): void {
    // Method to show alert when any api calls
    this._snackBar.open(message, action, { duration: 3000 });
  }

}
