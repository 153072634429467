import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FAQModel } from 'src/app/models/faq-model';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-landing-screen',
  templateUrl: './landing-screen.component.html',
  styleUrls: ['./landing-screen.component.scss'],
})
export class LandingScreenComponent implements OnInit {
  isLoginClicked: boolean = false;
  constructor(
    private nav: NavbarService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  login(type: string) {
    if (type === 'login') {
      this.isLoginClicked = true;
    }
  }

  loginClosed(event: boolean) {
    this.isLoginClicked = event;
  }
  faqList: FAQModel[] = [
    {
      question: 'Who can be a part of Nushift Connect?',
      answer:
        'Currently only doctors holding a valid registration number can register on the platform. However the services will soon be extended to Patients, Hospitals, Diagnostics, Pharmacies & other key stakeholders within the healthcare community. ',
    },
    {
      question: 'How can I be a part of the Nushift Connect app?',
      answer:
        'Nushift Connect is an invite only platform, if you know someone who is an existing user of the app you can ask them for an exclusive invitation. However you can also register your details with us to get regular updates on membership.',
    },
    {
      question: 'What can I post on the feed ?',
      answer:
        'You can post interesting articles, latest updates, facts, images, informative videos and case studies on the feed.',
    },
    {
      question: 'Why should I conduct an event through Nushift?',
      answer:
        'Nushift Technologies offers the convenience to conduct virtual events like CMEs, Health talks, online conferences, workshops and seminars online to help your voice reach the relevant stakeholders within the healthcare community.',
    },
    {
      question: 'Is Nushift Technologies a free networking platform ?',
      answer:
        'Nushift Technology’s networking platform is modelled on the principle of a freemium pricing which means some features of the app are free. However there are significant advantages that a premium subscription offers. Get in touch with our team or visit the product page to know more.',
    },
    {
      question: 'How can I connect with other doctors on the app?',
      answer:
        'In order to connect with a doctor through the Nushift Connect app, search for the doctor by name, specialisation and location.In case you don’t happen to find a doctor known to you on the platform, then please feel free to invite them from your personal contacts.',
    },
    {
      question:
        'How can I make my events reach out to the right target audience?',
      answer:
        'While creating your event on the app, kindly filter the options to reach out to the right target audience for your events. Based on the filter results your event will get recommended to the potential audience.',
    },
    {
      question: 'What features can be availed while conducting events?',
      answer:
        'If you are conducting an event through Nushift Connect then you can avail the following- a registration platform, viewer data management, Q&A for participants, quick notes, and use the chat feature during the event.',
    },
    {
      question: 'How is Nushift Connect unifying the medical ecosystem?',
      answer:
        'Through Nushift Connect doctors can find a common engagement platform for sharing their thoughts, views, and collaboratively make the best use of their expertise in individual areas. An ongoing strain of communication will help doctors better their understanding of critical cases and work towards their cost and time effective resolution.',
    },
    {
      question: 'Is Nushift Connect a social media site?',
      answer:
        'Nushift Connect is essentially a networking and engagement platform for doctors. It’s a closed community where people from within the medical fraternity work towards making healthcare services more accessible and patient care more proactive. It helps doctors stay connected to their wider community on the go.',
    },
  ];

  ngOnInit() {
    this.authService.isSpecialComponentActive = false;
    this.authService.routerChanges();
    this.route.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        this.isLoginClicked = true;
      }
    });
  }


  toScroll(id: string) {
    // install commands changed
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
    // document.getElementsByClassName(clickedTag)[0].classList.add(clickedTag);
  }
}