


<div class="container-fluid w-100 landing-page-v2">
  <ng-container *ngIf="isLoginClicked">
    <app-login class="w-100 d-block" [closeLogin]="isLoginClicked"
      (loginClosed)="loginClosed($event)"></app-login>
  </ng-container>


  <ng-container *ngIf="!isLoginClicked">
    <div class="page w-100">
      <!-- Menu Bar Section -->
      <div class="p-0 m-0 row align-items-center menu-bar bg-light w-100">
        <div class="nushift-logo col-md-2" (click)="toScroll('main')"></div>
        <a class="nav-link text-center col-md-2" routerLinkActive="active" (click)="toScroll('how')">
          <span class="navbar-title-size">How?</span>
        </a>
        <a class="nav-link text-center col-md-2" routerLinkActive="active" (click)="toScroll('services')">
          <span class="navbar-title-size">Services</span>
        </a>
        <a class="nav-link text-center col-md-2" LinkActive="active" (click)="toScroll('faq')">
          <span class="navbar-title-size">FAQ's</span>
        </a>
        <a class="nav-link text-center col-md-2" routerLinkActive="active" (click)="toScroll('contact')">
          <span class="navbar-title-size"> Contact us</span>
        </a>
        <button mat-button class="btn align-self-center col-md-2" id="login" (click)="login('login')"> LOGIN </button>
      </div>

      <!-- Main Section -->
      <div class="row mt-5" id="main">
        <div class="col-md-8 d-flex flex-column align-items-center justify-content-around">
          <h1 class="text-dark font-weight-bold">
            <strong>Connect with the biggest network of doctors across the
              globe-Seamlessly, Instantly and conveniently.</strong>
          </h1>
          <p class="">
            <a href="https://www.nushift.in/" target="_blank" class="nushift-a-tag">Nushift Technologies</a>
            <span> is India’s largest networking and engagement platform for the
              medical community that works towards unifying the entire medical
              ecosystem by fostering better connection, communication and
              collaboration.</span>
          </p>
          <div class="nushift-connect-download-buttons d-flex align-items-center flex-wrap justify-content-between align-content-center">
            <button mat-button class="btn text-white font-weight-bold get-invited" [routerLink]="['/get-invited']">Get Invited</button>
            <a href="https://play.google.com/store/apps/details?id=com.nushift.doctors" target="_blank">
              <img src="../assets/play_store_icon.png" class="app-icon-first download-android" height="70" width="200"
                matTooltip="Download Nushift Connect App" matTooltipPosition="above" />
            </a>
            <a href="https://apps.apple.com/in/app/nushift-connect/id1533287593" target="_blank">
              <img src="../assets/app_store_icon.png" class="ml-md-2 download-ios" matTooltip="Download Nushift Connect App"
                matTooltipPosition="above" height="48" width="175" />
            </a>
          </div>
        </div>
        <div class="doctor-standing-img col-md-4"></div>
      </div>

      <!-- How Does it Work? Section -->
      <div class="row how-does-it-work mx-3 mb-4">
        <h1 class="text-center mt-4 text-black font-weight-bold" id="how"><strong>How Does it work?</strong></h1>
        <div class="col-md-8 mt-3 pt-4">
          <div class="d-flex flex-column title-texts pb-3">
            <div class="d-flex align-items-start m-2 justify-content-around">
              <img class="mt-2" src="../assets/img/group-people.svg" height="40px" width="35px" />
              <div class="d-flex flex-column mx-2">
                <h3 class="point-title m-0"><strong>Connect with your fraternity</strong></h3>
                <h4 class="m-0">
                  Build interdisciplinary connections within the fraternity by
                  networking and engaging with doctors across the city, globe
                  and specialisation.
                </h4>
              </div>
            </div>
            <div class="d-flex align-items-start m-2 justify-content-around">
              <img class="" src="../assets/img/two-people.svg" height="40" width="40" />
              <div class="d-flex flex-column mx-2">
                <h3 class="point-title m-0"><strong>Cross-Refer Cases</strong></h3>
                <h4 class="m-0">
                  Collaboratively work on cases to get better insights and
                  perspective, and work towards bringing out faster and
                  improved treatment outcomes.
                </h4>
              </div>
            </div>
            <div class="d-flex align-items-start m-2 justify-content-around">
              <img class="" src="../assets/img/medical-book.svg" height="40px" width="45px" />
              <div class="d-flex flex-column m-0">
                <h3 class="point-title m-0"><strong>Conduct CMEs</strong></h3>
                <h4 class="m-0">
                  Educate the fraternity with the latest medical updates,
                  share journals, case studies and articles to empower the
                  community. Conduct online events, conferences and workshops
                  to encourage a free-flow of information and knowledge within
                  the fraternity.
                </h4>
              </div>
            </div>
            <div class="d-flex align-items-start m-2 justify-content-around">
              <img class="" src="../assets/img/magnet-people.svg" height="45" width="45" />
              <div class="d-flex flex-column m-0">
                <h3 class="point-title m-0"><strong>Engage, Educate, Enable and Empower</strong></h3>
                <h4 class="m-0">
                  Engage proactively to grow your network wider by making the
                  right connections with the right people. Educate, enable and
                  empower yourself to adapt to the ever-changing dynamics of
                  the medical landscape, and to progressively stay updated
                  with the new advancements in the field of health sciences.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="networks-img col-md-4"></div>
      </div>

      <!-- Services Section -->
      <div class="pb-2" id="services">
        <div class="d-flex flex-column align-items-center">
          <h1 class="text-center mt-4 text-black font-weight-bold"><strong>Our Services</strong></h1>
          <h4 class="mt-1">
            Connect, collaborate and cohesively work with a wider network of
            doctors within the medical fraternity.
          </h4>
        </div>
        <div class="row mt-2 mx-5 align-items-center border-bottom">
          <div class="hands-img col-md-4"></div>
          <div class="col-md-8 pl-3">
            <h2 style="font-weight: 600 !important;">Connect Conveniently</h2>
            <h4>
              We bring together the entire community of doctors under one
              platform and look forward to helping the medical fraternity by
              working closely towards making healthcare processes more
              goal-oriented, agile and proactive.
            </h4>
          </div>
        </div>
        <!-- <hr /> -->
        <div class="row mt-3 mx-3 px-4 align-items-center border-bottom">
          <div class="col-12 col-md-8 mt-5 pt-3">
            <h2 style="font-weight: 600 !important;">Communicate Cohesively</h2>
            <h4>
              Communication is the cornerstone for networking, a good and
              healthy exchange between doctors helps them to better both their
              humanistic and technical side. At Nushift Connect we work
            </h4>
          </div>
          <div class="hands-img col-md-4 pl-3"
            [ngStyle]="{ 'background-image': 'url(../assets/img/city.svg)', 'height': '50vh'}">
          </div>
        </div>
        <!-- <hr /> -->
        <div class="row mt-3 mx-3 px-4 align-items-center">
          <div class="hands-img col-md-4"
            [ngStyle]="{ 'background-image': 'url(../assets/img/gears.svg)', 'height': '50vh'}">
          </div>
          <div class="col-md-8 mt-5 pt-5 pl-3">
            <h2 style="font-weight: 600 !important;">Collaborate Continually</h2>
            <h4>
              Come together as a team to opine on matters of joint concern and
              decision making. We promote ongoing col- laboration within the
              fraternity to work towards a more comprehensive, cost-efficient
              and population based ap- proach towards patient care. A
              collaborative approach leads to better outcomes in the direction
              of health pro- motion and disease prevention.
            </h4>
          </div>
        </div>

        <!-- Contact Us Section -->
        <img src="../assets/img/curve-bg.svg" height="30%" width="100%" id="contact" />
        <div style="background-color: #133186" id="contactUs">
          <div style="background-color: #133186" class="text-white p-4">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="nushift-logo-white"></div>
                <h5 class="text-light ml-2" style="font-size: 13px;
                line-height: 18px;">
                  Nushift Connect has analyzed the present trend of patients
                  choosing a particular healthcare institution over another.
                </h5>
                <div class="communication-icons d-flex align-items-center">
                  <a href="https://www.facebook.com/Nushiftconnect" target="_blank">
                    <img class="img-fluid" src="../assets/img/media1.svg" alt="Nushift"
                      style="width: 25px; height: 25px;" />
                  </a>
                  <a href="https://twitter.com/nushiftconnect" target="_blank">
                    <img class="img-fluid" src="../assets/img/media2.svg" alt="Nushift"
                      style="width: 25px; height: 25px;" />
                  </a>
                  <a href="https://www.linkedin.com/company/nushiftconnect/" target="_blank">
                    <img class="img-fluid" src="../assets/img/media3.svg" alt="Nushift"
                      style="width: 25px; height: 25px;" />
                  </a>
                  <a href="https://www.instagram.com/nushiftconnect22/" target="_blank">
                    <img class="img-fluid" src="../assets/img/media4.svg" alt="../../../assets/img/updated-nushift-logo.png"
                      style="width: 25px; height: 25px;" />
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-4 mt-5" style="position: relative;left: 7rem;">
                <div class="d-flex flex-column justify-content-center">
                  <h5 style="margin: 0px;font-size: 18px;font-weight: 600 !important;" class="ml-2">
                    <span class="line-left mr-2"></span> Company
                  </h5>
                  <h5 style="margin: 0px" class="mt-2 scroll-to" (click)="toScroll('how')">
                    <span style="font-size: 11px;">
                      <img class="img-fluid" src="../assets/img/right-arrow.svg" height="24px" width="24px" />
                    </span>How Does it Work
                  </h5>
                  <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('services')">
                    <span style="font-size: 11px;">
                      <img class="img-fluid" src="../assets/img/right-arrow.svg" height="24px" width="24px" /></span>
                    Services
                  </h5>
                  <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('faq')">
                    <span style="font-size: 11px;"><img class="img-fluid" src="../assets/img/right-arrow.svg"
                        height="24px" width="24px" /></span>FAQ’s
                  </h5>
                  <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('contact')">
                    <span style="font-size: 11px;"><img class="img-fluid" src="../assets/img/right-arrow.svg"
                        height="24px" width="24px" /></span>Contact Us
                  </h5>
                  <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('download')">
                    <span style="font-size: 11px;"><img class="img-fluid" src="../assets/img/right-arrow.svg"
                        height="24px" width="24px" /></span>Download App
                  </h5>
                </div>
              </div>
              <div class="col-12 col-md-4 mt-2" style="position: relative;left: 2rem;">
                <div class="d-flex flex-column p-3 contact-us-images">
                  <div class="row">
                    <h5 class="ml-2 align-self-start" style="font-size: 18px;font-weight: 600 !important;">
                      <span class="line-left mr-2"></span> Get in touch
                    </h5>
                  </div>
                  <div class="row">
                    <span class="col-md-1"><img class="img-fluid" src="../assets/img/location.svg" /></span>
                    <div class="col-md-11 d-flex flex-column">
                      <h5 class="ml-1" style="font-size: 13px;">
                        Nushift Technologies Pvt. Ltd. <br />
                        T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar,
                        Madhapur,<br />
                        Hyderabad, Telangana 500081<br />
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <span class="col-md-1"><img class="img-fluid" src="../assets/img/phone.svg" /></span>
                    <div class="col-md-11 d-flex flex-column">
                      <h5 class="ml-1" style="font-size: 13px;">+91 90304 13379</h5>
                    </div>
                  </div>
                  <div class="row">
                    <span class="col-md-1"><img class="img-fluid" src="../assets/img/mail.svg" /></span>
                    <div class="col-md-11 d-flex flex-column">
                      <h5 class="ml-1" style="font-size: 13px;">info&#64;nushift.in</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer style="background-color: #133186">
            <div class="h-100 d-flex justify-content-center align-items-center">
              <div class="text-white font-size-2">
                © 2024 Nushift Connect | All rights reserved
              </div>
            </div>
          </footer>
        </div>
      </div>

      <!-- FAQ's Section -->
      <div class="faq-bg py-3" id="faq">
        <div class="d-flex justify-content-between align-items-center mx-3">
          <h1 class="mx-3 font-weight-bold text-light"><strong>FAQ's</strong></h1>
          <img class="mx-3" src="../assets/img/faq.svg" height="64px" width="64px" />
        </div>
        <ul class="faq-ul">
          <div *ngFor="let faq of faqList" class="my-1 text-light">
            <h3 style="margin: 0; font-size: 16px !important;font-weight: 600 !important;">
              <u>Q. {{ faq.question }}</u>
            </h3>
            <h4 style="margin: 0; font-size: 14px !important">A. {{ faq.answer }}</h4>
          </div>
        </ul>
      </div>

      <!-- Download Section -->
      <div class="row px-5 align-items-center" id="download">
        <div class="nushift-mobile-logo col-md-6"></div>
        <div class="col-md-6 d-flex flex-column align-items-center justify-content-between">
          <div class="download-text font-weight-bold">
            <span class="download">Download the </span> <span><strong> NUSHIFT CONNECT </strong></span> <span class="download">App</span>
          </div>
          <div class="mt-3 d-flex align-items-center justify-content-between">
            <a class="download-android" href="https://play.google.com/store/apps/details?id=com.nushift.doctors" target="_blank"
            matTooltip="Download Nushift Connect App" matTooltipPosition="below">
            </a>
            <a class="download-ios" href="https://apps.apple.com/in/app/nushift-connect/id1533287593" target="_blank">
              <img src="../assets/app_store_icon.png" class="ml-md-2" height="72dp" width="162dp" matTooltip="Download Nushift Connect App" matTooltipPosition="below" />
            </a>
          </div>
        </div>
      </div>

      <!-- Contact Us Section -->
      <img src="../assets/img/curve-bg.svg" height="30%" width="100%" id="contact" />
      <div style="background-color: #133186" id="contactUs">
        <div style="background-color: #133186" class="text-white p-4">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="nushift-logo-white"></div>
              <h5 class="text-light ml-2" style="font-size: 13px;
              line-height: 18px;">
                Nushift Connect has analyzed the present trend of patients
                choosing a particular healthcare institution over another.
              </h5>
              <div class="communication-icons d-flex align-items-center">
                <a href="https://www.facebook.com/Nushiftconnect" target="_blank">
                  <img class="img-fluid" src="../assets/img/media1.svg" alt="Nushift"
                    style="width: 25px; height: 25px;" />
                </a>
                <a href="https://twitter.com/nushiftconnect" target="_blank">
                  <img class="img-fluid" src="../assets/img/media2.svg" alt="Nushift"
                    style="width: 25px; height: 25px;" />
                </a>
                <a href="https://www.linkedin.com/company/nushiftconnect/" target="_blank">
                  <img class="img-fluid" src="../assets/img/media3.svg" alt="Nushift"
                    style="width: 25px; height: 25px;" />
                </a>
                <a href="https://www.instagram.com/nushiftconnect22/" target="_blank">
                  <img class="img-fluid" src="../assets/img/media4.svg" alt="../../../assets/img/updated-nushift-logo.png"
                    style="width: 25px; height: 25px;" />
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-5" style="position: relative;left: 7rem;">
              <div class="d-flex flex-column justify-content-center">
                <h5 style="margin: 0px;font-size: 18px;font-weight: 600 !important;" class="ml-2">
                  <span class="line-left mr-2"></span> Company
                </h5>
                <h5 style="margin: 0px" class="mt-2 scroll-to" (click)="toScroll('how')">
                  <span style="font-size: 11px;">
                    <img class="img-fluid" src="../assets/img/right-arrow.svg" height="24px" width="24px" />
                  </span>How Does it Work
                </h5>
                <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('services')">
                  <span style="font-size: 11px;">
                    <img class="img-fluid" src="../assets/img/right-arrow.svg" height="24px" width="24px" /></span>
                  Services
                </h5>
                <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('faq')">
                  <span style="font-size: 11px;"><img class="img-fluid" src="../assets/img/right-arrow.svg"
                      height="24px" width="24px" /></span>FAQ’s
                </h5>
                <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('contact')">
                  <span style="font-size: 11px;"><img class="img-fluid" src="../assets/img/right-arrow.svg"
                      height="24px" width="24px" /></span>Contact Us
                </h5>
                <h5 style="margin: 0px" class="scroll-to" (click)="toScroll('download')">
                  <span style="font-size: 11px;"><img class="img-fluid" src="../assets/img/right-arrow.svg"
                      height="24px" width="24px" /></span>Download App
                </h5>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-2" style="position: relative;left: 2rem;">
              <div class="d-flex flex-column p-3 contact-us-images">
                <div class="row">
                  <h5 class="ml-2 align-self-start" style="font-size: 18px;font-weight: 600 !important;">
                    <span class="line-left mr-2"></span> Get in touch
                  </h5>
                </div>
                <div class="row">
                  <span class="col-md-1"><img class="img-fluid" src="../assets/img/location.svg" /></span>
                  <div class="col-md-11 d-flex flex-column">
                    <h5 class="ml-1" style="font-size: 13px;">
                      Nushift Technologies Pvt. Ltd. <br />
                      T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar,
                      Madhapur,<br />
                      Hyderabad, Telangana 500081<br />
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <span class="col-md-1"><img class="img-fluid" src="../assets/img/phone.svg" /></span>
                  <div class="col-md-11 d-flex flex-column">
                    <h5 class="ml-1" style="font-size: 13px;">+91 90304 13379</h5>
                  </div>
                </div>
                <div class="row">
                  <span class="col-md-1"><img class="img-fluid" src="../assets/img/mail.svg" /></span>
                  <div class="col-md-11 d-flex flex-column">
                    <h5 class="ml-1" style="font-size: 13px;">info&#64;nushift.in</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer style="background-color: #133186">
          <div class="h-100 d-flex justify-content-center align-items-center">
            <div class="text-white font-size-2">
              © 2024 Nushift Connect | All rights reserved
            </div>
          </div>
        </footer>
      </div>
    </div>
  </ng-container>

  
</div>
