import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-visibility-settings',
  standalone: true,
  imports: [MatSidenavModule,MatListModule,MatIconModule,MatSlideToggleModule,FormsModule,CommonModule],
  templateUrl: './visibility-settings.component.html',
  styleUrl: './visibility-settings.component.scss'
})
export class VisibilitySettingsComponent {

  settings = {
    shareChanges: true,
    notifyViews: true,
    mentions: true
  };
  settings1 = {
    pageVisitVisibility: true,
    professionVisibility: true
  };

}
