import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-signin-security',
  standalone: true,
  imports: [MatSidenavModule,MatListModule,MatIconModule,MatSlideToggleModule,FormsModule,CommonModule],
  templateUrl: './signin-security.component.html',
  styleUrl: './signin-security.component.scss'
})
export class SigninSecurityComponent {


  onPreferenceClick(preference: string): void {
    console.log(preference);;
    // Here you can implement navigation or actions based on the preference
  }
  
}
