import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
@Component({
  selector: 'app-advertising-data',
  standalone: true,
  imports: [MatSidenavModule, MatListModule, CommonModule, MatIconModule, MatSlideToggleModule, FormsModule],
  templateUrl: './advertising-data.component.html',
  styleUrl: './advertising-data.component.scss'
})
export class AdvertisingDataComponent {


  settings = {
    gender: true,
    ageRange: true,
    cityLocation: true,
    profileLocation: true,
    customizedDisplay: true,
    groups: true,
    connections: true

  };
}
