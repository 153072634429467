<div class="preferences-container">
    <h2 class="title">How Nushift Connect uses your data</h2>
  
    <div class="preference-item">
      <span>Manage your data and activity</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Get a copy of your data</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Search history</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Personal demographic information</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Social, economic, and workplace research</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Data for Generative AI Improvement</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.aiImprovement"></mat-slide-toggle>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  
  <div class="preferences-container">
    <h2 class="title">Who can reach you</h2>
  
    <div class="preference-item">
      <span>Invitations from your network</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Messages</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Research invites</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.researchInvites"></mat-slide-toggle>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  