import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiEndpointsService } from './api-endpoint.service';
import { ApiHttpService } from './api-http.service';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MoreScreenService {

  destroy$ = new Subject<void>();

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  async sendAnEnquiry(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    // this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    // let encryptedPayload = {
    //   data: localStorage.getItem('aesToken')
    // }
    // console.log("Enquire now payload encrypted:- ")
    // console.log(encryptedPayload);
    return this.apiHttpService.post(
      this.apiEndpointsService.sendAnEnquiry(),
      params
    );
  }

  async sendAnSubscriptionEnquiry(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("Enquire now payload encrypted:- ")
    console.log(encryptedPayload);
    return this.apiHttpService.post(this.apiEndpointsService.sendAnSubscriptionEnquiry(), encryptedPayload);
  }

  getUserListBasedOnSearch(queryParams: any): Observable<any> {
    return this.apiHttpService.get(
      this.apiEndpointsService.getUserListbySearch(queryParams)
    );
  }

  getEnquiryServices(): Observable<any> {
    return this.apiHttpService.get(
      this.apiEndpointsService.getEnquiryServices()
    );
  }

  rateUs(formData: any): Observable<any> {
    return this.apiHttpService.post(
      this.apiEndpointsService.rateUs(),
      formData
    );
  }

  async createOrderId(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("createOrderId payload encrypted:- ")
    console.log(encryptedPayload);
    return (await this.apiHttpService.post(this.apiEndpointsService.createOrderId(), encryptedPayload)
    .pipe(
      map((res: any) => {
        return res;
      })
    ))
  }

  async subscriptionPayNow(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("createOrderId payload encrypted:- ")
    console.log(encryptedPayload);
    return (await this.apiHttpService.post(this.apiEndpointsService.subscriptionPayNow(), encryptedPayload)
    .pipe(
      map((res: any) => {
        return res;
      })
    ))
  }

  aboutUs(): Observable<any> {
    return this.apiHttpService.get(this.apiEndpointsService.aboutUs());
  }

  deactivateAccount(formData: any): Observable<any> {
    return this.apiHttpService.post(
      this.apiEndpointsService.accountDeactivate(),
      formData
    );
  }

  async changePass(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("changePassword payload encrypted:- ")
    console.log(encryptedPayload);
    return this.apiHttpService.post(
      this.apiEndpointsService.changePassword(),
      encryptedPayload
    );
  }

  async contactUs(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("changePassword payload encrypted:- ")
    console.log(encryptedPayload);
    return this.apiHttpService.post(this.apiEndpointsService.contactUs(),encryptedPayload);
  }

  async getPlansList(queryParams: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(queryParams);
    return this.apiHttpService.get(await this.apiEndpointsService.plansList(queryParams))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  async getSubscriptionHistory(queryParams: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(queryParams);
    return this.apiHttpService.get(await this.apiEndpointsService.getSubscriptionHistory(queryParams))
      .pipe(map((res: any) => {
        if (res.status) return res;
        else return null;
      }));
  }
  async getPlanDetails(queryParams: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(queryParams);
    return this.apiHttpService.get(await this.apiEndpointsService.planDetails(queryParams))
      .pipe(map((res: any) => {
        if (res.status) return res;
        else return null;
      }));
  }

  // async sendAnSubscriptionEnquiry(params: any): Promise<Observable<any>> {
  //   localStorage.setItem('aesToken', '')
  //   await this.apiEndpointsService.getAesToken(params);
  //   this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
  //   let encryptedPayload = {
  //     data: localStorage.getItem('aesToken')
  //   }
  //   console.log("Enquire now payload encrypted:- ")
  //   console.log(encryptedPayload);
  //   return this.apiHttpService.post(this.apiEndpointsService.sendAnSubscriptionEnquiry(), encryptedPayload);
  // }

  async sendOTPSubscription(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("createOrderId payload encrypted:- ")
    console.log(encryptedPayload);
    return (await this.apiHttpService.post(this.apiEndpointsService.sendOTPSubscription(), encryptedPayload)
    .pipe(
      map((res: any) => {
        return res;
      })
    ))
  }

  async validateOTPSubscription(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("validate OTP Subscription payload encrypted:- ")
    console.log(encryptedPayload);
    return (await this.apiHttpService.post(this.apiEndpointsService.validateOTPSubscription(), encryptedPayload)
    .pipe(
      map((res: any) => {
        return res;
      })
    ))
  }

 async submitChequeSubscription(params: any): Promise<Observable<any>> {
    localStorage.setItem('aesToken', '')
    await this.apiEndpointsService.getAesToken(params);
    this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), params.uid ?? 'nushift_connect', params);
    let encryptedPayload = {
      data: localStorage.getItem('aesToken')
    }
    console.log("submit Cheque Subscription payload encrypted:- ")
    console.log(encryptedPayload);
    return (await this.apiHttpService.post(this.apiEndpointsService.submitChequeSubscription(), encryptedPayload)
    .pipe(
      map((res: any) => {
        return res;
      })
    ))
  }


  unsubscribe() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  // async getApplicantsList(payload: any): Promise<Observable<any>> {
  //   return this.apiHttpService.get(
  //     await this.apiEndpointsService.getApplicantsList(payload)
  //   );
  // }

}
