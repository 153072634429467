<div class="preferences-container">
    <h2 class="title">Account access</h2>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('language')">
      <span>Email addresses</span>
      <div class="float-end">
        <span class="value">nushiftconnectgmail.com</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('contentLanguage')">
      <span>Phone numbers</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('autoplayVideos')">
      <span>Change password</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('autoplayVideos')">
      <span>Where you're signed in</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('autoplayVideos')">
      <span>Devices that remember your password</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="preference-item" (click)="onPreferenceClick('autoplayVideos')">
      <div class="float-end">
      <span class="value">Two-step verification</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    </div>
    <mat-divider></mat-divider>


  </div>