import { Component, OnInit } from '@angular/core';
import {  HostListener } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  selectedLabel: string = 'Home';
  isMobileView = false;

  @HostListener('window:resize', [])
  onResize() {
    this.checkMobileView();
  }
  constructor() { }

  ngOnInit(): void {
    this.checkMobileView();
  }

  onNavbarIconClick(label: string) {
    this.selectedLabel = label; // Update the middle div label based on the icon clicked
  }

  private checkMobileView() {
    this.isMobileView = window.innerWidth <= 768; // Adjust breakpoint as needed
  }

  navigate(destination: string) {
    alert( destination);
    // Add your navigation logic here
  }
}
