
<div class="preferences-container">
    <h2 class="title">Profile Information</h2>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('language')">
      <span>Name, Loction and Profession</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('contentLanguage')">
      <span>Personal demographic information</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('autoplayVideos')">
      <span>verifications</span>
      <div class="float-end">
        <span class="value">On</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>

    <mat-divider></mat-divider>

  </div>

  <div class="preferences-container">
    <h2 class="title">General Preferences</h2>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('language')">
      <span>Language</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('contentLanguage')">
      <span>Content Language</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('autoplayVideos')">
      <span>Autoplay Videos</span>
      <div class="float-end">
        <span class="value">On</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('soundEffects')">
      <span>Sound Effects</span>
      <div class="float-end">
        <span class="value">On</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('profilePhotos')">
      <span>Showing Profile Photos</span>
      <div class="float-end">
        <span class="value">All NusiftConnect members</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('feedView')">
      <span>Preferred Feed View</span>
      <div class="float-end">
        <span class="value">Most relevant posts (Recommended)</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('unfollowedPeople')">
      <span>People you unfollowed</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="preferences-container">
    <h2 class="title">Syncing Options</h2>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('language')">
      <span>Sync Calendar</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('contentLanguage')">
      <span>Sync Contacts</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  </div>

  <div class="preferences-container">
    <h2 class="title">Subscriptions and Payments</h2>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('language')">
      <span>Upgrade for free</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('contentLanguage')">
      <span>View purchase history</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  </div>


  <div class="preferences-container">
    <h2 class="title">Account Management</h2>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('language')">
      <span>Hibernate Account</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>

    <div class="preference-item" (click)="onPreferenceClick('contentLanguage')">
      <span>Close Account</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  </div>