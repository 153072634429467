import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class Constants {

  // public readonly API_ENDPOINT: string = "https://3.108.114.119/app";
  // public readonly API_ENDPOINT: string = "https://nushift.org/app";
  // public readonly PHP_API_ENDPOINT: string = "https://nushift.org/app";
  // public readonly FILTERED_ENDPOINT: string = "https://pydev.nushift.org/api/v2";
  // public readonly FILTERED_ENDPOINT: string = "https://pypro.nushift.org/api/v2";
  // public readonly FILTERED_ENDPOINT: string = "https://pyqa.nushift.org/api/v2";
  // public readonly API_ENDPOINT: string = 'https://nushift.org/test';
  // public readonly API_ENDPOINT: string = 'https://nushift.org/sale';   
  // public static readonly ASSETS_PATH: string = "https://3.108.114.119/app/assets/";
  // public static readonly ASSETS_PATH: string = "https://nushift-dev.s3.ap-south-1.amazonaws.com/";
  // public static readonly ASSETS_PATH: string = "https://nushift.org/test/assets/";
  // public static readonly ASSETS_PATH: string = "https://nushift.org/sale/assets/";
}

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyC-VhIQ4XysZDNTwSQgBYidGNhs9O98rVo",
//     authDomain: "hcc-doctors.firebaseapp.com",
//     databaseURL: "https://hcc-doctors.firebaseio.com",
//     projectId: "hcc-doctors",
//     storageBucket: "hcc-doctors.appspot.com",
//     messagingSenderId: "968123953038",
//     appId: "1:968123953038:web:0ec05601c7962416aafedf",
//     measurementId: "G-D53E8P17H0"
//   }
// };
