import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-account-preferences',
  standalone: true,
  imports: [MatSidenavModule,MatListModule,MatIconModule,MatSlideToggleModule,FormsModule,CommonModule],
  templateUrl: './account-preferences.component.html',
  styleUrl: './account-preferences.component.scss'
})
export class AccountPreferencesComponent {


  onPreferenceClick(preference: string): void {
    console.log(preference);;
    // Here you can implement navigation or actions based on the preference
  }
  
}
