import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VisibilitySettingsComponent } from "../visibility-settings/visibility-settings.component";
import { AccountPreferencesComponent } from "../account-preferences/account-preferences.component";
import { SigninSecurityComponent } from "../signin-security/signin-security.component";
import { DataPrivacyComponent } from "../data-privacy/data-privacy.component";
import { AdvertisingDataComponent } from "../advertising-data/advertising-data.component";
import { PrivacyNotificationsComponent } from "../privacy-notifications/privacy-notifications.component";

@Component({
  selector: 'app-privacy-settings',
  standalone: true,
  imports: [MatSidenavModule, MatListModule, CommonModule, MatIconModule, MatSlideToggleModule, FormsModule, VisibilitySettingsComponent, AccountPreferencesComponent, SigninSecurityComponent, DataPrivacyComponent, AdvertisingDataComponent, PrivacyNotificationsComponent],
  templateUrl: './privacy-settings.component.html',
  styleUrl: './privacy-settings.component.scss'
})
export class PrivacySettingsComponent {

  selectedMenu: string = 'account-preferences'; // Default selection

  constructor() {}

  onIconClick(menu: string): void {
   // alert( menu); // Debug log
    this.selectedMenu = menu;
  }

}

