<div class="rate-us-container">
  <div class="popup-header">
    <h1>Rate Us</h1>
    <button class="cancel-button" (click)="closePopup()">✖</button>
  </div>
  <h2>Tell Us your Experience about Our App?</h2>

  <img src="../../../assets/follow.png" alt="">
  <textarea
    [placeholder]="feedbackPlaceholder"
    class="feedback-input"
    [(ngModel)]="feedback"
  ></textarea>
  <div class="rating-container">
    <div class="rating-item" *ngFor="let option of ratingOptions">
      <input
      type="radio"
      [id]="option.id"
      name="rating"
      [value]="option.value"
      [(ngModel)]="selectedRating"
      (change)="updatePlaceholder(option.value)" 
          />
      <label [for]="option.id">{{ option.icon }}</label>
      <span>{{ option.label }}</span>
    </div>
  </div>
  <button class="submit-button" (click)="submitFeedback()">Submit</button>
</div>
