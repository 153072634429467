// guards/auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const uid = this.authService.userValue?.uid;
    const authRequired = route.data['authRequired'] ?? true;

    // Store the route that user was trying to access
    if (authRequired && !uid) {
      this.router.navigate(['/landing'], {
        queryParams: { 
          returnUrl: state.url // This will help us know where they were trying to go
        }
      });
      return false;
    }

    return true;
  }
}