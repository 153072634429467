import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { timeout } from 'rxjs';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { MoreScreenService } from 'src/app/services/more-screen.service';

@Component({
  selector: 'app-new-enquiries',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule],
  templateUrl: './new-enquiries.component.html',
  styleUrls: ['./new-enquiries.component.scss']
})
export class NewEnquiriesComponent {
  enquiryForm: FormGroup;
  toId: string = ''; // Populate dynamically or pass via input binding
  isSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private apiEndPointService: ApiEndpointsService,
    private moreScreenService: MoreScreenService,
    private router: Router,
    
  ) {
    this.enquiryForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]], // Assuming 10-digit phone numbers
      message: ['', Validators.required],
    });
  }
  ngOnInit(): void {}

  async onSubmit() {
    // Ensure the form is valid
    if (this.enquiryForm.invalid) {
      // this.openSnackBar('Please fill all required fields correctly.', 'Close');
      alert('Please fill all required fields correctly.');
      return;
    }

    this.spinner.show();

    const payload = {
      message: this.enquiryForm.get('message')?.value,
      email: this.enquiryForm.get('email')?.value,
      name: this.enquiryForm.get('name')?.value,
      phone: this.enquiryForm.get('phone')?.value,
      // regarding: this.enquiryForm.get('regarding')?.value,
      uid: this.authService.userValue?.uid,
    };

    console.log('payload', payload);

    try {
      (await this.moreScreenService.sendAnEnquiry(payload)).subscribe(
        async (res: any) => {
          this.spinner.hide();
          // if (res.status && res.data) {
          //   const decodedData = await this.apiEndPointService.decodeAesToken(
          //     this.authService.userValue?.uid || 'nushift_connect',
          //     res.data
          //   );


            
          //   console.log(
          //     JSON.parse(
          //       decodedData.replace(/'/g, '"').replace(/: None/g, ': "None"')
          //     )
          //   );
          // }
          this.openSnackBar(res.message, '');
          if (res.status) {
            // Reset the form on
            //  successful submission
            this.isSubmitted = true;
            this.enquiryForm.reset();
            setTimeout(() => {
              this.onCancel();
            }, 5000);
          }
        },
        (error) => {
          console.error('API Error:', error);
          this.spinner.hide();
          this.openSnackBar('Something went wrong. Please try again.', 'Close');
        }
      );
    } catch (error) {
      this.spinner.hide();
      console.error('Error:', error);
      this.openSnackBar('An unexpected error occurred.', 'Close');
    }
  }
  onCancel() {
    // Perform the desired action, e.g., close the thank-you section, navigate back, etc.
    console.log('Cancel button clicked');
    this.router.navigate(['/landing']);
    // Example: Reset form or navigate to another page
    // this.isSubmitted = false; // Assuming `isSubmitted` controls the thank-you visibility

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
