import { Injectable } from '@angular/core';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { getInstallations } from 'firebase/installations';
import { initializeApp } from 'firebase/app'; 
import * as CryptoJS from 'crypto-js';
import { environment } from './environments/environment';
import { HttpClient } from '@angular/common/http';
import {  getId } from 'firebase/installations'; 
@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  private readonly messaging;
  private readonly installations;

  constructor(private http: HttpClient) {
    console.log('🚀 Initializing FirebaseMessagingService');
    const firebaseApp = initializeApp(environment.firebase);
    this.messaging = getMessaging(firebaseApp);
    this.installations = getInstallations(firebaseApp);
    console.log('✅ Firebase initialized with config:', environment.firebase);
  }

  async getDeviceToken(): Promise<string> {
    console.log('📱 Starting getDeviceToken process');
    try {
      if ('Notification' in window) {
        console.log('🔔 Notifications API is available');
        const permission = await Notification.requestPermission();
        console.log('📢 Notification permission status:', permission);
        
        if (permission === 'granted') {
          console.log('✅ Notification permission granted, getting FCM token');
          return await this.getFCMToken();
        }
      } else {
        console.log('⚠️ Notifications not supported in this browser');
      }
      
      console.log('🔄 Falling back to Installation ID');
      return await this.getInstallationId();
      
    } catch (error) {
      console.error('❌ Error in getDeviceToken:', error);
      return await this.getInstallationId();
    }
  }

  private async getFCMToken(): Promise<string> {
    console.log('🎯 Starting FCM token generation');
    try {
      console.log('🔄 Registering service worker');
      const registration = await navigator.serviceWorker.register('/ngsw-worker.js');
      console.log('✅ Service worker registered');

      // This token comes from Firebase Cloud Messaging
      console.log('🔑 Requesting FCM token with VAPID key');
      const currentToken = await getToken(this.messaging, {
        vapidKey: 'BMoctavl11E7uA5oXFkG1vnJIsfVxIvIzTF_d9e7HfRxNXja5bZehDmLcm2odvSYWdDeAdQ_zY1pRovhZvvEDWc',
        serviceWorkerRegistration: registration,
      });

      console.log('📝 Raw FCM token received:', currentToken?.slice(0, 10) + '...');

      if (!currentToken) {
        throw new Error('No FCM token received');
      }

      console.log('🔒 Encrypting FCM token');
      const encryptedToken = this.encryptToken(currentToken);
      console.log('💾 Storing encrypted token in localStorage with key "kf"');
      localStorage.setItem('kf', encryptedToken);

      return encryptedToken;

    } catch (error) {
      console.error('❌ Error getting FCM token:', error);
      throw error;
    }
  }

  private async getInstallationId(): Promise<string> {
    console.log('🔧 Starting Installation ID generation');
    try {
      // This ID comes from Firebase Installations
      console.log('🔄 Requesting Firebase Installation ID');
      const installationId = await getId(this.installations);
      console.log('📝 Raw Installation ID received:', installationId?.slice(0, 10) + '...');

      console.log('🔒 Encrypting Installation ID');
      const encryptedId = this.encryptToken(installationId);
      console.log('💾 Storing encrypted Installation ID in localStorage with key "kf"');
      localStorage.setItem('kf', encryptedId);

      return encryptedId;
    } catch (error) {
      console.error('❌ Error getting Installation ID:', error);
      throw error;
    }
  }

  private encryptToken(token: string): string {
    console.log('🔐 Encrypting token/ID');
    const encrypted = CryptoJS.AES.encrypt(
      token,
      'ae65f515-b859-4c7f-887c-328a3c14ab7f'
    ).toString();
    console.log('✅ Encryption complete');
    return encrypted;
  }
}
