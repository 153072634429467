import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatSidenav;
  notifications: any[] = [];
  encodedJsonString: string = '';
  notificationType: string = 'ALL';
  @Input() isOpen: boolean = false;
  tabIndex: number = 0;
  unReadCount: string = '';
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getNotificationsList('ALL');
    this.getNotificationCount();
  }

  // Handle tab switching
  tabClick(index: number): void {
    this.tabIndex = index;
    const typeMap = ['ALL', 'REF', 'NTW', 'INV', 'POD'];
    const selectedType = typeMap[index] || 'ALL';
    this.getNotificationsList(selectedType);
  }

  // Fetch notifications list
  getNotificationsList(type: string): void {
    this.authService
      .getNotificationList({
        uid: this.authService.userValue.uid,
        filterBy: type,
      })
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.notifications = res.data;
          } else {
            this.notifications = [];
          }
        },
        (error) => {
          console.error('Error fetching notifications:', error);
        }
      );
  }



  getNotificationCount(): void {
    this.authService
      .getNotificationCount({
        uid: this.authService.userValue.uid,
      })
      .subscribe(
        (res: any) => {
          if (res.status) {
            // this.notifications = res.data;
            //  alert(res.data.total_count);
            this.unReadCount = res.data.total_count;
          } else {
            // this.notifications = [];
          }
        },
        (error) => {
          console.error('Error fetching notifications:', error);
        }
      );
  }
  markAllAsRead() {
    // this.notifications.forEach((notif) => (notif.unread = false));
    //  selectedType
    this.authService
      .getNotificationsRead({
        other_id: this.authService.userValue.uid,
        typ: 'all',
      })
      .subscribe(
        (res: any) => {
          if (res.status) {
            alert(res.message);
            //  this.notifications = res.data;
          } else {
            //  this.notifications = [];
          }
        },
        (error) => {
          console.error('Error fetching notifications:', error);
        }
      );
  }
  // Toggle notification drawer
  onClear(): void {
    // this.drawer = !this.drawer;
    this.isOpen = !this.isOpen;
  }

  // Handle notification click
  notificationClick(notification: any): void {
    const notificationType = notification.pType?.toLowerCase() || notification.ntype?.toLowerCase() || '';
    this.handleNotificationNavigation(notificationType, notification);
  }

  // Handle navigation based on notification type
  private handleNotificationNavigation(notificationType: string, notification: any): void {
    switch (notificationType) {
      case 'ref_view':
      case 'REF_VIEW':
        this.navigateWithEncodedParams('/refer', notification, 'notifications');
        break;
      case 'FEED_VIEW':
      case 'feed_view':
      case 'feedlike':
      case 'feedcomment':
      case 'feedshare':
        // this.navigateWithEncodedParams('/home/feed-details', notification, 'notifications');
        this.router.navigate(['/home/feed-details'], {
          queryParams: {
            feedID: notification.idref,
            // isHost: notification?.is_host,
            // eventType: notification?.type?.toLowerCase(),
          },
        });
        break;
      case 'POD_VIEW':
      case 'pod_view':
        this.router.navigate(['podcasts'], {
          queryParams: {
            eventId: notification.idref,
            isHost: notification?.is_host,
            eventType: notification?.type?.toLowerCase(),
          },
        });
        break;
      case 'NETWORK_VIEW':
      case 'PROFILE_VIEW':

        notification['uid'] = notification.sender_id;
        this.navigateWithEncodedParams('/profile', {
          viewId: notification.sender_id,
          connectionDetails: notification,
        });
        break;
      default:
        console.warn('Unhandled notification type:', notificationType);
    }
  }

  // Navigate to a route with encoded query params
  private navigateWithEncodedParams(route: string, data: any, from?: string): void {
    this.encodedJsonString = encodeURIComponent(JSON.stringify(data));
    const queryParams: any = { notification: this.encodedJsonString };
    if (from) {
      queryParams.from = from;
    }
    this.router.navigate([route], { queryParams });
  }

  // Clear individual notification
  onNotificationClear(notification: any): void {
    this.authService
      .clearNotification({
        uid: this.authService.userValue.uid,
        notification_id: notification.id,
      })
      .subscribe(
        (res) => {
          if (res.status) {
            this.showSnackBar(res.message);
            this.getNotificationsList(this.notificationType);
          } else {
            this.showSnackBar(res.message);
          }
        },
        (error) => {
          console.error('Error clearing notification:', error);
        }
      );
  }

  // Show snack bar notification
  private showSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 3000 });
  }
}
