<div class="preferences-container">
    <h2 class="title">Profile data</h2>
  
    <div class="preference-item">
      <span>Connections</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.connections"></mat-slide-toggle>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Groups</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.groups"></mat-slide-toggle>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Education</span>
      <span class="value">Schools &amp; 4 more</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Job information</span>
      <span class="value">Current job &amp; 1 more</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Customized display format</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.customizedDisplay"></mat-slide-toggle>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Profile Location</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.profileLocation"></mat-slide-toggle>
      </div>
    </div>
  </div>
  
  <div class="preferences-container">
    <h2 class="title">Activity and inferred data</h2>
  
    <div class="preference-item">
      <span>Inferred city location</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.cityLocation"></mat-slide-toggle>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Interests and traits</span>
      <span class="value">Current job &amp; 1 more</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Age range</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.ageRange"></mat-slide-toggle>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="preference-item">
      <span>Gender</span>
      <div class="float-end">
        <mat-slide-toggle [(ngModel)]="settings.gender"></mat-slide-toggle>
      </div>
    </div>
  </div>
  