import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service'; 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  latitude: any;
  longitude: any;
  @Input() drawer!: MatDrawer;
  @Output() loginClosed = new EventEmitter();
  @Input() closeLogin: any;
  hide = true;
  otp: any;
  disableButton: boolean = false;
  loginForm: FormGroup = new FormGroup({
    userid: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$'
      ),
    ]),
    pwd: new FormControl('', [Validators.required, Validators.min(3)]),
    as: new FormControl('D'),
    otp: new FormControl(null)
  });

  errors: any = { email: 'Email required' };
  showOtpField: boolean = false;
  firstLoginResponse: any = {
    "uid": "D188387504352126_D001",
    "name": "Dr. Sravani",
    "lastName": "Vajrala",
    "usts": 1,
    "ppic": "https://nushift-dev.s3.ap-south-1.amazonaws.com/default/profiles/female.png",
    "is_subscribed": "no",
    "mob": "8309446398",
    "email": "pometi7491@mainmile.com",
    "cphncode": 91,
    "firstTimePassword": "no",
    "specialization_name": "None",
    "extra_user_type": "USER",
    "extra_new_device": "yes"
  };
  geoCoder: any;
  verifiedOtpResponse: any;
  otpLoginResponse: any;
  otpValidated: boolean = false;
  decryptedUserDetails: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private apiEndPointService: ApiEndpointsService,
    private nav: NavbarService, 
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
    this.nav.navbarHide();
    this.nav.footerHide();
    // this.geoCoder = new google.maps.Geocoder();
    // this.getCurrentLocation();
  }


  uid: string;
  generateUUIDV4(): string {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // eslint-disable-next-line no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
  }
  getDeviceId() {
    let deviceId = localStorage.getItem('deviceId')
    if (!deviceId) {
      deviceId = this.generateUUIDV4()
      localStorage.setItem('deviceId', deviceId)
    }
    return deviceId;
  }

  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }

  getAddress(latitude: any, longitude: any): void {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: { formatted_address: any }[], status: string) => {
        if (status === 'OK') {
          if (results[0]) {
            localStorage.setItem('deviceLocation', results[0]['formatted_address']);
            localStorage.setItem('latitude', latitude);
            localStorage.setItem('longitude', longitude);
          }
        } else {
          // window.alert('Geocoder failed due to: ' + status);
        }
      }
    );

  }


  async validateOtp() {
    debugger
    let payload ={
      mobile: this.loginForm.get('userid').value,
      otp: Number(this.loginForm.get('otp').value)
    };
    this.spinner.show();
    (await this.authService.validateOTPV2(payload)).subscribe((res) => {
      console.log(res);
      // let loginResponse: any;
      this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then((data) => {
        if(data) {
          console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')));
        this.otpLoginResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
        console.log(this.otpLoginResponse);
        }
        if (res.status) {
          this.spinner.hide();
          this.otpValidated = true;
          this.showOtpField = false;
          this.otpLoginResponse['td'] = CryptoJS.AES.decrypt(localStorage.getItem('kf'), 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString(CryptoJS.enc.Utf8);
          let encodedUserString = encodeURIComponent(JSON.stringify(this.otpLoginResponse));
          localStorage.setItem('user', encodedUserString);
          let decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
            this.authService.userSubject = new BehaviorSubject<any>(decryptedUserDetails);
            this.authService.user = this.authService.userSubject.asObservable();
            this.authService.userSubject.next(decryptedUserDetails);
            console.log(decryptedUserDetails);
        } else {
          this.spinner.hide();
          this.openSnackBar(res.message, '');
        }
      });
    }, error => {
      this.openSnackBar('Something went wrong! Please try again', '');
      console.log(error);
      this.spinner.hide();
    });
  }
  async login() {
    debugger;
    const payload = {
      user_name: this.loginForm.get('userid').value,
      password: this.loginForm.get('pwd').value,
      device_token: CryptoJS.AES.decrypt(localStorage.getItem('kf'), 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString(CryptoJS.enc.Utf8),
      device_id: this.getDeviceId(),
      uid: 'nushift_connect'
    };
  
    this.spinner.show();
    (await this.authService.loginv3(payload)).subscribe({
      next: (res) => {
        if (res.status && res.data) {
          this.apiEndPointService.decodeAesToken(
            (this.authService && this.authService.userValue && this.authService.userValue.uid) 
              ? this.authService.userValue.uid 
              : 'nushift_connect', 
            res.data
          ).then(async (data) => {
            this.firstLoginResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
            
            if (res.status) {
              this.spinner.hide();
              this.firstLoginResponse['td'] = CryptoJS.AES.decrypt(localStorage.getItem('kf'), 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString(CryptoJS.enc.Utf8);
              
              if (this.firstLoginResponse.alid) {
                let payload = {
                  uid: this.firstLoginResponse.uid,
                  alid: this.firstLoginResponse.alid,
                };
                (await this.authService.getAlias(payload)).subscribe((res) => {
                  this.apiEndPointService.decodeAesToken(this.firstLoginResponse.uid, res.data);
                });
              }
  
              let encodedUserString = encodeURIComponent(JSON.stringify(this.firstLoginResponse));
              localStorage.setItem('user', encodedUserString);
              let decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
              this.authService.userSubject = new BehaviorSubject<any>(decryptedUserDetails);
              this.authService.user = this.authService.userSubject.asObservable();
              this.authService.userSubject.next(decryptedUserDetails);
  
              if (this.firstLoginResponse && this.firstLoginResponse.firstTimePassword) {
                if (this.firstLoginResponse.firstTimePassword.toLowerCase() == 'yes') {
                  this.router.navigate(['/register'], {
                    queryParams: { firstLoginResponse: JSON.stringify(this.firstLoginResponse) }
                  });
                } else if (this.firstLoginResponse.firstTimePassword.toLowerCase() == 'no') {
                  if (this.firstLoginResponse.extra_new_device && 
                      this.firstLoginResponse.extra_new_device.toLowerCase() == 'no') {
                    let newDeviceLogin: boolean = false;
                    this.openSnackBar(res.message, '');
                    this.authService.setLocalStorage(this.firstLoginResponse);
                    localStorage.setItem('loggedInPpic', this.firstLoginResponse.ppic);
                    localStorage.setItem('loggedInUid', this.firstLoginResponse.uid);
                    localStorage.setItem('loggedInUsername', (this.firstLoginResponse.name + ' ' + this.firstLoginResponse.lastName));
                    
                    // Use the centralized navigation method
                    this.handlePostLoginNavigation(newDeviceLogin);
                  } else if (this.firstLoginResponse.extra_new_device && 
                           this.firstLoginResponse.extra_new_device.toLowerCase() == 'yes') {
                    this.openSnackBar(res.message, '');
                    this.showOtpField = true;
                    this.loginForm.get('otp').markAsUntouched();
                    this.loginForm.get('otp').setValidators(Validators.required);
                    this.authService.setLocalStorage(this.firstLoginResponse);
                    localStorage.setItem('loggedInPpic', this.firstLoginResponse.ppic);
                    localStorage.setItem('loggedInUid', this.firstLoginResponse.uid);
                    localStorage.setItem('loggedInUsername', (this.firstLoginResponse.name + ' ' + this.firstLoginResponse.lastName));
                  }
                }
              }
            }
          });
        } else {
          this.spinner.hide();
          this.openSnackBar(res.message, '');
        }
      },
      error: (error) => {
        this.openSnackBar('Something went wrong! Please try again', '');
        this.spinner.hide();
      }
    });
  }
  private handlePostLoginNavigation(isOtpLogin: boolean) {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (returnUrl) {
      // User came from a protected route - navigate there
      this.router.navigate([returnUrl], {
        
      });
    } else {
      // User came from landing page - go to home
      this.router.navigate(['home'], {
      
      });
    }
    // Close login form
    this.loginClosed.emit(false);
  }
  async verifyOTP() {
    debugger;
    let decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
    const payload = {
      uid: this.firstLoginResponse.uid,
      otp: Number(this.loginForm.get('otp').value),
      device_type: 'Web',
      device_token: localStorage.getItem('kf') 
        ? CryptoJS.AES.decrypt(localStorage.getItem('kf'), 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString(CryptoJS.enc.Utf8) 
        : '',
      device_model: (this.deviceService.getDeviceInfo().browser && this.deviceService.getDeviceInfo().os) 
        ? (this.deviceService.getDeviceInfo().os + ' - ' + this.deviceService.getDeviceInfo().browser) 
        : '',
      latitude: this.latitude ? this.latitude.toString() : '17.3850',
      longitude: this.longitude ? this.longitude.toString() : '78.4867',
      device_id: this.getDeviceId() ?? '',
      address: localStorage.getItem('deviceLocation') ?? '',
      alid: decryptedUserDetails.alid ?? ''
    };
  
    this.spinner.show();
    (await this.authService.OtpLogin(payload)).subscribe({
      next: (res) => {
        let otpLogin: boolean = false;
        this.apiEndPointService.decodeAesToken(
          (this.authService && this.authService.userValue && this.authService.userValue.uid) 
            ? this.authService.userValue.uid 
            : 'nushift_connect', 
          res.data
        ).then((data) => {
          if (data) {
            this.verifiedOtpResponse = JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'));
            
            if (res.status) {
              this.spinner.hide();
              this.openSnackBar(res.message, '');
              this.verifiedOtpResponse['td'] = CryptoJS.AES.decrypt(localStorage.getItem('kf'), 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString(CryptoJS.enc.Utf8);
              
              let encodedUserString = encodeURIComponent(JSON.stringify(this.verifiedOtpResponse));
              localStorage.setItem('user', encodedUserString);
              let decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
              this.authService.userSubject = new BehaviorSubject<any>(decryptedUserDetails);
              this.authService.user = this.authService.userSubject.asObservable();
              this.authService.userSubject.next(decryptedUserDetails);
              
              otpLogin = true;
              
              // Use the centralized navigation method
              this.handlePostLoginNavigation(otpLogin);
              
            } else {
              this.spinner.hide();
              this.openSnackBar(res.message, '');
            }
          } else {
            this.spinner.hide();
            this.openSnackBar(res.message, '');
          }
        });
      },
      error: (error) => {
        this.openSnackBar('Something went wrong! Please try again', '');
        this.spinner.hide();
      }
    });
  }
  
  


  onCloseDrawer() {
    this.loginClosed.emit(false);
  }
  gotoCreatePassword = () =>
    this.router.navigate(['auth/createPassword'], {
      queryParams: { uid: this.uid },
    });

  gotoHome = (otpLogin) => this.router.navigate(['home'], {queryParams: {isRole: otpLogin ? true : false, ln: true}});    // 'ln' means user logged in

  gotoRegister = () => this.router.navigateByUrl('register');

  gotoForgotPassword = () => this.router.navigateByUrl('forgotPassword');

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
