<div class="subscriptions-main-container w-100 position-absolute container-fluid">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-4 d-flex">
        <div
          class="card text-center mb-3 shadow-sm"
          [class.selected]="isCardSelected('basic')"
          (click)="selectCard('basic')"
        >
          <div class="card-body position-relative d-flex flex-column">
            <img
              src="../../../../../assets/subscriptions/free.png"
              class="corner-image"
              alt="Basic"
            />
            <h5 class="card-title">Basic</h5>
            <h6 class="card-subtitle mb-2 text-muted">Free</h6>
            <h3 class="amount">0/-</h3>
            <p class="card-text">Enjoy all these features</p>
            <div
              class="w-100 plan-detail m-3"
              *ngFor="let plan of plansList; let mainIndex = index"
            >
              <div *ngIf="plan.name.toLowerCase() === 'free'">
                <div
                  *ngFor="
                    let subItem of plan.fnames.split('#') | slice : 0;
                    let i = index
                  "
                >
                  <ul class="left-aligned-list">
                    <li  style="font-size: 12px !important; font-weight: bold;">
                      <img
                        src="../../../../../assets/subscriptions/green_tick.png"
                        alt="Networking Icon"
                      /><span> {{ subItem.split("|")[0] }}</span> 
                      
                    </li>
                  </ul>
                </div>
                <button type="button" name="button" (click)="onPlanCLick(plan)" class="submit-button text-center"
                *ngIf="plan.is_current_plan===true"> Current Plan </button>

              <button type="button" name="button" class="submit-button text-center noHover" *ngIf="plan.is_current_plan==='processing'"> Processing </button>

              <button type="button" name="button" (click)="onPlanCLick(plan)" class="submit-button text-center" *ngIf="plan.is_current_plan===false"> Subscribe </button>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex">
        <div
          class="card text-center mb-3 shadow-sm"
          [class.selected]="isCardSelected('premium')"
          (click)="selectCard('premium')"
        >
          <div class="card-body position-relative d-flex flex-column">
            <img
              src="../../../../../assets/subscriptions/paid.png"
              class="corner-image"
              alt="Premium"
            />
            <h5 class="card-title">Premium</h5>
            <h6 class="card-subtitle mb-2 text-muted">(Billed Annulay)</h6>
            <h3 class="amount">1998/- month</h3>
            <p class="card-text">Enjoy all these features, plus</p>
            <div
              *ngFor="let plan of plansList; let mainIndex = index"
            >
              <div *ngIf="plan.name.toLowerCase() === 'premium'">
                <ul style="list-style-type: none;" class="left-aligned-list">
                  <li
                    *ngFor="
                      let subItem of plan.fnames.split('#') | slice : 0;let i = index" (click)="toggleItem(i)"
                      style="font-size: 12px !important; font-weight: bold;"
                  >
                      <img
                        src="../../../../../assets/subscriptions/blue_tick.png"
                        alt="Networking Icon"
                      /><span> {{ subItem.split("|")[0] }}</span>
                    <ul *ngIf="isSelected(i)" style="list-style-type: none" class="left-aligned-list">
                      <li
                        *ngFor="
                          let subItemPoint of subItem.split('|') | slice : 1 : subItem.split('|').length;let j = index"
                      class="list-item-text"  style="font-size: 10px !important;">
                        <img
                        src="../../../../../assets/subscriptions/arrow-up.png"
                        alt="Networking Icon"
                      /><span class="list-item-text">  {{subItem.split("|")[j + 1] }}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <!-- <button class="submit-button text-center" (click)="onPlanCLick(plan)">Subscribe</button> -->

                <button type="button" name="button" (click)="onPlanCLick(plan)" class="submit-button text-center"
                *ngIf="plan.is_current_plan===true"> Current Plan </button>
              <button type="button" name="button" class="submit-button text-center noHover" *ngIf="plan.is_current_plan==='processing'"> Processing </button>
              <button type="button" name="button" (click)="onPlanCLick(plan)" class="submit-button text-center" *ngIf="plan.is_current_plan===false"> Subscribe </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex">
        <div
          class="card text-center mb-3 shadow-sm"
          [class.selected]="isCardSelected('enterprise')"
          (click)="selectCard('enterprise')"
        >
          <div class="card-body position-relative d-flex flex-column">
            <img
              src="../../../../../assets/subscriptions/support.png"
              class="corner-image"
              alt="Enterprise"
            />
            <h5 class="card-title">Enterprise</h5>
            <h6 class="mb-2 support-lable">For Your Support</h6>
            <p class="card-text">Enterprise solutions</p>
      
            <ul class="left-aligned-list mt-2">
              <li  style="font-size: 12px !important; font-weight: bold;">
                <img
                  src="../../../../../assets/subscriptions/green_tick.png"
                  alt="Networking Icon"
                /><span> For any custom solutions feel
                  free to get in touch with us</span>
              </li>
            </ul>

            <form [formGroup]="enquiryForm" (ngSubmit)="onEnquiryClick()" class="mt-2">

              <button class="mt-2" type="submit" class="submit-button text-center" >Enquire</button>

              <div class="form-group mt-2">
                <input type="text" class="form-control" formControlName="name" placeholder="Full name">
              </div>
              <div class="form-group mt-2">
                <input type="text" class="form-control" formControlName="organization" placeholder="Organisation name">
              </div>
              <div class="form-group ">
                <input type="text" class="form-control" formControlName="designation" placeholder="Designation">
              </div>
              <div class="form-group mt-2 ">
                <textarea class="form-control" formControlName="description" placeholder="Description"></textarea>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="default"
    color="#fff"
    type="ball-atom"
    [fullScreen]="true"
  >
    <b style="color: white"> Loading... </b>
  </ngx-spinner>
</div>
