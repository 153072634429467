<div color="primary" class="new-navbar text-center">
  <!-- Logo -->
  <div class="logo"  (click)="onIconClick('Home')" matTooltip="Home">
    <img (click)="onIconClick('Home')" matTooltip="Home" src="../../../../assets/new_feed/new_logo.png" alt="NUSHIFT CONNECT" />
  </div>

  <!-- Centered Search Box -->
  <!-- <div class="search-container">
    <mat-form-field appearance="outline" class="search-box">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput placeholder="Search" />
    </mat-form-field>
  </div> -->
  <div class="search-bar">
    <mat-icon matPrefix>search</mat-icon>
    <!-- <input type="text" placeholder="Search..." /> -->

    <input 
    type="text" 
    class="search-input" 
    placeholder="Search..." 
    (input)="onSearch($event)"
  />
  <!-- <button (click)="clearSearch()">Clear</button> -->
  </div>

  <div class="icons">
    <mat-icon (click)="onIconClick('Chat')" matTooltip="Chat">chat</mat-icon>
    <mat-icon (click)="onIconClick('Grid View')" matTooltip="Dashboard">grid_view</mat-icon>
    <mat-icon (click)="onIconClick('Notifications')" matTooltip="Notifications">notifications</mat-icon>
    <span class="profile-pic">

      
      <img matTooltip="Profile" [src]="ppic ? ppic : '../../../../assets/new_feed/user.png'" alt="Profile Picture" (click)="onIconClick('My Profile')"/>

    </span>
    <mat-icon (click)="onIconClick('Apps')" matTooltip="Bussiness">apps</mat-icon>
    <!-- <mat-icon (click)="onIconClick('More Options')">more_horiz</mat-icon> -->
    <mat-icon matTooltip="More Options" [matMenuTriggerFor]="moreOptionsMenu">more_horiz</mat-icon >
    <mat-menu #moreOptionsMenu="matMenu">
      <button mat-menu-item (click)="performAction('My Profile')">
        <span>My Profile</span>
      </button>
      <!-- <button mat-menu-item (click)="performAction('Subscriptions')">
        <span>Subscriptions</span>
      </button> -->

      <button mat-menu-item (click)="performAction('Enquiries')">
        <span>Enquiries</span>
      </button>
      <button mat-menu-item (click)="onIconClick('Privacy')">
        <span>Privacy & Settings</span>
      </button>
      <button mat-menu-item (click)="performAction('Terms and Conditions')">
        <span>Terms and Conditions</span>
      </button>
      <button mat-menu-item (click)="performAction('Rate Us')">
        <span>Rate Us</span>
      </button>
      <button mat-menu-item (click)="performAction('About Us')">
        <span>About Us</span>
      </button>
      <button mat-menu-item (click)="navigateTo('contactus')">
        <span>Contact Us</span>
      </button>
      <button mat-menu-item (click)="performAction('Change Password')">
        <span>Change Password</span>
      </button>
      <!-- <button mat-menu-item (click)="performAction('Account Privacy Settings')">
        <span>Account Privacy Settings</span>
      </button> -->
      <!-- <button mat-menu-item (click)="performAction('Switch Profile')">
        <span>Switch Profile</span>
      </button> -->
      <button mat-menu-item (click)="performAction('Deactivate Account')">
        <span>Deactivate Account</span>
      </button>
      <button mat-menu-item (click)="performAction('Logout')">
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>

  

</div>