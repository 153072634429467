<div class="container">
  <!-- Left Sidebar -->
  <div class="left-sidebar">
    <h2>Settings & Privacy</h2>
    <ul class="menu">
      <li class="menu-item" [class.active]="selectedMenu === 'account-preferences'"
        (click)="onIconClick('account-preferences')">
        <mat-icon class="menu-icon">person</mat-icon>
        <span class="menu-text">Account preferences</span>
      </li>
      <li class="menu-item" [class.active]="selectedMenu === 'sign-in-security'"
        (click)="onIconClick('sign-in-security')">
        <mat-icon class="menu-icon">lock</mat-icon>
        <span class="menu-text">Sign in & Security</span>
      </li>
      <li class="menu-item" [class.active]="selectedMenu === 'visibility'" (click)="onIconClick('visibility')">
        <mat-icon class="menu-icon">visibility</mat-icon>
        <span class="menu-text">Visibility</span>
      </li>
      <li class="menu-item" [class.active]="selectedMenu === 'data-privacy'" (click)="onIconClick('data-privacy')">
        <mat-icon class="menu-icon">shield</mat-icon>
        <span class="menu-text">Data Privacy</span>
      </li>
      <li class="menu-item" [class.active]="selectedMenu === 'advertising-data'"
        (click)="onIconClick('advertising-data')">
        <mat-icon class="menu-icon">query_stats</mat-icon>
        <span class="menu-text">Advertising Data</span>
      </li>
      <li class="menu-item" [class.active]="selectedMenu === 'notifications'" (click)="onIconClick('notifications')">
        <mat-icon class="menu-icon">notifications</mat-icon>
        <span class="menu-text">Notifications</span>
      </li>
    </ul>
  </div>
  <!-- Right Content -->
  <div class="right-content">
    <div *ngIf="selectedMenu === 'account-preferences'">
       <app-account-preferences></app-account-preferences>
    </div>
    <div *ngIf="selectedMenu === 'sign-in-security'">
      <app-signin-security></app-signin-security>
    </div>
    <div *ngIf="selectedMenu === 'visibility'">
        <app-visibility-settings></app-visibility-settings>
    </div>
    <div *ngIf="selectedMenu === 'data-privacy'">
     <app-data-privacy></app-data-privacy>
    </div>
    <div *ngIf="selectedMenu === 'advertising-data'">
     <app-advertising-data></app-advertising-data>
    </div>
    <div *ngIf="selectedMenu === 'notifications'">
      <app-privacy-notifications></app-privacy-notifications>
    </div>
  </div>
</div>