import { FormsModule } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-new-rate-us',
  standalone: true,
  imports: [FormsModule, BrowserModule],
  templateUrl: './new-rate-us.component.html',
  styleUrls: ['./new-rate-us.component.scss'], // Corrected this line
})
export class NewRateUsComponent implements OnInit {
  feedback: string = '';
  selectedRating: string = '';

  ratingOptions = [
    { id: 'excellent', value: 'Excellent', icon: '😍', label: 'Excellent' },
    { id: 'good', value: 'Good', icon: '😊', label: 'Good' },
    { id: 'normal', value: 'Normal', icon: '😐', label: 'Normal' },
    { id: 'confused', value: 'Confused', icon: '😕', label: 'Confused' },
    { id: 'bad', value: 'Bad', icon: '😭', label: 'Bad' },
  ];

  feedbackPlaceholder: string = 'Wow, you loved it! We\'re thrilled to exceed your expectations!';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewRateUsComponent>,
  ) { }

  ngOnInit(): void {
    // Set default rating to 'Excellent'
    this.selectedRating = 'Excellent';
  }

  updatePlaceholder(rating: string) {
    switch (rating) {
      case 'Excellent':
        this.feedbackPlaceholder = 'Wow, you loved it! We\'re thrilled to exceed your expectations!';
        break;
      case 'Good':
        this.feedbackPlaceholder = 'We\'re happy you enjoyed it. Let us know how we can do even better!';
        break;
      case 'Normal':
        this.feedbackPlaceholder = 'Thank you! We\'d love to hear how we can improve.';
        break;
      case 'Confused':
        this.feedbackPlaceholder = 'We\'re sorry for the confusion. Please share your thoughts.';
        break;
      case 'Bad':
        this.feedbackPlaceholder = 'We\'re really sorry! Please tell us what went wrong.';
        break;
      default:
        this.feedbackPlaceholder = 'Wow, you loved it! We\'re thrilled to exceed your expectations!';
    }
  }

  submitFeedback() {
    if (!this.selectedRating) {
      alert('Please select a rating before submitting!');
      return;
    }
    if (!this.feedback.trim()) {
      alert('Please provide your feedback before submitting!');
      return;
    }

    console.log('Feedback:', this.feedback);
    console.log('Selected Rating:', this.selectedRating);
    alert(`Thank you for your feedback! 
Rating: ${this.selectedRating}
Feedback: ${this.feedback}`);
  }

  closePopup() {
    this.dialogRef.close();
  }
}
