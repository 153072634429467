<div class="enquire-container">
  <div *ngIf="!isSubmitted" class="content-section">
    <h2>Enquire us</h2>
    <p>
      At Nushift Connect, we believe in fostering a unified and inclusive healthcare ecosystem. We aim to connect, collaborate, and engage with healthcare professionals, enterprises, biomedical universities, pharmaceutical companies, and general users to drive meaningful change.
    </p>
    <p>
      Interested in how Nushift Connect can enhance your health education? Reach out with your questions, and our team will be happy to help you find tailored solutions to meet your needs.
    </p>
    <h3>Key Focus Areas</h3>
    <ul>
      <li>Networking opportunities within the healthcare community.</li>
      <li>Collaborative partnerships to enhance healthcare delivery.</li>
      <li>Engagement strategies that empower patients and professionals.</li>
      <li>Promoting health education to create informed and proactive participants in the healthcare ecosystem.</li>
    </ul>
  </div>

  <div *ngIf="!isSubmitted" class="form-section">
    <h3>
      Fill out the form below, and let's embark on a journey of a unified healthcare ecosystem together!
    </h3>
    <form [formGroup]="enquiryForm">
      <div class="form-group">
        <label for="fullName">Full Name</label>
        <input
          type="text"
          id="fullName"
          formControlName="name"
          placeholder="Enter your full name"
        />
        <div *ngIf="enquiryForm.get('name')?.touched && enquiryForm.get('name')?.invalid" class="error">
          Full Name is required.
        </div>
      </div>
    
      <div class="form-group">
        <label for="email">Email ID</label>
        <input
          type="email"
          id="email"
          formControlName="email"
          placeholder="Enter your E-mail ID"
        />
        <div *ngIf="enquiryForm.get('email')?.touched && enquiryForm.get('email')?.invalid" class="error">
          <span *ngIf="enquiryForm.get('email')?.errors?.['required']">Email is required.</span>
          <span *ngIf="enquiryForm.get('email')?.errors?.['email']">Invalid email format.</span>
        </div>
      </div>
    
      <div class="form-group">
        <label for="phone">Phone number</label>
        <input
          type="tel"
          id="phone"
          formControlName="phone"
          placeholder="Enter your phone number"
        />
        <div *ngIf="enquiryForm.get('phone')?.touched && enquiryForm.get('phone')?.invalid" class="error">
          <span *ngIf="enquiryForm.get('phone')?.errors?.['required']">Phone number is required.</span>
          <span *ngIf="enquiryForm.get('phone')?.errors?.['pattern']">Invalid phone number format.</span>
        </div>
      </div>
    
      <div class="form-group">
        <label for="message">Message</label>
        <textarea
          id="message"
          formControlName="message"
          placeholder="Enter your Message"
        ></textarea>
        <div *ngIf="enquiryForm.get('message')?.touched && enquiryForm.get('message')?.invalid" class="error">
          Message is required.
        </div>
      </div>
    
      <button type="submit" (click)="onSubmit()">Submit</button>
    </form>
  </div>

  <div *ngIf="isSubmitted" class="thank-you-section text-center">
    <div class="thank-you-container">
      <button class="cancel-button" (click)="onCancel()">X</button>
      <img
        src="../../../assets/Mask group.png"
        alt="Thank You Image"
        class="thank-you"
      />
      <h1 class="mt-3">Thank You for Getting in Touch!</h1>
      <p>
        We've received your submission and appreciate your interest. Our team
        will review your details and get back to you shortly.
      </p>
    </div>
  </div>
</div>
