<div class="preferences-container">
    <h2 class="title">Notification you receive</h2>

    <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>Searching for job</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>hiring someone</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>connecting with others</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>network catch-up updates</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>posting and commenting</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>messaging</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>


      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>groups</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>Pages</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>Attending events</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>news and reports</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span>Updating your profile</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="preference-item" (click)="onPreferenceClick('language')">
        <span> Verifications</span>
        <div class="float-end">
          <mat-slide-toggle [(ngModel)]="settings.socialResearch"></mat-slide-toggle>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

</div>