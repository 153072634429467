<div class="container-fluid w-100 main-container">


  <ng-container *ngIf="!isLoginClicked">
    <div class="hero-container">
      <div class="header">
        <img src="../../assets/img/nushiftconnect_logo.svg" alt="Logo" class="logo" />
        <button class="login" (click)="login('login')">Login/Signup</button>
      </div>


      <p class="small-head">Welcome to Nushift Connect</p>

      <!-- Main Heading -->
      <div class="hero-text">
        <h1>
          Network, Collaborate, and Engage with the Largest Healthcare Community—
          Seamlessly and Effortlessly
        </h1>
      </div>

      <!-- Image and Registration Form Section -->
      <div class="main-content">
        <!-- Healthcare Professionals Image -->
        <div class="professionals">
          <img src="../../assets/new_landing/doctors.png" alt="Healthcare Professionals" />
        </div>

        <!-- Registration Form -->
        <div class="registration-form">
          <h2>Register Now</h2>
          <form>
            <input type="text" placeholder="Name" />
            <input type="text" placeholder="Mobile Number" />
            <button type="submit">Send OTP</button>
          </form>
        </div>
      </div>
    </div>

    <div class="agenda-section">
      <h2>Our Agenda</h2>
      <div class="boxes-container">
        <div class="info-box">
          <img src="../../assets/new_landing/img-1.png" alt="Icon" class="icon" />
          <h3>Network</h3>
          <p>
            Content related to network will come here. Content related to network.
          </p>
        </div>
        <div class="info-box">
          <img src="../../assets/new_landing/img-2.png" alt="Icon" class="icon" />
          <h3>Engage</h3>
          <p>Content related to engagement. will come here. Content here.</p>
        </div>
        <div class="info-box">
          <img src="../../assets/new_landing/img-3.png" alt="Icon" class="icon" />
          <h3>Collaborate</h3>
          <p>Content related to collaboration will come here. Content here.</p>
        </div>
      </div>
    </div>

    <div class="content-creator-section">
      <h2>Get insights about trending topics on healthcare</h2>
      <div class="content-container">
        <!-- Left Side Static Image -->
        <div class="static-image">
          <img src="../../assets/new_landing/podcastlist.jpg" alt="Content Creator Image" />
        </div>

        <!-- Right Side Static Text -->
        <div class="static-text">
          <p>
            In 2024, Generative AI is transforming healthcare with smarter
            diagnostics and personalized care, while telemedicine 2.0 leverages
            IoT for advanced remote monitoring and patient management.
          </p>
        </div>
      </div>

      <!-- Pagination -->
      <div class="pagination">
        <span class="dot active"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <div class="video-conatainer">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="Sample YouTube video"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>

    <div class="testimonials-container">
      <h1>Users testimonials</h1>
      <p>Stories that keep us going</p>
      <div class="testimonials">
        <div class="testimonial-card">
          <div class="testimonial-header">
            <div class="user-icon">P</div>
            <div>
              <h3>Prerna</h3>
              <span>January 14, 2022</span>
            </div>
          </div>
          <div class="testimonial-content">
            <h4>Helped with collaboration with doctors of the same field</h4>
            <div class="tags">
              <div class="tag">Highly recommended</div>
              <div class="tag">Engaging platform</div>
            </div>
            <p class="testimonial-text">
              User testimonial will come here. User has a testimonial to write it
              will be represented here.
            </p>
          </div>
        </div>

        <div class="testimonial-card">
          <div class="testimonial-header">
            <div class="user-icon">B</div>
            <div>
              <h3>Brijesh</h3>
              <span>December 10, 2021</span>
            </div>
          </div>
          <div class="testimonial-content">
            <h4>Helped with collaboration with doctors of the same field</h4>
            <div class="tags">
              <div class="tag">Networking platform</div>
              <div class="tag">Engaging platform</div>
            </div>
            <p class="testimonial-text">
              User testimonial will come here. User has a testimonial to write it
              will be represented here.
            </p>
          </div>
        </div>

        <div class="testimonial-card">
          <div class="testimonial-header">
            <div class="user-icon">R</div>
            <div>
              <h3>Rohan</h3>
              <span>November 14, 2021</span>
            </div>
          </div>
          <div class="testimonial-content">
            <h4>Helped with collaboration with doctors of the same field</h4>
            <div class="tags">
              <div class="tag">Highly recommended</div>
              <div class="tag">Engaging platform</div>
            </div>
            <p class="testimonial-text">
              User testimonial will come here. User has a testimonial to write it
              will be represented here.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content-creator-section">
      <h2>Join the Conversation Today</h2>
      <div class="content-container">
        <!-- Left Side Static Image -->
        <div class="static-image">
          <img src="../../assets/new_landing/nurse.png" alt="Content Creator Image" />
        </div>

        <!-- Right Side Static Text -->
        <div class="static-text">
          <p>
            <b>Ready to be part of the future of healthcare?</b> <br />
            Join Nushift Connect to engage with like-minded professionals, access
            collaborative opportunities, and enhance your healthcare journey
            through valuable connections.
          </p>
        </div>
      </div>

      <div class="footer-dev">
        <div class="footer-container">
          <div class="footer-logo">
            <h2>LOGO</h2>
            <p>
              Nushift Connect has analyzed the present trend of patients choosing
              a particular healthcare institution over another.
            </p>
            <div class="social-icons">
              <a href="#" aria-label="Facebook"><img src="../../assets/new_landing/fb.png" alt="" /></a>
              <a href="#" aria-label="Instagram"><img src="../../assets/new_landing/instagram.png" alt="" />
              </a>
              <a href="#" aria-label="LinkedIn"><img src="../../assets/new_landing/linkedin.png" alt="" /></a>
              <a href="#" aria-label="YouTube"><img src="../../assets/new_landing/youtube.png" alt="" /></a>
              <a href="#" aria-label="Twitter"><img src="../../assets/new_landing/twitter.png" alt="" /></a>
            </div>
          </div>

          <div class="footer-links">
            <!-- <h3>Links</h3> -->
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">How Does it Work</a></li>
              <li><a href="#">Services</a></li>
              <li><a href="#">FAQ's</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Download App</a></li>
            </ul>
          </div>

          <div class="footer-contact">
            <h3>Get in touch</h3>
            <p>
              <img src="../../assets/new_landing/message.png" alt="" /> &nbsp;
              info&#64;nushift.in
            </p>
            <p>
              <img src="../../assets/new_landing/call.png" alt="" /> &nbsp; +91
              9030413379
            </p>
            <p>
              Nushift Technologies Pvt. Ltd.<br />
              T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur,
              Hyderabad, Telangana 500081
            </p>
          </div>
        </div>
      </div>
    </div>

  </ng-container>


  <ng-container *ngIf="isLoginClicked">
    <app-login class="w-100 d-block" [closeLogin]="isLoginClicked" (loginClosed)="loginClosed($event)"></app-login>
  </ng-container>

</div>