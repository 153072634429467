import 'zone.js';// Included with Angular CLI.

declare global {
  interface Window {
    global: any;
  }
}
// console.log = () => { }
window.global = window;
(window as any)['global'] = window;
global.Buffer = global.Buffer || require('buffer').Buffer;
global.process = require('process');
global.CryptoKey = require('crypto');
global.ReadableStream = require('readable-stream');

