import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';  // Using the compat library
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {
    // Listen to messages directly
    this.receiveMessage();
  }

  // Request permission to show notifications and store the token
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (token) {
          const encodedToken = CryptoJS.AES.encrypt(token, 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString();
          localStorage.setItem('kf', encodedToken);
          console.log('Notification permission granted and token saved:', token);
        }
      },
      (error) => {
        console.error('Error while requesting notification permission:', error);
        const randomString = uuidv4();
        const encryptedString = CryptoJS.AES.encrypt(randomString, 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString();
        localStorage.setItem('kf', encryptedString);
      }
    );
  }

  // Listen for incoming messages
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("New message received: ", payload);
        this.currentMessage.next(payload);
      },
      (error) => {
        console.error('Error receiving message: ', error);
      }
    );
  }
}
