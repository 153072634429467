<div class="left-sidebar">
  <!-- Menu Items -->
  <ul class="menu">
    <li
      class="menu-item"
      *ngFor="let item of menuItems"
      (click)="onIconClick(item.route)"
      [class.active]="isRouteActive(item.route)"
    >
      <img [src]="item.icon" [alt]="item.label + ' Icon'" class="icon" />
      {{ item.label }}
    </li>
  </ul>

  <!-- Promotional Banner -->
  <div class="promo-banner">
    <img
      src="../../../../assets/new_feed/nc_banner_ads2_copy 1.png"
      alt="Healthcare Network"
      class="promo-image"
      (click)="onIconClick('networks')"
      [class.active]="isRouteActive('networks')"
    />
  </div>
</div>
