import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('API-KEY');
    if (request.url.toLowerCase().includes('getfiltereddocs') || request.url.toLowerCase().includes('getpatientslist') || request.url.toLowerCase().includes('practiceareas') || request.url.toLowerCase().includes('dashboard') || request.url.toLowerCase().includes('getinvited') || request.url.toLowerCase().includes('getuserprofile') || request.url.toLowerCase().includes('services/getrejectreasons')) {
      if(!request.url.toLowerCase().includes('feed/record_activity') && !request.url.toLowerCase().includes('groups/get_feed') && !request.url.toLowerCase().includes('groups/delete_feed_activity') && !request.url.toLowerCase().includes('groups/accept_reject_feed') && !request.url.toLowerCase().includes('groups/get_pending_feed') && !request.url.toLowerCase().includes('networks/get_explore_list_v2') && !request.url.toLowerCase().includes('networks/user_unfollow') && !request.url.toLowerCase().includes('networks/get_all_networks')) {
        if (request.body) {
          request = request.clone({ body: request.body.append('API-KEY', token) });
          }
          else {
            console.log(request.params);
            request = request.clone({
              params: request.params.append('API-KEY', token),
            });
            if (request.url.includes('.pdf')) {
              for (let key of request.params.keys()) {
                if (key == 'API-KEY') {
                  request = request.clone({
                    params: request.params.delete('API-KEY')
                  });
                }
              }
            } else {
              for (let key of request.params.keys()) {
                if (key !== 'API-KEY') {
                  request = request.clone({
                    params: request.params.append('API-KEY', token),
                  });
                }
              }
            }
          }
      }
      
    }
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      })
    );
  }
}
