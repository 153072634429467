import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent {
  @Output() iconClick = new EventEmitter<string>();

  menuItems = [
    { label: 'Home', route: 'home', icon: '../../../../assets/new_feed/home.png' },
    { label: 'Networks', route: 'networks', icon: '../../../../assets/new_feed/networks.png' },
    { label: 'Suggestions', route: 'events', icon: '../../../../assets/new_feed/sugge.png' },
    { label: 'Recent profile viewers', route: 'recent-profile-viewers', icon: '../../../../assets/new_feed/recent.png' },
    { label: 'Podcasts', route: 'podcasts', icon: '../../../../assets/new_feed/podcasts.png' },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onIconClick(route: string): void {
    console.log(`Navigating to ${route}`);
    this.router.navigate([route]);
  }

  isRouteActive(route: string): boolean {
    // Check if the current route matches the given route
    return this.router.url.includes(route);
  }
}
