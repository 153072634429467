import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchService } from 'src/app/modules/home/search.service';
import { FeedService } from 'src/app/modules/home/services/feed.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {

  podcastName : string;
  podcastImage : string;
  podcastDescription : string;
  podcastEpisodeCount : number;
  podcastEpisodeDuration : string;
  podcastWatchers : string;
  isLoading: boolean = false;
  pc_episode_count: number;
  pc_views: number;
  trending_tags: [];
  date: "2024-01-12";
  trending_events: [];


  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private feedService: FeedService,
    private authService: AuthService,
    private searchService: SearchService,
  ) { }

  ngOnInit(): void {
    // alert('............');
    this.getTrendingData();
  }
  onSearch(hashtag: string) {
    this.searchService.setSearchTerm(hashtag); // Update search term
    console.log('Searching for:', hashtag);
  }
  async getTrendingData() {
    
    let payload = {
      uid: this.authService.userValue.uid,
      date: "2024-01-12",
      //new Date().toISOString()
    };

    this.isLoading = true;
    this.spinner.show();

    (await this.feedService.getTendingDetails(payload)).subscribe((res: any) => {
      console.log('trendingdata',res.data);
      this.trending_tags = res.data.trending_tags;
      this.trending_events = res.data.upcoming_events;
      this.podcastName = res.data.trending_podcast[0].pc_title;
      this.podcastImage = res.data.trending_podcast[0].pc_thumbnail ? res.data.trending_podcast[0].pc_thumbnail : './../../../assets/new_feed/heart.png';
      // pc_duration
      this.pc_episode_count = res.data.trending_podcast[0].pc_episode_count;
      this.pc_views = res.data.trending_podcast[0].pc_views;
      this.podcastEpisodeDuration = res.data.trending_podcast[0].pc_duration;

      this.isLoading = false;
      this.spinner.hide();
    }, error => {
      this.isLoading = false;
      this.spinner.hide();
      console.error('Something went wrong! Please try again.', error);
    });
  }

  
}
