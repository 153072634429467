import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-landing',
  templateUrl: './new-landing.component.html',
  styleUrls: ['./new-landing.component.scss']
})
export class NewLandingComponent implements OnInit {

  isLoginClicked: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  login(type) {
    if (type == 'login') {
      this.isLoginClicked = true;
    }
  }
  loginClosed(event) {
    if (event === true) {
      this.isLoginClicked = false;
    }
  }
}
